/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import {Notify,AppFullscreen,Dialog} from 'quasar'



export default { config: {"brand":{"primary":"#6699FF","secondary":"#4DD2BC","warning":"#FB8674","success":"#4DD2BC","positive":"#bfc1c6","accent":"#96989c","dark":"#f0f3fa","info":"#ffffff","negative":"#ffffff"}},plugins: {Notify,AppFullscreen,Dialog} }

