
/**
* Компонент vue3 script setup Фреймворк Quasar
* Название компонента UniArrayEdit.vue
* Входные параметры:
* parent: Object
* lang: String
* columns: Array - массив названий столбцов [{field:'field', name: 'name',  align: 'left'}..]
* rows: Array - массив строк [{field:'field', id:'id', text: 'text'}..]
* isReadOnly: Boolean - режим редактирования
*
* Компонент редактирует принимаемый массив rows:
* Доавление, удаление, редактирование полей строк
* Автогенерация id при добавлении
* Перетаскивание строк вверх-вниз
*
* Компонент отображает кнопку "Группы", при нажаатии все редактирование происходит в модальном окне.
* Кнопка "Сохранить", закрывает окно, возвращает измененный rows через emit в родительский компонент
* Кнопка "Отмена", закрывает окно, возвращает исходный массив rows через emit в родительский компонент
* 
*/

<template>
  <q-dialog v-model="isModalOpen" position="top" >
    <q-card :style="'width: '+width+'px'">
        <q-toolbar class="bg-primary text-negative">
          <q-avatar>
            <q-icon name="group" color="negative"/>
          </q-avatar>

          <q-toolbar-title>{{ title }}</q-toolbar-title>

          <q-btn flat round dense icon="close" v-close-popup />
        </q-toolbar>

      <q-card-section>
        <table  style="width: 100%;">
        <draggable
          v-model="localRows"
          item-key="id"
          handle=".handle"
          @end="onDragEnd"

        >
          <template #item="{ element }" >
            <tr>
              <td style="width:10%">
                <q-icon name="drag_handle" class="handle cursor-grab" color="positive" />
              </td>
              <td v-for="col in columns" :key="col.field" :style="col.field == 'id'?'width:0px':'display: flex; flex-grow: 1;'">
                <q-input
                  v-if="col.field!='id'"
                  v-model="element[col.field]"
                  @update:model-value="saveChanges"
                  debounce="500"
                  dense
                  autofocus
                />
              </td>
              <td style="width:10%">
                <q-btn
                  v-if="!isReadOnly"
                  icon="delete"
                  color="positive"
                  size="sm"
                  @click="removeRow(element.id)"
                  flat
                  round
                  dense
                />
              </td>
            </tr>
          </template>
        </draggable>
        </table>
      </q-card-section>


      <q-card-actions align="right">
        <q-btn flat rounded :label="message[lang].row_add" @click="addRow" v-if="!isReadOnly" color="secondary"/>
        <!-- <q-btn flat rounded :label="message[lang].sub_acc_save" @click="saveChanges" color="primary" /> -->
        <q-btn flat rounded :label="message[lang].close" @click="cancelChanges" color="positive" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-btn color="primary" rounded no-caps flat :label="title" @click="openModal" />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import draggable from 'vuedraggable';
import { useQuasar } from 'quasar';


const props = defineProps({
    title: String,
    parent: Object,
    lang: String,
    message: Object,
    columns: Array,
    rows: Array,
    isReadOnly: Boolean,
    uniqueFields: {type:Array, default:new Array()},
    usedIds: {type:Array, default:new Array()},
    width: {type:Number, default:300}
});

const emit = defineEmits(['update:rows']);
const $q = useQuasar();
const isModalOpen = ref(false);
const localRows = ref([...props.rows]);



const openModal = () => {
  isModalOpen.value = true;
};

const addRow = () => {
  const newRow = { id: generateId(), text: '' };
  localRows.value.push(newRow);
};

const removeRow = (id) => {
  const isIdUsed = props.usedIds.includes(id);
  $q.dialog({
    title: props.message[props.lang].attention, // Заголовок диалога
    message: isIdUsed ? props.message[props.lang].remove_row : props.message[props.lang].remove_row.split(',')[0], // Сообщение
    position: 'top', // Позиция (если поддерживается)
    persistent: true, // Диалог не закроется при клике вне его области
    ok: {
      label:  props.message[props.lang].remove, // Текст кнопки подтверждения
      color: 'warning', // Цвет кнопки
      rounded: true
    },
    cancel: {
      label:  props.message[props.lang].prompt_cancel, // Текст кнопки отмены
      color: 'positive', // Цвет кнопки
      flat: true, // Плоский стиль кнопки
    },
  }).onOk(() => {
    // Действие при подтверждении
    console.log('Пользователь подтвердил');
    localRows.value = localRows.value.filter(row => row.id !== id);
    saveChanges();
  }).onCancel(() => {
    // Действие при отмене
    console.log('Пользователь отменил');
  }).onDismiss(() => {
    // Действие при закрытии диалога (например, клик вне диалога)
    console.log('Диалог закрыт');
  });
};

// Сохранение изменений
const saveChanges = () => {
  if (!validateUniqueFields()) {
    console.log(props.message[props.lang].no_uniques_found)
    $q.notify({
      type: 'warning',
      position: 'top',
      multiLine: true,
      message: props.message[props.lang].no_uniques_found,
    });
    return;
  }else{
    emit('update:rows', prepareToSave(localRows.value));
  }
  // isModalOpen.value = false;
};

const prepareToSave = (rows) => {
  let ret=[];
  for(let row of rows){
    for(let col of props.columns){
      if(col.field!='id'){
        row[col.field]=row[col.field].trim();
        if(row[col.field].length>32){
          row[col.field]=row[col.field].substring(0, 32);
          $q.notify({
            type: 'warning',
            position: 'top',
            multiLine: true,
            message: props.message[props.lang].max_length+'32',
          });
        }
      }
    }
    ret.push(row);
  }
  return ret;
};


const cancelChanges = () => {
  localRows.value = [...props.rows];
  isModalOpen.value = false;
};

const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};

// Проверка уникальности поля
const isFieldUnique = (value, field, currentId) => {
  if (props.uniqueFields.length==0 || !props.uniqueFields.includes(field)) return true; // Проверяем только указанное уникальное поле
  return (
    localRows.value.filter(
      (row) => row[field] === value && row.id !== currentId
    ).length === 0
  );
};

// Валидация всех уникальных полей
const validateUniqueFields = () => {
  console.log('::validateUniqueFields uniqueFields=', props.uniqueFields);
  if (props.uniqueFields.length==0) return true; // Если уникальные поля не указаны, пропускаем проверку
  
  // Проверяем каждое уникальное поле
  for (const field of props.uniqueFields) {
    const values = localRows.value.map((row) => row[field]?.toString().trim()); // Получаем значения текущего поля и обрезаем пробелы

    // Проверка на пустоту или строку из пробелов
    if (values.some((value) => !value)) {
      return false; // Если хотя бы одно значение пустое или состоит из пробелов, возвращаем false
    }

    // Проверка на уникальность значений
    if (new Set(values).size !== values.length) {
      return false; // Если найдены дубликаты, возвращаем false
    }
  }
  return true; // Если все поля уникальны, возвращаем true

};

const onDragEnd = () => {
  // Логика после завершения перетаскивания (если нужна)
  saveChanges();
};

watch(() => props.rows, (newRows) => {
  localRows.value = [...newRows];
}, { deep: true });

onMounted(() => {
    // console.log(':**: UniArrayEdit columns=', props.columns);
    // console.log(':**: UniArrayEdit rows=', props.rows);
});
</script>

<style scoped>
.handle {
  cursor: grab;
}


</style>