<template>
  <div>
    <div v-if="course" style="padding-left: 15px; padding-bottom: 100px; padding-right: 10px;">

      <div v-if="parent.isMobile" class="row justify-between items-end text-left" >
          <div class="col-12 text-right positive h4">
            {{message[lang].rating}}
            <q-rating
              v-model="rating"
              size="1.5em"
              color="yellow-13"
              icon="star_border"
              icon-selected="star"
              @update:model-value="setRating"
            />
          </div>
          <div class="col-12">
            <div class="q-gutter-x-sm">
              <q-btn color="positive" no-caps flat round icon="transit_enterexit" @click="$emit('goback', goback);"/>
              <q-btn unelevated color="primary" >
                {{message[lang].the_course}} <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> - {{course.ID}}</span>
              </q-btn>
              <span v-if="!nav && course && course.ACTIVE !== 'Y'" class="text-warning h5 q-mx-sm">({{message[lang].notpublished}})</span>&ensp;
              <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/> {{parent.formatDate(course)}}
              <span class="h5 text-positive">{{pointsShow}}</span>
            </div>
        </div>
      </div>

      <div v-else v-bind:class="[{'margin-top-30' : parent.isAdmin||parent.isModer, 'margin-top_15' : !parent.isAdmin&&!parent.isModer }]">
        <div class="row justify-between text-left">
          <div class="col-5 text-left q-pl-md">
            <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="$emit('goback', goback);"/>
            <div class="q-my-sm">
              <q-btn unelevated color="primary" >
                {{message[lang].the_course}} <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> - {{course.ID}}</span>
              </q-btn>
              <span v-if="!nav && course && course.ACTIVE !== 'Y'" class="text-warning q-mx-sm h5">({{message[lang].notpublished}})</span>&ensp;
              <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/>
              {{parent.formatDate(course)}}
              <span class="h5 text-positive">{{pointsShow}}</span>
            </div>
          </div>
          <div class="col-3 text-right  positive h4">
            <div  style="padding: 40px 10px;">
            {{message[lang].rating}}
                <q-rating
                  v-model="rating"
                  size="1.5em"
                  color="yellow-13"
                  icon="star_border"
                  icon-selected="star"
                  @update:model-value="setRating"
                />
            </div>
          </div>
        </div>
        <div class="text-right q-pr-md text-positive">
          {{message[lang].direct_link}}:
            <input style="border: none; background: none; border-radius: 15px"
             class="text-positive"
              v-on:focus="$event.target.select()"
              :ref="'clone'+course.ID"
              readonly
              :value="parent.appURL+'?course='+course.ID"/>

              <q-btn flat round color="positive" icon="content_copy"
                 @click="copy(course.ID)">
              </q-btn>
        </div>
      </div>

      <div v-if="parent.isMobile" class="h4 q-mb-md">{{course.NAME}}</div>
      <div v-else class="h4 q-mb-md">{{course.NAME}}</div>
      <q-separator/>

      <div class="row q-my-sm">
        <div class="col-2" v-if="!parent.isMobile && course.DETAIL_PICTURE">
          <q-img :src="course.DETAIL_PICTURE" />
        </div>
        <div class="col-xs-12 col-md-10 q-gutter-y-xs q-pl-md">
          <div><span class="positive h4">{{message[lang].target}}</span>: <span v-if="course && course.PROPERTY_VALUES" class="body-0">
            <q-input
              readonly
              v-model="course.PROPERTY_VALUES.target"
              type="textarea"
              borderless
              autogrow
              class="body-0"
            />
          </span></div>
          <div><span class="positive h4">{{message[lang].thematics}}</span>: <span v-if="course && course.PROPERTY_VALUES" class="body-0">{{course.PROPERTY_VALUES.thematics}}</span></div>
          <div><span class="positive h4">{{message[lang].duration}}</span>:
            <span v-if="course && course.PROPERTY_VALUES" class="body-0">
              {{message[lang].lessons}}: {{course.PROPERTY_VALUES.duration.lessons}} {{message[lang].hours}}: {{course.PROPERTY_VALUES.duration.hours}}
            </span>
          </div>
          <div><span class="positive h4">{{message[lang].program}}</span>:
            <q-input
              readonly
              v-model="course.PROPERTY_VALUES.program"
              type="textarea"
              borderless
              autogrow
              class="body-0"
            />
          </div>
          <div v-if="competence"><span class="positive h4">{{message[lang].course_comps}}</span>:
            <q-chip color="accent" outline v-for="(item,index) in competence" :key="index">
              {{item.name}}
            </q-chip>
          </div>

            <!-- Попытки -->
          <div><span class="positive h4">{{message[lang].num_attempts}}</span>:
            <span v-if="course && course.PROPERTY_VALUES.flags && course.PROPERTY_VALUES.flags.att " class="body-0">
              {{attempts}}/{{course.PROPERTY_VALUES.flags.att}}
            </span>
            <span v-else class="body-0">{{message[lang].unlim_ited}}</span>
          </div>
          <!-- Время -->
          <div><span class="positive h4">{{message[lang].time_attempt}}</span>:
            <span v-if="course && course.PROPERTY_VALUES.flags && course.PROPERTY_VALUES.flags.time" class="body-0">
              {{course.PROPERTY_VALUES.flags.time}}
            </span>
            <span v-else class="body-0">{{message[lang].unlim_ited}}</span>
          </div>
          <!-- Статус -->
          <div><span class="positive h4">{{message[lang].course_status}}</span>:
            <span v-if="course_passed" class="body-0">{{message[lang].passed}}</span>
            <span v-else class="body-0">{{message[lang].notpassed}}</span>
          </div>
        </div>
      </div>



      <div class="row q-my-sm">
        <div class="col-12">
          <q-btn :disable="no_more_attempts  || !availPeriods" v-if="!showTraning" @click="underGoTraning" rounded unelevated color="primary" >
            <span v-if="userCourse && userCourse.completed">{{message[lang].completed}} </span>
            <div>{{message[lang].underGoTraning}}</div>
            &nbsp;<span v-if="userCourse && userCourse.repeat">{{message[lang].repeated}} </span>
            <q-tooltip v-if="no_more_attempts" class="bg-warning h-tt">{{message[lang].no_more_attempts}}</q-tooltip>
          </q-btn>
          <!-- <q-btn v-else @click="completeTheCourse" rounded unelevated color="warning" :label="message[lang].complete_the_course1"/> -->
        </div>
      </div>

      <div v-if="showTraning && !isLoading"  class="full-width">
        <!-- Дерево -->
        <div class="full-width" v-if="course.PROPERTY_VALUES.flags.tree">
          <q-splitter
            v-model="splitterModel"
            style="min-height: 500px"
            class="full-width"
            disable
          >
            <template v-slot:before>
              <q-tree v-if="showTree && course.PROPERTY_VALUES.course"
                    ref="tree"
                    :nodes="course.PROPERTY_VALUES.course"
                    node-key="name"
                    default-expand-all
                    no-transition

            >
              <template v-slot:header-root="prop">
                <div @click.stop class="row items-center" :class="prop.node.type&&prop.node.stage!='D'&&!prop.node.dsbld?'cursor-pointer':'cursor-not-allowed'">
                  <div :style="'color:'+(prop.node.color?prop.node.color : (prop.node.type=='lesson'?'#6699FF':'#4DD2BC'))"
                       @click="prop.node.stage=='D'||prop.node.dsbld?'':viewTreeItem(prop.node)"
                  >
                    <q-icon v-if="prop.node.type!=='sep'&&prop.node.test_passed&&!prop.node.body" name="done" color="secondary"/>
                    <q-icon v-else-if="prop.node.type!=='sep'&&prop.node.test_passed!==false&&!prop.node.body" name="hide_source" color="warning"/>
                    {{ prop.node.name }}
                  </div>
                </div>
              </template>

              <template v-slot:header-child="prop">
<!--                <q-tooltip>-->
<!--                  {{prop.node}}-->
<!--                </q-tooltip>-->
                <div @click.stop class="row items-center" :class="prop.node.type&&prop.node.stage!='D'&&!prop.node.dsbld?'cursor-pointer':'cursor-not-allowed'">
                  <div :style="'color:'+(prop.node.color?prop.node.color : (prop.node.type=='lesson'?'#6699FF':'#4DD2BC'))"
                       @click="prop.node.stage=='D'||prop.node.dsbld?'':viewTreeItem(prop.node)"
                  >
                    <q-icon v-if="prop.node.test_passed" name="done" color="secondary"/>
                    <q-icon v-else-if="prop.node.type=='test' && prop.node.test_passed==false" name="hide_source" color="warning"/>
                    {{ prop.node.name }}
                  </div>
                </div>
              </template>

            </q-tree>
            </template>

            <template v-slot:after>
              <div v-if="lessonItem">
                <lesson-view 
                  @moduleReturn="moduleReturn"
                  @completeTheCourse="completeTheCourse" 
                  @lessonComplete="lessonComplete"    
                  @goback="gobackFromModule"
                  v-bind:parent="parent"
                  v-bind:back="''"
                  v-bind:lang="lang"
                  v-bind:lessonItem="lessonItem"
                ></lesson-view>
              </div>
              <div v-else-if="testItem">
                <test-view 
                  @moduleReturn="moduleReturn"
                  @completeTheCourse="completeTheCourse"
                  @goback="gobackFromModule"
                  v-bind:parent="parent"
                  v-bind:back="''"
                  v-bind:lang="lang"
                  v-bind:testItem="testItem"
                  v-bind:user_current="parent.user_current"
                ></test-view>
              </div>
            </template>
          </q-splitter>
        </div>
        <!-- Линейная навигация     -->
<!--        <div class="q-pa-md row items-start q-gutter-md" v-else>-->
        <div class="row q-col-gutter-sm" v-else>
          <div :class="!element.dsbld && element.type!=='sep'?'col-3':'col-12'" v-for="element in course.PROPERTY_VALUES.course" :key="element.id">
<!--            <div  v-if="!element.dsbld && element.type!=='sep'" >-->
              <q-card  v-if="!element.dsbld && element.type!=='sep'">
                <q-card-section
                      horizontal
                      :style="'height: 130px; overflow: hidden; background-color:'+(element.color?element.color : '#6699FF')">
                  <q-card-section v-if="element.pic" class="col-4 q-px-xs flex flex-center">
                    <q-img v-if="element.pic"
                           class="rounded-borders"
                           :src="element.pic"
                    />
                  </q-card-section>

                  <q-card-section class="text-negative h4 q-px-xs" >
                    <div v-if="!element.dsbld && element.type!=='sep'" class="text-bold text-center">{{message[lang][element.type]}}<span v-if="parent.showSysInfo=='Y'"> №{{ element.id }}</span></div>
                    <div class="text-negative h5">{{element.name}}</div>
                  </q-card-section>
                </q-card-section>
                <q-card-actions vertical align="center">
                  <div v-if="checkPassed(element.id, element.type)"><q-icon name="control_point" color="secondary" /> {{message[lang].passed}} </div>
                  <div v-else><q-icon name="remove_circle_outline" color="warning" /> {{message[lang].notpassed}} </div>
                  <div>
                    <q-btn :disable="element.stage === 'D'" @click="viewItem(element.id, element.type)" size="sm" unelevated rounded color="primary" :label="message[lang].pass" icon-right="chevron_right" />
                  </div>
                </q-card-actions>
              </q-card>

              <q-card v-else flat bordered
                      :style="'max-height: 60px; overflow: hidden; background-color:'+(element.color?element.color : '#6699FF')">
                <q-card-section class="text-accent">
                    {{element.name}}
                </q-card-section>
              </q-card>
          </div>
        </div>
      </div>



      <Comments v-if="course.PROPERTY_VALUES.flags.comments_available"
                v-bind:lang="lang"
                v-bind:entity-id="`course_${course.ID}`"
                v-bind:user_current="parent.user_current"
                v-bind:parent="parent"
                v-bind:mode="'list'"
                @save_btn="saveComment"
      />

      <q-inner-loading :showing="isLoading">
        <q-spinner-oval size="5em" color="positive" />
      </q-inner-loading>

    </div>

    <div>


    </div>
<!-- модальные тесты и уроки -->
    <q-dialog v-model="test_modal" persistent full-width full-height>
        <q-card class="bg-dark">
            <q-card-section class="q-pt-xl">
                <test-view
                    @testViewById="programTestViewById"
                    @lessonViewById="programLessonViewById"
                    @goback="courseClose"
                    @courseReturn="courseReturn"
                    @moduleReturn="moduleReturn"
                    v-bind:parent="parent"
                    v-bind:back="parent.currentScreen"
                    v-bind:lang="lang"
                    v-bind:testItem="moduleItem"
                    v-bind:user_current="parent.user_current"
        ></test-view>
            </q-card-section>
        </q-card>
    </q-dialog>
        <q-dialog v-model="lesson_modal" persistent full-width full-height>
            <q-card class="bg-dark">
                <q-card-section class="q-pt-xl">
                    <lesson-view
                        @lessonViewById="programLessonViewById"
                        @testViewById="programTestViewById"
                        @goback="courseClose"
                        @courseReturn="courseReturn"
                        @moduleReturn="moduleReturn"
                         v-bind:parent="parent"
                         v-bind:back="parent.currentScreen"
                         v-bind:lang="lang"
                         v-bind:lessonItem="moduleItem"
                        ></lesson-view>
                </q-card-section>
            </q-card>
        </q-dialog>

  </div>
</template>
<script>
  import {ref} from "vue";
  import dayjs from "dayjs"; //https://vuejsexamples.com/a-simple-and-highly-customisable-star-rating-component-for-vue/
  import Comments from './Comments.vue';
  import lessonView from './LessonView.vue';
  import testView from './TestView.vue';
  import { makeIPR } from "src/helpers/uni_utils.js";

  export default {
        props:{
          parent:Object,
          lang:String,
          courseItem:Object,
          course_index:Number,
          user_current:Object,
          back:String,
        },
        data: function () {
          return {
            pointsShow:'',
            test_modal:false,
            lesson_modal:false,
            moduleItem:null,
            goback:'coursesList',
            program_chedule_id:0,
            competence:'',
            loading_comps:false,
            comps_options:[],
            splitterModel:ref(30),
            lessonItem:null,
            testItem:null,
            textDone:'OK',
            showDone:false,
            course:'',
            isLoading:false,
            isLoaded:false,
            rating:0,
            showTraning:false,
            showTree:true,
            userCourse:null,
            lastStage:{status:'Y'},
            currStage:'',
            passedTests:null,
            no_more_attempts:false,
            attempts:0,
            course_passed:false,
            message:this.parent.dictionary,
          } 
        },
        updated: function () {
            // console.log(':: CourseView updated ');
            const $this=this;
            if(!$this.course.isLoaded){
                this.initCourse();
            }
        },
        mounted: function(){
          // console.log(':: CourseView mounted courseItem=', this.courseItem);
          let $this = this;
          $this.isLoading = true;
          this.goback = this.back?this.back:'coursesList';
          // console.log(':: CourseView mounted goback=', this.goback);
          $this.parent.uCoursesTestsGet_Cache='';
          $this.parent.checkPulse('course').then(res=>{
            let user_id = $this.parent.user_current.ID;
            let course_id = parseInt($this.courseItem.ID);
            this.program_chedule_id = $this.courseItem.program_chedule_id;


            $this.initCourse().then(res=>{
              console.log('::initCourse this.course=',this.course);

              let testsNlessons = this.parent.isJSON(this.course.PROPERTY_VALUES.course) ? JSON.parse(this.course.PROPERTY_VALUES.course):this.course.PROPERTY_VALUES.course;
              if (!Array.isArray(testsNlessons)) {
                testsNlessons = null;
              }else if($this.course.PROPERTY_VALUES.flags.tree){
                testsNlessons = $this.parent.flattenArray(testsNlessons);
              }
              
              $this.parent.getSummaryUserCourses(user_id, course_id, testsNlessons).then(res=> {
                console.log('::getSummaryUserCourses res=',res);
                if(res) {
                  $this.attempts = res.attempts;
                  $this.course_passed = res.passed;
                  
                  if(res.passed && !res?.data.completed){
                    //все пройдено, но даты завершения нет, завершим курс
                    this.parent.confirm_init();
                    $this.parent.title_confirm = this.message[this.lang].course_passed;
                    $this.parent.message_confirm = this.message[this.lang].course_time_closed_title;
                    $this.parent.modal_ok = this.message[this.lang].notify_ok;
                    $this.parent.modal_cancel =  'btn-hide';
                    $this.parent.do_confirm = ()=>{
                      $this.completeTheCourse();
                      $this.parent.confirm = false;
                    };
                    $this.parent.confirm = true;

                  }
                }
                // $this.initCourse();
                $this.parent.getUserTests().then(ok => {
                  $this.isLoading = false;
                  // console.log('::CourseView mounted  passedTests=',ok);
                  $this.passedTests = ok.passedTests;
                }, err => {
                  $this.isLoading = false;
                  console.log(err);
                });
              }, err=>{
                // $this.initCourse();
                $this.parent.getUserTests().then(ok => {
                  $this.isLoading = false;
                  // console.log('::CourseView mounted  passedTests=',ok);
                  $this.passedTests = ok.passedTests;
                }, err => {
                  $this.isLoading = false;
                  console.log(err);
                });
              });
            });

          },err=>{
            $this.isLoading = false;
            this.$emit('goback', 'studentProfile');
          });



        },
        watch:{
        },
        computed: {
          availPeriods() {
            let ret = true;
            if (this.course.PROPERTY_VALUES.flags.avail_period && this.course.PROPERTY_VALUES.flags.avail_days && this.course.PROPERTY_VALUES.flags.avail_days.length > 0) {
              let today = dayjs().format('YYYY-MM-DD');
              ret = false;
              for (let item of this.course.PROPERTY_VALUES.flags.avail_days) {
                if (today >= item.from && today <= item.to) {
                  ret = true;
                  break;
                }
              }
              if (!ret) {
                let $this = this;
                console.error(':: Тест вне сроков доступности')
                $this.parent.notify_title = this.parent.message[this.lang].bx24_alert;
                $this.parent.notify_text = this.parent.message[this.lang].module_out_from_dates;
                $this.parent.notify_text += ": " + this.course.PROPERTY_VALUES.flags.avail_days.map(el => this.parent.customFormatter(el.from) + ' - ' + this.parent.customFormatter(el.to)).join(', ');
                $this.parent.notify_warning = true;
                $this.parent.notify = true;
              }
            }
            return ret;
          },
        },
        methods: {
          lessonComplete(lesson_id){
            console.log('::lessonComplete ',lesson_id)
          },
          saveComment(arg) {
            // console.log(':***:saveComment ',arg, this.parent.moderators)
            let message = this.message[this.lang].comment_saved + '. ' + this.message[this.lang].title + ': ' +
              "[" + this.course.ID + "] " + this.course.NAME + '. "' + arg.comment + '". ' + arg.user.name + '©';

            // this.parent.isNoteSet(this.parent.moderators.map(el => {return el.id}), "test").then(checks=>{
            //   if (Object.keys(checks).length > 0) {
            //     let arBatch = {};
            //     for (let uid of checks) {
            //       arBatch['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
            //     }
            //     this.parent.callBatch(arBatch);
            //   }
            // });

          },
          copy(id) {
            this.$refs['clone'+id].focus();
            document.execCommand('copy');
          },
          // getDirectLink(id){
          //   const link = `${this.parent.appURL}?course=${id}`;
          //   console.log('::getDirectLink ',link);
          //   navigator.clipboard.writeText(link);
          //   this.textDone = this.message[this.lang].textDone_copy;
          //   this.showDone = true;
          // },
          modersNotify(course_id, course_name, action){
            let message = this.user_current.ID+' '+this.user_current.NAME + ' ' +this.parent.user_current.LAST_NAME + ' '
              + this.message[this.lang]['course_' + action] + ' ' + course_id + ' ' + course_name;// Уведомления

            // this.parent.isNoteSet(this.parent.moderators.map(el => {return el.id}), "self").then(checks => {
            //   if (Object.keys(checks).length > 0) {
            //     let arBatch2 = {};
            //     for (let uid of checks) {
            //       arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
            //     }
            //     this.parent.callBatch(arBatch2);
            //   }
            // });
          },
          gobackFromModule(arg) {
            console.log('::courseView gobackFromModule ',arg)
            if(arg.completed) {
              this.completeTheCourse();
            }
          },
          completeTheCourse(){
            if(this.course.intervalId){
              clearInterval(this.course.intervalId);
            }
            this.parent.getServerTimeStamp().then(endTime=> {
              let completedTime = dayjs(endTime*1000).format('YYYY-MM-DD HH:mm');
              this.modersNotify(this.course.ID, this.course.NAME, 'end');
              console.log(':***: completeTheCourse endTime=', endTime, completedTime, this.course);
              let user_id = this.parent.user_current.ID;
              let course_id = parseInt(this.course.ID);
              let testsNlessons = this.parent.isJSON(this.course.PROPERTY_VALUES.course) ? JSON.parse(this.course.PROPERTY_VALUES.course):this.course.PROPERTY_VALUES.course;
              if (!Array.isArray(testsNlessons)) {
                testsNlessons = null;
              }else if(this.course.PROPERTY_VALUES.flags.tree){
                testsNlessons = this.parent.flattenArray(testsNlessons);
              }
                this.parent.getSummaryUserCourses(user_id, course_id, testsNlessons).then(res=>{
                  console.log(':***:saveLessonToCourseOption user Course=',res)
                  if(res){
                    this.userCourse = res.data;
                    this.userCourse.save_id = res.id; //id сохраненного userCourse
                    ///////////
                    this.userCourse.completed = completedTime;
                    let passed = true;
                    let sum_value = 0;
                    if(this.userCourse.lessons) {
                      for (let item of this.userCourse.lessons) {
                        if(!item.test_passed && !item.passed){
                          passed = false;
                          break;
                        }else {
                          if (item.points) {
                            sum_value += parseInt(item.points);
                          } else {
                            //если в уроке есть инфа о баллах, суммируем
                            sum_value += this.parent.getPointsForModType('L'); //parseInt(this.parent.arPoints.for_lesson)
                          }
                        }
                      }
                    }
                    if(passed && this.userCourse.tests) {
                      for (let item of this.userCourse.tests) {
                        if(!item.test_passed && !item.passed){
                          passed = false;
                          break;
                        }else {
                          //если в тесте есть инфа о баллах, суммируем
                          if (item.points) {
                            sum_value += parseInt(item.points);
                          } else if (item.type === 'final') {
                            sum_value += this.parent.getPointsForModType('T'); //parseInt(this.parent.arPoints.for_itest)
                          } else {
                            sum_value += this.parent.getPointsForModType('I'); //parseInt(this.parent.arPoints.for_test)
                          }
                        }
                      }
                    }
                    if(passed){
                      // console.log(`:***:completeTheCourse passed sum_value=${sum_value} course`, this.course)
                      let for_course=this.course.PROPERTY_VALUES.flags.for_course;
                      if(for_course?.active ){
                        //в курсе задано начисление баллов
                        if(for_course && for_course.active && for_course.mode=='hand' && for_course.value){
                          //начиялем баллы заданные вручную
                          this.parent.savePoints('C', this.course.ID, parseInt(for_course.value));
                        }else if(for_course && for_course.active && for_course.mode=='sum' && sum_value){
                          //начиялем баллы суммированием баллов за уроки и тесты
                          this.parent.savePoints('C', this.course.ID, sum_value);
                        }
                      } else{
                        // берем баллы для курса из настроек
                        sum_value = this.parent.getPointsForModType('C');
                        if(sum_value){
                          this.parent.savePoints('C', this.course.ID, sum_value);
                        }
                      }

                      if(sum_value){
                        //если курс проходится из программы
                        let for_prog=this.courseItem.for_prog;
                        if(!for_prog || for_prog && (!for_prog.active || for_prog.active && for_prog.dop === 'Y')){
                          this.parent.savePoints('C', this.course.ID, sum_value);
                        }
                      }  
                      
                    }else{
                      //курс не пройден, посмотреть нужно ли создавать ИПР
                      if(this.course.PROPERTY_VALUES.flags.make_ipr){
                        makeIPR(this.course.PROPERTY_VALUES.flags.comp, this).then(res=>{
                          console.log(':: ИПР ?=',res);
                        });
                      }
                    }

                    this.userCourse.passed = passed;
                    this.updateUserCourses(this.userCourse, this.userCourse.course_id, 'N').then(res=>{
                      this.parent.getUserScheduledCourses();
                    });

                    this.parent.showAOS({props_aos:this.course.PROPERTY_VALUES.flags.aos, id:this.course.ID, name:this.course.NAME, type:'course'});

                    // console.log(':: completeTheCourse user course=',this.userCourse.sched_id, this.userCourse);
                    if(this.userCourse.sched_id) {
                      this.parent.checkTaskScheduled(this.userCourse.sched_id, this.userCourse.course_id);
                    }

                    /**
                     * Возврат в программу
                     */
                    console.log(':+++:this.program_chedule_id=',this.program_chedule_id)
                    if(this.program_chedule_id){
                      let emit_data = {
                        program_chedule_id:this.userCourse.program_chedule_id||this.program_chedule_id,
                        module_id:this.course.ID,
                        passed:passed
                      };
                      console.log(':+++: completeTheCourse emit courseReturn',emit_data);
                      this.$emit('courseReturn', JSON.stringify(emit_data));
                    }else{
                      console.log(':+++: completeTheCourse emit goback');
                      this.$emit('goback', this.goback);
                    }
                    /////////
                  }else{
                    console.error(':: userCourse not recording')
                    this.$emit('goback', this.goback);
                  }

              });


              // if(!this.userCourse || !this.userCourse.sched_id){
              //   console.log(':***: completeTheCourse emit goback');
              //   this.$emit('goback', this.goback);
              // }

            });
          },
          reachFlatten(arr_src, arr_flat){
            if(arr_flat?.length && arr_src?.lessons?.length){ 
              for(let item of arr_flat){
                  let foundItem = arr_src.lessons.find(el=>{return el.lesson_id == item.id});
                  if(foundItem){
                    item.test_passed = foundItem.test_passed;
                  }
              }
            }

            if(arr_flat?.length && arr_src?.tests?.length){ 
              for(let item of arr_flat){
                  let foundItem = arr_src.tests.find(el=>{return el.test_id == item.id});
                  if(foundItem){
                    item.test_passed = foundItem.test_passed;
                  }
              }
            }
          },

          addStage(from_id){
            console.log('::=== addStage === this.userCourse=',this.userCourse);
              let $this = this;
              return new Promise(function (resolve, reject) {
                let isResetStatuses=false;
                // if($this.userCourse && $this.userCourse.startTime && $this.course.DATE_ACTIVE_FROM){
                //   //Проверка на изменение курса с момента предыдущего его прохождения
                //   let date_last_publish = dayjs($this.course.DATE_ACTIVE_FROM).format('YYYY-MM-DD');
                //   let date_last_run = dayjs($this.userCourse.startTime*1000).format('YYYY-MM-DD');
                //   isResetStatuses = date_last_publish > date_last_run;
                //   // console.log(`::Были изменения в курсе? last_publish=${date_last_publish} > last_run=${date_last_run} => ${isResetStatuses}`)
                // }
                let alw_next =  $this.course.PROPERTY_VALUES.flags.alw_next;
                if($this.course.PROPERTY_VALUES.flags.tree) {
                  //развернем дерево в плоский список (так легче обходить)
                  let flatten = $this.parent.flattenArray($this.course.PROPERTY_VALUES.course);
                  $this.reachFlatten($this.userCourse, flatten);//перенесем test_passed в flatten
                  let prev_passed=false;
                  let isContinue = true;
                  for (let i in flatten) {
                    let item = flatten[i];
                    if(item.dsbld || item.type==='sep'){
                      continue;
                    }

                    // if(!from_id){
                    //   isContinue = false;
                    // }else if(item.id == from_id){
                    //   isContinue = false;
                    //   let itemStatus = $this.getStatus(item.type, item.id, flatten)
                    //   console.log(':: first item status=', itemStatus, item);
                    //   continue;
                    // }

                    // if(isContinue){
                    //   continue;
                    // }

                    let itemStatus = $this.getStatus(item.type, item.id, flatten)
                    // console.log(':!!!: item status=',item.type, item.id, {...itemStatus});
                    item.stage = isResetStatuses?'D':itemStatus.status;
                    item.test_passed = itemStatus.test_passed;
                    if(item.stage == 'D' && (i==1 || alw_next == 0)){
                      item.stage = 'View';
                    }
                    // console.log(`:${i}:${alw_next} addStage item=`,item.stage, item);
                    // console.log(':+++: item =', {...item});
                    $this.parent.updateInTreeById($this.course.PROPERTY_VALUES.course, item.id, item);
                    prev_passed = true;
                  }
                  // console.log(':--: addStage tree=',$this.course.PROPERTY_VALUES.course);
                  resolve('ok');
                }
                else{
                  if($this.course && $this.course.PROPERTY_VALUES && $this.course.PROPERTY_VALUES.course) {
                    let forfilter = $this.course.PROPERTY_VALUES.course.filter(el=>{return el.type==='lesson' || el.type==='test'});
                    $this.reachFlatten($this.userCourse, forfilter);//перенесем test_passed в forfilter
                    console.log(':///////:for filter=',forfilter)
                    console.log('://:isx=',$this.course.PROPERTY_VALUES.course)
                    for (let i in forfilter) {
                      let item = forfilter[i];
                      if(item.dsbld || item.type==='sep')
                        continue;

                      // if( isResetStatuses ){
                      //   console.log('::!completed ',$this.course.PROPERTY_VALUES.course[i].stage, $this.course.PROPERTY_VALUES.course[i])
                      //   $this.course.PROPERTY_VALUES.course[i].stage = i==0?'View':'D';
                      //   console.log(`::Были изменения в курсе с момента предыдущего его прохождения`)
                      // }else{
                        let itemStatus = $this.getStatus(item.type, item.id, forfilter);
                        console.log(':!!!: item status=',item.type, item.id, {...itemStatus});
                        // let stage = itemStatus.status;
                        // if(stage == 'D' && (i==0 || alw_next == 0)){
                        //   stage = 'View';
                        // }
                        // console.log(':*1*: addStage stage=',stage);
                        let ind = $this.course.PROPERTY_VALUES.course.findIndex(el=>{return el.id == item.id});
                        $this.course.PROPERTY_VALUES.course[ind].stage = itemStatus.status;
                        $this.course.PROPERTY_VALUES.course[ind].test_passed = itemStatus.test_passed;
                        // $this.course.PROPERTY_VALUES.course[i].stage = stage;// != 'D'?'View':stage;
                        // console.log(':*2*:addStage stage=',stage,item);
                      // }

                    }
                    resolve('ok');
                  }else{
                    reject($this.course);
                  }
                }

              });
            },
            getStatus(type, id,flatten){
              // console.log(':-----------------: getStatus type,id,userCourse=',type, id,flatten);
              console.log(' ');

              let alw_next =  this.course.PROPERTY_VALUES.flags.alw_next;
              let module={type:'sep', error:'no user-test found'};
              let foundIndex = flatten.findIndex(el => {return el.id == id && el.type===type});
              if(foundIndex>=0) {
                module = {...flatten[foundIndex]}
                console.log(':-1-:getStatus module ',foundIndex, module.name,  module);
              }else{ 
                console.error('::getStatus no user-test found ',id); 
                return module;
              }

              let lastStage = {...this.lastStage};

              console.log(':-2-:getStatus  alw_next, lastStage=',alw_next, lastStage);
              // // if(this.course.PROPERTY_VALUES.flags.tree){
                if(alw_next == 0){
                    module.status = 'View';
                }else 
                if(alw_next == 1){
                  // if(module.type === 'test' && !lastStage.one && lastStage.status === 'View' && module.test_passed===false){
                  if(module.type === 'test' && lastStage.status === 'View' && module.test_passed===false){
                    // Это тест перед которым пройден модуль, но тест пройден с ошибками. Для следующего разрешаем пройти модуль
                    module.status = 'View';
                    module.one = true;
                  }else  if(lastStage.type === 'test' && lastStage.one && lastStage.status === 'View' ){
                    //Модуль следующий за ошибочным тестом. Для него разрешаем пройти модуль
                    module.status = 'View';
                    module.one = true;
                  }else if(lastStage.test_passed || foundIndex==0){
                    module.status = 'View';
                  } 
                  else{
                    module.status = 'D'; 
                  }
                  
                }else{//alw_next == 2
                  if(lastStage.test_passed || foundIndex==0){
                    module.status = 'View';
                  }else{
                    module.status = 'D';
                  }
                }
              
                this.lastStage = {...module};
                console.log(':-3-:getStatus  ret=',this.lastStage);
                return module;
            },
            getUserCourse(){
              let $this=this;
              let user_id = $this.parent.user_current.ID;
              let course_id = parseInt($this.course.ID);
              return new Promise(function (resolve, reject) {
                // $this.parent.uCoursesTestsGetActive('course', user_id, course_id, true).then(res=>{
                let testsNlessons = $this.parent.isJSON($this.course.PROPERTY_VALUES.course) ? JSON.parse($this.course.PROPERTY_VALUES.course):$this.course.PROPERTY_VALUES.course;
                if (!Array.isArray(testsNlessons)) {
                  testsNlessons = null;
                }else if($this.course.PROPERTY_VALUES.flags.tree){
                  testsNlessons = $this.parent.flattenArray(testsNlessons);
                }
                const tNl = $this.testsNlessonsForNewCourse(course_id);
                $this.parent.getSummaryUserCourses(user_id, course_id, testsNlessons).then(res=>{
                  // console.log(':1:getUserCourse res=',res)
                  $this.parent.getServerTimeStamp().then(startTime=> {
                    $this.userCourse = res.data;
                    $this.userCourse.startTime = startTime;
                    $this.course_passed = res.passed;
                    // console.log(':: startTime,endTime=',dayjs($this.userCourse.startTime*1000).format('YYYY-MM-DD HH:mm:s'),dayjs($this.userCourse.endTime*1000).format('YYYY-MM-DD HH:mm:s'))
                    $this.userCourse.repeat=true;
                    $this.userCourse.save_id = res.id;
                    $this.userCourse.ID = res.id;
                    $this.userCourse.sched_id=$this.courseItem.sched_id;
                    $this.userCourse.program_chedule_id = $this.courseItem.program_chedule_id;
                    $this.userCourse.passed = $this.course_passed;
                    $this.userCourse.flags = $this.course.PROPERTY_VALUES.flags;
                    $this.userCourse.course_name = $this.course.NAME;
                    if(!$this.userCourse.lessons || $this.userCourse.lessons.length==0) {
                      $this.userCourse.lessons = tNl.lessons;                    
                    }
                    if(!$this.userCourse.tests || $this.userCourse.tests.length==0) {
                      $this.userCourse.tests = tNl.tests;  
                    }
                    if($this.userCourse.tests) {
                      for (let test of $this.userCourse.tests) {
                        test.isEnd = test.test_passed;
                      }
                    }
                    console.log(':2:getUserCourse userCourse=',{...$this.userCourse})
                    resolve('loaded');
                  });
                }, err=>{
                  $this.initUserCourse(true).then(new_course=> {
                    new_course.passed=false;
                    $this.parent.uCoursesTestsSet('course', user_id, new_course).then(res => {
                      // console.log('::getUserCourse попыток прохождения курса не обнаружено. Создадим новую запись res=', res);
                      let save_id = res.result;
                      $this.userCourse = new_course;
                      $this.userCourse.repeat = false;
                      $this.userCourse.save_id = save_id;
                      $this.userCourse.sched_id=$this.courseItem.sched_id;
                      $this.userCourse.lessons = tNl.lessons;
                      $this.userCourse.tests = tNl.tests;
                      resolve('created new');
                    });
                  });
                });

              });
            },
            testsNlessonsForNewCourse(course_id){
              let testsNlessons = this.parent.isJSON(this.course.PROPERTY_VALUES.course) ? JSON.parse(this.course.PROPERTY_VALUES.course):this.course.PROPERTY_VALUES.course;
              if (!Array.isArray(testsNlessons)) {
                testsNlessons = null;
              }else if(this.course.PROPERTY_VALUES.flags.tree){
                testsNlessons = this.parent.flattenArray(testsNlessons);
              }
              let lessons = [];
              let tests = [];

              if(testsNlessons && testsNlessons.length>0){
                lessons = testsNlessons.filter(el => {return el.type=='lesson'}).map(el => {
                  return {
                    course_id: course_id,
                    dt: dayjs().format('YYYY-MM-DD'),
                    lesson_id: el.id,
                    lesson_name: el.name,
                    points: 0,
                    program_chedule_id: this.courseItem.program_chedule_id,
                    src: "newCourse"
                  }
                });

                tests = testsNlessons.filter(el => {return el.type=='test'}).map(el => {
                  return {
                    course_id: course_id,
                    dt: dayjs().format('YYYY-MM-DD'),
                    test_id: el.id,
                    test_name: el.name,
                    points: 0,
                    program_chedule_id: this.courseItem.program_chedule_id,
                    src: "newCourse"
                  }
                });
              }

              return {tests:tests, lessons:lessons};
            },
            underGoTraning() {
                let $this = this;
                // this.addStage().then(ok=>{
                  $this.GoTraning();
                // }, err=>{console.error('::Course failed', err)});
            },
            checkPassed(id,type){
              let index=type?`${type}s`:'none';
              let item = this.userCourse && this.userCourse[index] ? this.userCourse[index].find(el => { return el[`${type}_id`] == id}):'';
              // console.log('::checkPassed item=',id,index,item, this.userCourse[index])
              return item?item.test_passed:false;
            },
            GoTraning(){
              let $this = this;
              this.showTraning=true;
              this.isLoading=true;
              // BX24.scrollParentWindow (-1, function(){});
               window.scrollTo({
                      top: 0,
                      behavior: 'smooth' 
                    });
              //Создадим запись курса и будем в нее писать о результатах пройденных уроков и курсов
              this.getUserCourse().then(uc_res=>{
                // console.log(':+++:GoTraning this.userCourse=',this.userCourse)
                this.addStage().then(ok=>{
                  // console.log(':: this.course.PROPERTY_VALUES.course=',this.course.PROPERTY_VALUES.course)
                  this.showTraning=true;
                  // if(this.courseItem.PROPERTY_VALUES.flags && this.courseItem.PROPERTY_VALUES.flags.tree){
                  //   this.$nextTick(() => {
                  //     this.$refs.tree.expandAll();
                  //     if(this.course.PROPERTY_VALUES.course && this.course.PROPERTY_VALUES.course.length) {
                  //       this.course.PROPERTY_VALUES.course[0].disabled = true;
                  //     }
                  //   });
                  //
                  // }
                  this.isLoading=false;
                  // BX24.scrollParentWindow (-1, function(){});
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  })
                  if(this.courseItem.completed==='Y'){
                    this.completeTheCourse();
                  }else {

                    this.parent.getServerTimeStamp().then(currentTime=> {
                      //Это начало новой попытки нужно проставить время старта
                      this.userCourse.startTime = currentTime;
                      this.userCourse.completed = "";
                      //Если это не первая попытка, то создаем новую. Иначе она уже создана в getUserCourse
                      if(uc_res === 'loaded') {
                        this.updateUserCourses(this.userCourse, this.course.ID, 'Y', true);
                      }
                      this.modersNotify(this.course.ID, this.course.NAME, 'start');
                      if(this.course.PROPERTY_VALUES.flags && this.course.PROPERTY_VALUES.flags.time){
                        let time = parseInt(this.course.PROPERTY_VALUES.flags.time);
                        if(!isNaN(time) && time>0){
                          this.countdown(time*60, (intervalId) => {
                            console.log('Timer finished');
                            $this.parent.notify_title = this.message[this.lang].course_time_closed_title;
                            $this.parent.notify_text = this.message[this.lang].course_time_closed_mess;
                            $this.parent.notify_text += ' '+$this.course.NAME;
                            $this.parent.notify_warning = true;
                            $this.parent.notify = true;
                            $this.completeTheCourse();
                            $this.showTraning=false;
                            $this.parent.currentScreen="studentProfile";

                          });
                        }
                      }
                    });
                  }
                }, err=>{console.error('::Course failed', err)});
              });
            },
            countdown(seconds, callback) {
              let count = seconds;
              const intervalId = setInterval(() => {
                // console.log('::countdown ',count);
                count--;
                if (count < 0) {
                  // clearInterval(intervalId);
                  callback();
                }
              }, 1000);
              this.course.intervalId = intervalId;
            },
            initUserCourse(isNew){
              let $this = this;
              console.log('::initUserCourse $this.course= ',$this.course)
              return new Promise((resolve) => {
                let course_id = parseInt($this.course.ID);
                // let today = Date.now();
                // let startTime = Math.floor(today / 1000); //количество секунд с начало эпохи Unix
                $this.parent.getServerTimeStamp().then(startTime=> {
                  let new_course = {
                    course_id: parseInt(course_id),
                    startTime: startTime,
                    endTime:'',
                    course_name: $this.course.NAME,
                    tests: [],
                    lessons: [],
                    completed: '',
                    save_id: '',
                    flags: $this.course.PROPERTY_VALUES.flags,
                    sched_id:$this.courseItem.sched_id,
                    cat: $this.course.PREVIEW_TEXT //ID раздела в каталоге
                    // program_chedule_id:$this.courseItem.program_chedule_id
                  };
                  if (!isNew && $this.userCourse) {
                    // console.log('::initUserCourse $this.userCourse= ',$this.userCourse)
                    new_course = $this.userCourse;
                    new_course.startTime = startTime;
                    new_course.flags = $this.course.PROPERTY_VALUES.flags;
                    new_course.sched_id = $this.courseItem.sched_id;
                    // new_course.program_chedule_id = $this.courseItem.program_chedule_id;
                  } else {
                    let nav = $this.course.PROPERTY_VALUES.course;
                    console.log(':---: nav=',nav)
                    for (let item of nav) {
                      // let item = nav[i];
                      if (item.type === 'lesson') {
                        new_course.lessons.push({
                          lesson_id: parseInt(item.id),
                          lesson_name: item.name,
                          status: item.stage, //'View'
                          test_passed: item.test_passed
                        });
                      } else if (item.type === 'test') {
                        new_course.tests.push({
                          test_id: parseInt(item.id),
                          test_name: item.name,
                          status: item.stage,//'View',
                          isEnd: item.isEnd,
                          test_passed: item.test_passed
                        });
                      }
                    }
                    $this.userCourse = new_course;
                  }
                  // console.log('::initUserCourse userCourse=', new_course);
                  resolve(new_course);
                });
              });
            },
            updateUserCourses(course, course_id, active, isNewAttempt){
              let $this=this;
              return new Promise(resolve => {
                $this.parent.getServerTimeStamp().then(curTime=> {
                  let user_id = this.parent.user_current.ID;
                  course.endTime = curTime;
                  if(!isNewAttempt && this.userCourse && this.userCourse.save_id){
                    //Это обновление в пределах текущей попытки
                    $this.parent.uCoursesTestsSet('course', user_id, course, this.userCourse.save_id, active).then(res => {
                      // console.log(':**update**:uCoursesTestsSet  ', res, course);
                      resolve(res);
                    });
                  }else{
                    //Если это старт новой попытки
                    // $this.parent.uCoursesTestsSave('course', user_id, course, active).then(res => {
                    $this.parent.uCoursesTestsSet('course', user_id, course, '', true).then(res => {
                      // console.log(':**new**:uCoursesTestsSet ',res.result, course);
                      $this.userCourse.save_id = res.result;
                      resolve(res)
                    });
                  }
                });
              });
            },
            viewItem(id, type){
              console.log('::viewItem ', id, type, this.courseItem)
              let meth = type + 'ViewById';
              let nav = this.course.PROPERTY_VALUES.course.filter(item=>item.type==='test'||item.type==='lesson');
              let flags = this.course.PROPERTY_VALUES.flags;
              let moduleItem = {
                  id: id,
                  nav: nav,
                  course_id: this.course.ID,
                  save_id: this.userCourse.save_id,
                  sched_id: this.courseItem.sched_id,
                  alw_next: flags.alw_next,
                  time:flags.time,
                  start:this.userCourse.startTime,
                  for_course:flags.for_course
                };
              if(this.courseItem.program_chedule_id){
                moduleItem.program_chedule_id = this.courseItem.program_chedule_id;
                if(type==='test'){
                  this.programTestViewById(moduleItem);
                }else if(type==='lesson'){
                  this.programLessonViewById(moduleItem);
                }
              }else{
                this.$emit(meth, moduleItem);
              }
            },
            programLessonViewById(id_nav) {
                let $this = this;
                this.lesson_modal = false;
                this.test_modal = false;
                console.log('::programLessonViewById id_nav=', id_nav);
                this.parent.getServerTimeStamp().then(currentTime=> {
                  let time = parseInt(id_nav.time);
                  if (!isNaN(time) && time > 0 && id_nav.start) {
                    let delta = (currentTime - id_nav.start) / 60;
                    if (delta > time) {
                      //Превышено время на попытку
                      $this.parent.notify_title = this.parent.message[this.lang].bx24_alert;
                      $this.parent.notify_text = this.parent.message[this.lang].no_more_time;
                      $this.parent.notify_ok = this.parent.message[this.lang].tarif_understood;
                      $this.parent.notify = true;
                      $this.parent.currentScreen = '';
                      this.$nextTick(() => {
                        $this.parent.currentScreen = 'studentProfile';
                      });
                      return;
                    }
                  }
                  this.moduleItem = {
                    ID: id_nav.id,
                    nav: id_nav.nav,
                    course_id: id_nav.course_id,
                    save_id: id_nav.save_id,
                    sched_id: id_nav.sched_id,
                    program_chedule_id: id_nav.program_chedule_id,
                    alw_next: id_nav.alw_next,
                    time: id_nav.time,
                    start: id_nav.start,
                    for_course:id_nav.for_course
                  };
                  this.lesson_modal = true;
                });
              },
              programTestViewById(id_nav) {
                let $this = this;
                this.lesson_modal = false;
                this.test_modal = false;
                console.log(':1:programTestViewById id_nav=', id_nav);
                this.parent.getServerTimeStamp().then(currentTime=> {
                  let time = parseInt(id_nav.time);
                  if (!isNaN(time) && time > 0 && id_nav.start) {
                    let delta = (currentTime - id_nav.start) / 60;
                    if (delta > time) {
                      //Превышено время на попытку
                      $this.parent.notify_title = this.parent.message[this.lang].bx24_alert;
                      $this.parent.notify_text = this.parent.message[this.lang].no_more_time;
                      $this.parent.notify_ok = this.parent.message[this.lang].tarif_understood;
                      $this.parent.notify = true;
                      $this.parent.currentScreen = '';
                      this.$nextTick(() => {
                        $this.parent.currentScreen = 'studentProfile';
                      });
                      return;
                    }
                  }
                  this.moduleItem = {
                    ID: id_nav.id,
                    nav: id_nav.nav,
                    course_id: id_nav.course_id,
                    save_id: id_nav.save_id,
                    sched_id: id_nav.sched_id,
                    program_chedule_id: id_nav.program_chedule_id,
                    alw_next: id_nav.alw_next,
                    time: id_nav.time,
                    start: id_nav.start,
                    for_course:id_nav.for_course
                  };
                  this.test_modal = true;
                });
            },
            courseClose(arg){
              console.log('::courseView courseClose arg=',arg);
              this.lesson_modal = false;
              this.test_modal = false;
              if(arg.completed==='Y'){
                this.completeTheCourse();
              }
            },
            courseReturn(arg){
              console.log('::courseReturn ',arg);
              this.lesson_modal = false;
              this.test_modal = false;
              this.completeTheCourse();
            },
            moduleReturn(arg){
              let $this = this;
              const alw_next =  $this.course.PROPERTY_VALUES.flags.alw_next;
              console.log('::moduleReturn ',arg);
              let module = this.parent.isJSON(arg) ? JSON.parse(arg) : arg;

              this.setUserCoursePassed(module.module_id, module.type,module.passed);
              let course_passed = this.isUserCoursePassed();
              if(course_passed){
                $this.parent.notify_title = this.message[this.lang].attention;
                $this.parent.notify_text=this.message[this.lang].title+' '+this.message[this.lang].completed;
                $this.parent.notify_no_btn=true;
                $this.parent.notify = true;
                setTimeout(() => {
                    $this.parent.notify = false;
                    $this.parent.notify_no_btn=false;
                }, 2000);
                this.completeTheCourse();
              }else if(alw_next>0){
                this.addStage(module.module_id);
              }else{
                this.addStage();
              }
            },
            isUserCoursePassed(){
              //развернем дерево в плоский список (так легче обходить)
              let flatten = this.parent.flattenArray(this.course.PROPERTY_VALUES.course);
              this.reachFlatten(this.userCourse, flatten);//перенесем test_passed в flatten
              let passed = true;
              for (let i in flatten) {
                let item = flatten[i];
                if(item.type!='test' && item.type!='lesson'){
                  continue;
                }
                if(!item.test_passed){
                    passed = false;
                    break;
                }

              }
              console.log(':: isUserCoursePassed=', passed, flatten);
              return passed;
            },
            setUserCoursePassed(module_id, module_type, module_passed=false){
              // console.log('::isUserCoursePassed ',module_id, module_type, module_passed)
              if(this.userCourse.lessons) {
                for (let item of this.userCourse.lessons) {
                  //ставим модулю который только что пройден test_passed = true;
                  if(module_type==='lesson' && item.lesson_id == module_id){
                    item.test_passed = module_passed;
                    break;
                  }
                }
              }

              if(this.userCourse.tests) {
                for (let item of this.userCourse.tests) {
                  //ставим модулю который только что пройден test_passed = true;
                  if(module_type==='test' && item.test_id == module_id){
                    item.test_passed = module_passed;
                    break;
                  }
                }
              }
            },
            viewTreeItem(node){
              let $this = this;
              this.parent.getServerTimeStamp().then(currentTime=> {
                let delta = (currentTime - this.userCourse.startTime)/60;
                let id = node.id;
                let type = node.type;
                // console.log('::viewTreeItem id=' + id + ' type=' + type, node);
                let flags = this.course.PROPERTY_VALUES.flags;
                let nav = this.course.PROPERTY_VALUES.course;
                // console.log(`::currentTime=${currentTime} - start=${this.userCourse.startTime} = ${delta} / ${flags.time}`,)

                if(flags.time && delta > flags.time) {
                  //Превышено время на попытку
                  $this.parent.notify_title=this.message[this.lang].attention;
                  $this.parent.notify_text=this.message[this.lang].no_more_time;
                  $this.parent.notify_ok=this.message[this.lang].alert_cancel;
                  $this.parent.notify=true;
                  this.completeTheCourse();
                  return;
                }

                let item = {
                  ID: id,
                  nav: nav,
                  course_id: this.course.ID,
                  save_id: this.userCourse.save_id,
                  sched_id: this.courseItem.sched_id,
                  program_chedule_id: this.courseItem.program_chedule_id,
                  alw_next: flags.alw_next,
                  tree: flags.tree,
                  for_course:flags.for_course
                };
                if (type === 'lesson') {
                  this.testItem = null;
                  this.lessonItem = null;
                  this.$nextTick(() => {
                    this.lessonItem = item;
                  })
                } else if (type === 'test') {
                  this.testItem = null;
                  this.lessonItem = null;
                  this.$nextTick(() => {
                    this.testItem = item;
                  })
                }
                this.$refs.tree.setExpanded(node.name, true);
              });
            },
            getMyScore(){
                if(!this.course.PROPERTY_VALUES.rating || typeof this.course.PROPERTY_VALUES.rating !== 'object'){
                  this.course.PROPERTY_VALUES.rating = {users:[], average:0}
                }
                let countUsers = Object.keys(this.course.PROPERTY_VALUES.rating.users).length;
                if(countUsers > 0) {
                    for (let i in this.course.PROPERTY_VALUES.rating.users) {
                        let item = this.course.PROPERTY_VALUES.rating.users[i];
                        if (item.id === this.user_current.ID) {
                            this.rating = item.score;
                            break;
                        }
                    }
                }
            },
            setRating(){
                //console.log('::setRating ', this.rating, this.course.PROPERTY_VALUES.rating);
                let index = -1;
                for(let i in this.course.PROPERTY_VALUES.rating.users){
                    let item = this.course.PROPERTY_VALUES.rating.users[i];
                    if(item.id == this.user_current.ID){
                        index = parseInt(i);
                        break;
                    }
                }
                //
                if(index < 0){
                    //первый раз ставит оценку этому курсу
                    this.course.PROPERTY_VALUES.rating.users.push({
                            id:this.user_current.ID,
                            score:this.rating
                    });
                    //Баллы за оценку
                    this.parent.savePoints('Rc', this.course.ID);
                }else{
                    //исправляет оценку
                    this.course.PROPERTY_VALUES.rating.users[index].score = this.rating;
                }
                //считаем новое среднее
                let sumScores = 0;
                let countScores = 0;
                for(let j in this.course.PROPERTY_VALUES.rating.users) {
                    let item = this.course.PROPERTY_VALUES.rating.users[j];
                    sumScores += item.score;
                    countScores ++;
                }
                this.course.PROPERTY_VALUES.rating.average = sumScores/countScores;

                this.updateCourseRating();
                window.localStorage.setItem('courses','');
                window.localStorage.setItem('grp_courses', '');
              // eslint-disable-next-line vue/no-mutating-props
                this.parent.listCourses=null;
            },
            updateCourseRating(){
              // console.log('::updateCourseRating: ', this.course.PROPERTY_VALUES.rating);
              let $this = this;
              let method = 'entityItemUpdate';
              let params = {
                  'ENTITY': 'uni_courses',
                  'ID': parseInt(this.course.ID),
                  'PROPERTY_VALUES': {
                      target: this.course.PROPERTY_VALUES.target,
                      duration:JSON.stringify(this.course.PROPERTY_VALUES.duration),
                      program:this.course.PROPERTY_VALUES.program,
                      course:JSON.stringify(this.course.PROPERTY_VALUES.course),
                      rating:JSON.stringify(this.course.PROPERTY_VALUES.rating)
                  }
              };
                // BX24.callMethod(method, params, function(res){
                //     //console.log(res);
                //     // window.localStorage.setItem('courses', '');
                //     if(res.answer.error){
                //         console.error(res.answer.error_description);
                //     }else{
              var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

                if(res.result){
                    //console.log('The course rating updated ! ');
                }
                  // }
              }, "json");
            },
            initCourse(){
              let $this = this;
              // console.log(':*****:initCourse attempts=',this.attempts)
              return new Promise((resolve, reject) => {
                $this.getCourseById(this.courseItem.ID).then((kurs)=> {
                  console.log('::initCourse ',kurs)
                  if(kurs.PROPERTY_VALUES.flags.att && $this.attempts && $this.attempts > kurs.PROPERTY_VALUES.flags.att){
                    //Превышено количество попыток
                    $this.no_more_attempts = true;
                    $this.parent.notify_title=$this.message[$this.lang].attention;
                    $this.parent.notify_text=$this.message[$this.lang].no_more_attempts;
                    $this.parent.notify_ok=$this.message[$this.lang].alert_cancel;
                    $this.parent.notify=true;
                  }
                  if(kurs.PROPERTY_VALUES.flags.comp){
                    $this.loading_comps = true;
                    $this.parent.competenceGetList('competence', true).then(res=> {
                      $this.competence = [];
                      for(let comp_id of kurs.PROPERTY_VALUES.flags.comp) {
                        let comp=res.find(el => el.id == comp_id);
                        if(comp) {
                          $this.competence.push(comp);
                        }
                      }
                      $this.loading_comps = false;
                    });
                  }
                  $this.course = kurs;
                  $this.course.sched_id = $this.courseItem.sched_id;
                  // $this.course.program_chedule_id = $this.courseItem.program_chedule_id;
                  $this.course.isLoaded = true;
                  $this.getMyScore();

                  let for_course=$this.course.PROPERTY_VALUES.flags.for_course;
                  console.log('::for_course= ',for_course)
                  if(for_course && for_course.active && for_course.mode=='hand'){
                    //начиялем баллы заданные вручную
                    $this.pointsShow = $this.parent.showPoints('C', $this.course.ID, parseInt(for_course.value));
                  }else{
                    $this.pointsShow = $this.parent.showPoints('C', $this.course.ID);
                  }

                  if($this.courseItem.completed==='Y'){
                    $this.completeTheCourse();
                  }
                  resolve(true)
                }, error=>{
                  console.error(error);
                  $this.parent.notify_title=$this.message[$this.lang].alert_title;
                  $this.parent.notify_text=$this.message[$this.lang].alert_message;
                  $this.parent.notify_ok=$this.message[$this.lang].alert_cancel;
                  $this.parent.notify=true;
                  $this.$emit('goback', 'coursesList');
                  resolve(false);
                });
              });
            },
            getCourseById(id){
              let $this=this;
                // console.log(':1:getCourseById ', id);
              let params = {
                'ENTITY': 'uni_courses',
                'SORT': {},
                'FILTER': {
                  'ID': parseInt(id)
                }
              }
                return new Promise(function(resolve, reject) {

                  let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });
                  $this.parent.b24POST(url, $this.parent.bx24params, function (res) {

                    if (res?.result?.length > 0) {
                        var item = res.result[0];
                        item.PROPERTY_VALUES.course = JSON.parse(item.PROPERTY_VALUES.course);
                        item.PROPERTY_VALUES.duration = JSON.parse(item.PROPERTY_VALUES.duration);
                        if(item.PROPERTY_VALUES.rating){
                            item.PROPERTY_VALUES.rating = JSON.parse(item.PROPERTY_VALUES.rating);
                        }else{
                            item.PROPERTY_VALUES.rating = {users:[], average:0};
                        }
                        if($this.parent.isJSON(item.PROPERTY_VALUES.flags)) {
                          item.PROPERTY_VALUES.flags = JSON.parse(item.PROPERTY_VALUES.flags);
                          if(item.PROPERTY_VALUES.flags.alw_next===false){
                            item.PROPERTY_VALUES.flags.alw_next = 2; //Открывать последовательно. Если имеются тесты и не набран проходной балл, не открывать последующий урок.
                          }else if(item.PROPERTY_VALUES.flags.alw_next===true){
                            item.PROPERTY_VALUES.flags.alw_next = 1; //Открывать последовательно. Если имеются тесты и не набран проходной балл, все равно открывать.
                          }

                        }else{
                          item.PROPERTY_VALUES.flags = {comments_available:false, issue_cert:false, alw_next:0, aos:false, hide_cat:false, tree:false };
                        }

                        if (!item.PROPERTY_VALUES.flags.time) {
                          item.PROPERTY_VALUES.flags.time = 0;
                        }

                        if (!item.PROPERTY_VALUES.flags.att) {
                          item.PROPERTY_VALUES.flags.att = 0;
                        }

                      // //console.log('::getCourseById ', id, item);
                        resolve(item);
                    }else{
                        reject('Course Not found');
                        // console.error(':3:getCourseById');
                    }
                  }, "json");
                });
            },


        },
        components: {
          Comments,
          lessonView,
          testView
            // StarRating
        }
    }
</script>

<style scoped>

  .course-view-card{
    width: 100%;
  }
  .course-view-card-stuff{
    width: 500px;
    height: 15px;
    display: block;
  }

</style>

