<template>
  <div class="q-pa-md">
    <div class="row justify-between">
      <div class="col-3">
        <!-- Add button -->
        <q-btn  v-if="parent.isModer"
          color="primary"
          rounded
          icon="add"
          :label="message[lang].add_shelf"
          @click="openEditor(null)"
          class="q-mb-md"
        />
      </div>
      <div class="col-3 text-right">
        <UniArrayEdit v-if="parent.isModer && usedCatsIds"
            :title="message[lang].category"
            :parent="parent"
            :lang="lang"
            :message="message"
            :columns="cat_columns"
            :rows="cat_rows"
            :isReadOnly="false"
            :uniqueFields="['text']"
            :usedIds="usedCatsIds"
            @update:rows="handleUpdateCats"
          />
      </div>
    </div>
    <!-- Shelves list with drag and drop  -->
    <draggable v-if="shelves?.length > 0"
      v-model="shelves"
      item-key="id"
      class="bordered separator"
      handle=".drag-handle"
      @end="onDragEnd"
    >
      <template #item="{ element: shelf }">
        <div>
          <q-item class="q-my-sm" >
            <q-item-section avatar>
              <q-spinner-hourglass v-if="shelf.loading"
                    color="primary"
                    size="2em"
                  />
              <q-icon v-else name="drag_handle" class="drag-handle" style="cursor: move;" />
            </q-item-section>

            <q-item-section avatar @click.stop="handleExpand(shelf)" class="cursor-pointer">
              <q-avatar>
                <img :src="shelf.avatar" v-if="shelf.avatar">
                <q-icon name="inventory_2" v-else />
              </q-avatar>
            </q-item-section>

            <q-item-section @click.stop="handleExpand(shelf)" class="cursor-pointer">
              <q-item-label :class="shelf.isPublished?'text-primary':'text-positive'">{{ shelf.name }}</q-item-label>
            </q-item-section>

            <q-item-section side v-if="parent.isModer">
              <div class="row q-gutter-sm">
                
                <q-btn
                  flat
                  round
                  dense
                  icon="edit"
                  color="positive"
                  @click.stop="handleEdit(shelf)">
                    <q-tooltip>{{message[lang].eve_edit}}</q-tooltip>
                  </q-btn>

                <q-btn
                  flat
                  round
                  dense
                  icon="delete"
                  color="positive"
                  @click.stop="handleDelete(shelf)">
                    <q-tooltip>{{message[lang].remove}}</q-tooltip>
                  </q-btn>
              </div>
            </q-item-section>
          </q-item> 
          <!-- Expanded контент на новой строке -->
          <q-item v-if="shelf.expanded">
            <q-item-section>
              <div class="row justify-start">
                <q-btn  v-if="parent.isModer"
                  color="primary"
                  rounded
                  flat
                  icon="add"
                  :label="message[lang].add_item"
                  @click="openItemEditor(shelf, null)"
                  class="q-mb-md"
                />
              </div>


              <draggable v-if="shelf.items?.length > 0"
                v-model="shelf.items"
                item-key="id"
                class="row wrap q-col-gutter-xs"
                handle=".drag-handle"
                @end="onDragItemEnd"
              >
                <template #item="{ element: item }">
                  <q-card flat bordered class="item-card col-12 col-sm-6 col-md-4 bg-negative text-accent"
                    style="max-height:200px; overflow: hidden">
                    <q-item>
                      <q-item-section avatar top>
                        <div class="flex flex-center column">
                          <q-avatar class="q-my-sm drag-handle" style="cursor: move;">
                            <img v-if="item.avatar" :src="item.avatar">
                            <q-icon v-else size="lg" :name="getItemIcon(item.type)"/>
                          </q-avatar>
                          
                          <q-badge 
                            v-if="item.hosts" 
                            :label="item.hosts+'/'+item.hits" 
                            rounded 
                            transparent 
                            color="primary"
                            class="q-mx-auto" 
                          />
                        </div>
                      </q-item-section>

                      <q-item-section>
                        <div class="row no-wrap">
                          <div class="col-10" @click="parent.isModer?openItemEditor(shelf, item):''">
                            <div :class="item.isPublished?'h5 text-primary':'h5 text-positive'">
                              {{item.name}}
                            </div>
                            <div 
                              v-if="item.description"
                              class="h6 text-accent" 
                              style="white-space: pre-line;" >
                                {{item.description}}
                            </div>
                          </div>

                          <div class="col-2">
                          <!-- Редактировать -->
                            <q-btn v-if="parent.isModer" flat round icon="edit" @click="openItemEditor(shelf, item)">
                              <q-tooltip>{{message[lang].edit}}</q-tooltip>
                            </q-btn>
                            <!-- Удалить -->
                            <q-btn v-if="parent.isModer" flat round icon="clear" @click="removeItem(shelf, item)">
                              <q-tooltip>{{message[lang].remove}}</q-tooltip>
                            </q-btn>
                            <!-- Согласование -->
                            <q-btn v-if="item?.co_ordinators?.length>0" 
                              flat 
                              round 
                              icon="done" 
                              :color="item.isPublished?'secondary':'positive'"
                              @click="showagreeDialog(shelf, item)">
                              <q-tooltip>{{message[lang].degree360_agreeID}}</q-tooltip>
                            </q-btn>
                            <!-- Просмотр типа материала -->
                            <div>
                              <q-tooltip>{{message[lang].viewCourse}}</q-tooltip>

                              <typeSCORM v-if="item.type==='scorm'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                              <typePDF v-else-if="item.type==='pdf'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                              <typeFiles v-else-if="item.type==='files'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                              <typeLinks v-else-if="item.type==='links'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                              <typeLongread v-else-if="item.type==='longread'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                              <typeMedia v-else-if="item.type==='media'"
                                :entityId="item.id"
                                :mode_view="true"
                                :lang="lang"
                                :message="message"
                                :parent="parent"
                                :element="item.element"
                                :selfItem="item"
                                @item-checked="itemChecked"
                                />
                            </div>

                          </div>
                        </div>
                      </q-item-section>
                    </q-item>
                  </q-card>
                </template>
              </draggable>

              
              <div class="row justify-center" v-if="parent && shelf.allowComments">
                <div class="col-8">
                  <Comments 
                    v-bind:lang="lang" 
                    v-bind:entity-id="shelf.id" 
                    v-bind:user_current="parent.user_current" 
                    v-bind:parent="parent" 
                    v-bind:mode="'list'" 
                    v-bind:heght="700" />
                </div>
              </div>
              

              <q-inner-loading
                :showing="shelf.loading"
                color="primary"
                size="3em"
              />
            </q-item-section>
          </q-item>
          
        </div>
      </template>
    </draggable>

    <!-- Load more button -->
    <div class="text-center q-mt-md">
      <q-btn
        v-if="hasMoreItems"
        outline
        color="primary"
        icon="expand_more"
        label="Загрузить еще"
        @click="loadMore"
        :loading="isLoading"
      />
    </div>

    <!-- Editor -->
    <ShelfEditor
      v-model="showEditor"
      :shelf-data="currentShelf"
      :parent="parent"
      :lang="lang"
      :message="message"
      :saved_categories="cat_rows"
      @submit="handleShelfSubmit"
    />
    <!-- Item Editor -->
    <ItemEditor
      v-model="showItemEditor"
      :item-data="currentItem"
      :parent="parent"
      :lang="lang"
      :message="message"
      :saved_categories="cat_rows"
      :shelves="shelves"
      :shelf-data="currentShelf"
      @submit="handleItemSubmit"
      @close="handleItemClose"
    />
  </div>

   <!-- Диалог с компонентом согласования -->
    <q-dialog v-model="agreeDialog" persistent>
      <q-card style="width: 80%; max-width: 700px;">
        <q-bar class="bg-primary text-negative">
          <div>{{ message[lang].degree360_agreeID }}</div>
          <q-space />
          <q-btn round dense flat icon="done" v-close-popup @click="saveAgreements">
            <q-tooltip>{{message[lang].close}}</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          
          <agreements
              :parent="parent"
              :message="message"
              :lang="lang"
              :co_ordinators="currentItem.co_ordinators||[]"
              :mode="'view'"
            />
        </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script setup>
import { ref, onMounted, computed, nextTick } from 'vue'
import { useQuasar } from 'quasar'
import draggable from 'vuedraggable'
import ShelfEditor from 'src/components/BazaZnaniy/ShelfEditor.vue'
import ItemEditor from 'src/components/BazaZnaniy/ItemEditor.vue'
import UniArrayEdit from 'src/components/UniArrayEdit.vue'
import Comments from 'src/Comments.vue';
import typeSCORM from "src/components/BazaZnaniy/typeSCORM"
import typePDF from "src/components/BazaZnaniy/typePDF"
import typeFiles from "src/components/BazaZnaniy/typeFiles"
import typeLinks from "src/components/BazaZnaniy/typeLinks"
import typeLongread from "src/components/BazaZnaniy/typeLongread"
import typeMedia from "src/components/BazaZnaniy/typeMedia"
import agreements from "src/components/BazaZnaniy/agreements"

const $q = useQuasar()

// Props
const props = defineProps({
  initialShelves: {
    type: Array,
    default: () => []
  },
  hasMore: {
    type: Boolean,
    default: false
  },
  parent: Object,
  lang: {
    type: String,
    default: 'ru'
  },
  message: Object
})

// Component state
// const shelves = ref([...props.initialShelves])
const shelves = ref([])
const hasMoreItems = ref(props.hasMore)
const isLoading = ref(false)
const showEditor = ref(false)
const currentShelf = ref(null)
const showItemEditor = ref(false)
const currentItem = ref(null)
const agreeDialog = ref(false)

const POST = ref(props.parent.b24POST);

const cat_rows = ref([
    {id: 'books', text: props.message?.[props.lang]?.books },
    {id: 'documents', text: props.message?.[props.lang]?.documents },
    {id: 'media', text: props.message?.[props.lang]?.media },
    {id: 'other', text: props.message?.[props.lang]?.other },
  ]);
  const cat_columns = [
    { field: 'id', name: 'ID', align: 'left' },
    { field: 'text', name:  props.message?.[props.lang]?.title_name, align: 'left' }
  ];

  // const fltShelves = computed(()=>{
  //   return props.parent.isModer?shelves.value:shelves.value.filter(el=>el.isPublished);
  // });
  //в этот массив нужно сложить задйствованные категории  
  const usedCatsIds = computed(() => {
    return shelves.value?.length ? shelves.value.map(el => el.category) : []
  })

// Получение иконки для типа материала
const getItemIcon = (type) => {
  if (type === 'pdf') return 'picture_as_pdf';
  if (type === 'longread') return 'insert_drive_file';
  if (type === 'media') return 'play_circle';
  if (type === 'scorm') return 'folder_zip';
  if (type === 'files') return 'cloud_circle';
  if (type === 'links') return 'link';
  return 'insert_drive_file';
};

// Data loading
const loadData = async () => {
  isLoading.value = true
  shelves.value = await fetchShelves(!props.parent.isModer)
console.log('::loadData shelves.value=',shelves.value)
  isLoading.value = false
}

const fetchShelves = (isActiveOnly) => {
  let filter = {
    1: {
      LOGIC: "AND",
    },
  }
  if (isActiveOnly) {
    filter['1'].ACTIVE = 'Y'
  }

  let params = {
    ENTITY: "uni_shelves",
    SORT: { ID: "DESC" },
    FILTER: filter,
    LIMIT: 1000,
  }
  return new Promise( function (resolve, reject) {
    let url = props.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params })
    POST.value(url, props.parent.bx24params, async function (res) {
      let list = []
      if (res?.result?.length > 0) {
        for (let item of res.result) {
          const flags = props.parent.fromJSON(item.PROPERTY_VALUES.flags) || {}
          let shelfData = {
            id: item.ID,
            category: item.PREVIEW_TEXT,
            name: props.parent.restoreQuotes(item.NAME),
            description: props.parent.restoreQuotes(item.DETAIL_TEXT),
            avatar: item.DETAIL_PICTURE,
            isPublished: item.ACTIVE === 'Y',
            competence: props.parent.fromJSON(item.PROPERTY_VALUES.competence),
            allowedDepartments: props.parent.fromJSON(item.PROPERTY_VALUES.allowedDepartments),
            allowComments: flags.allowComments || false,
            points: flags.points || 0,
            hideFromExtranet: flags.hideFromExtranet || false,
            hideInCatalog: flags.hideInCatalog || false,
            showAOS: flags.showAOS || false
          }
          list.push(shelfData)
        }
      }

      // сначала смотрим личную сортировку
      let shelves_order = await props.parent.userOption('get', 'shelves_order');
      console.log(':1:shelves_order= ',shelves_order)
      if(!shelves_order){
        //личной нет смотрим общую
        shelves_order = await props.parent.appOptionGet('shelves_order');
        shelves_order = shelves_order?.DETAIL_TEXT;
        console.log(':2:shelves_order= ',shelves_order)
      }
      shelves_order = props.parent.fromJSON(shelves_order);
      console.log(':3:shelves_order= ',shelves_order)
      if(shelves_order?.length > 0){
        const sortedShelves = [...list].sort((a, b) => {
          const indexA = shelves_order.indexOf(a.id);
          const indexB = shelves_order.indexOf(b.id);
          
          // Если элемента нет в массиве порядка, помещаем его в конец
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          
          return indexA - indexB;
        });
        resolve(sortedShelves)
      }else{
        resolve(list)
      }
    })
  })
}



// Event handlers
const openEditor = (shelf) => {
  currentShelf.value = shelf || {}
  showEditor.value = true
}

const openItemEditor = (shelf, item) => {
  currentItem.value = item || {}
  currentShelf.value = shelf || {}
  showItemEditor.value = true
}

const getRelations = (shelf_id) => {
  console.log(':1:getRelations shelf_id=', shelf_id)
  let filter = {
    1: {
      LOGIC: "AND",
      'PREVIEW_TEXT': shelf_id, // id полки
      'DETAIL_TEXT': 'uni_shelves' //таблица родителя
    },
  };
  let params = {
    ENTITY: 'uni_items_own',
    FILTER: filter,
  }
  return new Promise( function (resolve, reject) {
    let url = props.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params })
    POST.value(url, props.parent.bx24params, async function (res) {
      console.log(':2:getRelations res=',res)
      resolve(res?.result || []);
    });
  });
};

const setRelations = (item_id, shelf_id) => {
   console.log(':1:setRelations item_id, shelf_id=',item_id, shelf_id)
  // проверить есть ли связь между материалом и полкой
  let filter = {
    1: {
      LOGIC: "AND",
      'NAME': item_id, // in NAME храним  id материала
      'PREVIEW_TEXT': shelf_id, // id полки
      'DETAIL_TEXT': 'uni_shelves' //таблица родителя
    },
  };
  let params = {
    ENTITY: 'uni_items_own',
    FILTER: filter,
  }
  return new Promise( function (resolve, reject) {
    let url = props.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params })
    POST.value(url, props.parent.bx24params, async function (res) {
      console.log(':2:setRelations res=',res)
      // если связи нет - добавить
      if (res?.result?.length > 0) {
        resolve(false);
      }else{
        let params = {
          'ENTITY': 'uni_items_own',
          'NAME': item_id,
          'PREVIEW_TEXT': shelf_id,
          'DETAIL_TEXT': 'uni_shelves'
        }
        const url = props.parent.bx24Init({ AJAX_ACTION: 'entityItemAdd', params: params })
        POST.value(url, props.parent.bx24params, async function (res) {
          console.log(':3:setRelations res=',res)
          resolve(res.ID)
        })
      }

    });

  });

};

const getRelation = (item_id, shelf_id) => {
  console.log(':1:removeRelation item_id, shelf_id=',item_id, shelf_id)
  // проверить есть ли связь между материалом и полкой
  let filter = {
    1: {
      LOGIC: "AND",
      'NAME': item_id, // in NAME храним  id материала
      'PREVIEW_TEXT': shelf_id, // id полки
      'DETAIL_TEXT': 'uni_shelves' //таблица родителя
    },
  };
  let params = {
    ENTITY: 'uni_items_own',
    FILTER: filter,
  }
  return new Promise( function (resolve, reject) {
    let url = props.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params })
    POST.value(url, props.parent.bx24params, async function (res) {
      resolve(res);
    });
  });

};

const removeRelation = (item_id, shelf_id) => {
  console.log(':1:removeRelation item_id, shelf_id=',item_id, shelf_id)
  
  return new Promise( async function (resolve, reject) {

    const res = await getRelation(item_id, shelf_id);
    if(res?.result[0]){
      const id = res.result[0].ID;
      console.log(':2:getRelation id=',id)
      const url = props.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params: { ENTITY: 'uni_items_own', ID: id }})
      POST.value(url, props.parent.bx24params, async function (res) {
        console.log(':2:removeRelation res=',res)
        resolve(res);
      });
    }else{
      console.log(':: Ошибка удаления связи res=',res)
      resolve(false);
    }
  });

};

const handleItemClose = (itemData) => {
  handleExpand(currentShelf.value, true)
};

const handleItemSubmit = (itemData) => {
  console.log(':1:handleItemSubmit itemData=',itemData)
  currentShelf.value.loading = true;
  let isPublished = itemData.isPublished ? 'Y' : 'N';
  //Если есть согласование, откорректируем публикацию
  if(itemData?.co_ordinators?.length>0){
    isPublished = 'Y';
    for(let group of itemData.co_ordinators){
      for(let i in group){
        if(!group[i].checked){
          isPublished = 'N';
          break;
        }
      }
    }
  }

  let method = 'entityItemAdd'
  let params = {
    'ENTITY': 'uni_items',
    'PREVIEW_TEXT': itemData.category,
    'NAME': props.parent.replaceQuotes(itemData.name),
    'DETAIL_TEXT': JSON.stringify(itemData.element),
    'DETAIL_PICTURE': itemData.avatar,
    'ACTIVE': isPublished,
    'PROPERTY_VALUES': {
      competence: JSON.stringify(itemData.competence),
      allowedDepartments: JSON.stringify(itemData.allowedDepartments),
      flags: JSON.stringify({
        allowComments: itemData.allowComments,
        points: itemData.points,
        hideFromExtranet: itemData.hideFromExtranet,
        hideInCatalog: itemData.hideInCatalog,
        showAOS: itemData.showAOS,
        type:itemData.type,
        co_ordinators: itemData.co_ordinators
      }),
    },
    'STUFF': itemData.description?props.parent.replaceQuotes(itemData.description):' '
  }

  if (itemData.id) {
    params.ID = parseInt(itemData.id)
    params.PROPERTY_VALUES.editor = props.parent.user_current.ID
    method = 'entityItemUpdate'
  } else {
    params.PROPERTY_VALUES.owner = props.parent.user_current.ID
    params.PROPERTY_VALUES.editor = props.parent.user_current.ID
  }
  console.log(':2:handleItemSubmit params=',params)
  const url = props.parent.bx24Init({ AJAX_ACTION: method, params: params })
  POST.value(url, props.parent.bx24params, async function (res) {
    itemData.id = res.result
    currentItem.value = itemData;
    // console.log(':2:handleItemSubmit res=',res)
    if(itemData.shelf != currentShelf.value.id){ //если полка не равна текущей удалим привязку к текущей
      await removeRelation(currentItem.value.id, currentShelf.value.id);
    }
    await setRelations(currentItem.value.id, itemData.shelf); //привяжем item к shelf

    handleExpand(currentShelf.value, true)

  })
};

const handleShelfSubmit = (shelfData) => {
  let method = 'entityItemAdd'
  let params = {
    'ENTITY': 'uni_shelves',
    'PREVIEW_TEXT': shelfData.category,
    'NAME': props.parent.replaceQuotes(shelfData.name),
    'DETAIL_TEXT': props.parent.replaceQuotes(shelfData.description),
    'DETAIL_PICTURE': shelfData.avatar,
    'ACTIVE': shelfData.isPublished ? 'Y' : 'N',
    'PROPERTY_VALUES': {
      competence: JSON.stringify(shelfData.competence),
      allowedDepartments: JSON.stringify(shelfData.allowedDepartments),
      flags: JSON.stringify({
        allowComments: shelfData.allowComments,
        points: shelfData.points,
        hideFromExtranet: shelfData.hideFromExtranet,
        hideInCatalog: shelfData.hideInCatalog,
        showAOS: shelfData.showAOS,
      }),
    }
  }
  if (shelfData.id) {
    params.ID = parseInt(shelfData.id)
    params.PROPERTY_VALUES.editor = props.parent.user_current.ID
    method = 'entityItemUpdate'
  } else {
    params.PROPERTY_VALUES.owner = props.parent.user_current.ID
    params.PROPERTY_VALUES.editor = props.parent.user_current.ID
  }
  const url = props.parent.bx24Init({ AJAX_ACTION: method, params: params })
  POST.value(url, props.parent.bx24params, async function (res) {
    shelves.value = await fetchShelves()
  })
}

const handleExpand = async (shelf, reload) => {
  console.log(':: handleExpand shelf:', shelf)
  currentShelf.value = shelf || {}
  // shelf.expanded = !shelf.expanded
  if(!shelf.expanded || reload){
    shelf.loading = true;
    const rels = await getRelations(shelf.id)
    console.log('::handleExpand rels=',rels)
    if(rels?.length>0){
      let arBatch={};
      for(const item of rels){
        let params = {
          'ENTITY': 'uni_items',
          'SORT': {},
          'FILTER': {
            'ID': parseInt(item.NAME)
          }
        };
        arBatch['get_' + item.NAME] = ['entityItemGet', params];
      }
      let list_items = await props.parent.callBatchIB(arBatch)
      // console.log('::handleExpand list_items=', list_items);
      let new_items = [];
      if(list_items?.result){
        for(let i in list_items.result){
          const item = list_items.result[i][0];
          // console.log('::handleExpand item=', item);
          const flags = props.parent.fromJSON(item?.PROPERTY_VALUES?.flags)||{}
          //проверка являюсь ли я согласующим
          let isImCoordinator=flags?.co_ordinators ? flags.co_ordinators.flat()
              .find(item => item.user.value == props.parent.user_current.ID) : false;

          if(item?.ID && (item.ACTIVE=='Y' || props.parent.isModer || isImCoordinator) ){
            const element = {
              id: item.ID,
              name: props.parent.restoreQuotes(item.NAME),
              description: !item.STUFF || item.STUFF=="null"?'':props.parent.restoreQuotes(item.STUFF),
              element: props.parent.fromJSON(item.DETAIL_TEXT),
              avatar: item.DETAIL_PICTURE,
              category: item.PREVIEW_TEXT,
              isPublished: item.ACTIVE=='Y',
              competence: props.parent.fromJSON(item?.PROPERTY_VALUES?.competence),
              allowComments: flags.allowComments,
              showAOS: flags.showAOS,
              points: flags.points,
              hideInCatalog: flags.hideInCatalog,
              hideFromExtranet: flags.hideFromExtranet,
              allowedDepartments:  props.parent.fromJSON(item?.PROPERTY_VALUES?.allowedDepartments),
              shelf:shelf.id,
              type:flags.type,
              co_ordinators: flags.co_ordinators
            };
            new_items.push(element);
          }
        }
        //запросим в uni_items_checks количество  хостов и хитов для каждого материала
        await getHostsAndHits(new_items);

        // console.log('::new_items= ',new_items)

        //`items_${currentShelf.value.id}_order`
        // сначала смотрим личную сортировку
        let items_order = await props.parent.userOption('get', `items_${currentShelf.value.id}_order`);
        console.log(':1:items_order= ',items_order)
        if(!items_order){
          //личной нет смотрим общую
          items_order = await props.parent.appOptionGet(`items_${currentShelf.value.id}_order`);
          items_order = items_order?.DETAIL_TEXT;
          // console.log(':2:items_order= ',items_order)
        }
        items_order = props.parent.fromJSON(items_order);
        // console.log(':3:items_order= ',items_order)
        if(items_order?.length > 0){
          const sortedItems = [...new_items].sort((a, b) => {
            const indexA = items_order.indexOf(a.id);
            const indexB = items_order.indexOf(b.id);
            
            // Если элемента нет в массиве порядка, помещаем его в конец
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            
            return indexA - indexB;
          });
          shelf.items = sortedItems;
        }else{
          shelf.items = new_items;
        }
      }
      


    }
    shelf.expanded = true;
    shelf.loading = false;
  }else{
    shelf.expanded = false;
  }
  
}

const getHostsAndHits = (listItems) => {
  // console.log('::getHostsAndHits listItems=', listItems);
  
  const mapItems = listItems.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  return new Promise (async(resolve)=>{
    if(listItems?.length > 0){
      const arIds = listItems.map(el=>parseInt(el.id));
      let arBatch = {}
      for(let item_id of arIds){
        arBatch['get_' + item_id] = ['entityItemGet',
            {
              ENTITY: 'uni_items_checks',
              FILTER: {NAME: item_id},
            },
          ];
      }
      const res = await props.parent.callBatchIB(arBatch);
      if(res?.result && Object.keys(res.result).length>0){
        for(let i in res.result){
          if(res.result[i].length>0){
            const item = res.result[i][0]
            const item_id = item.NAME
            const data = props.parent.fromJSON(item.DETAIL_TEXT)
            const hosts = Object.keys(data).length;
            const hits =  Object.values(data).reduce((sum, count) => sum + count, 0)
            mapItems[item_id].hosts = hosts;
            mapItems[item_id].hits = hits;
          }
        }
      }

    }

    resolve(true)
  })

}

const handleEdit = (shelf) => {
  openEditor(shelf)
}

const handleDelete = (shelf) => {
  $q.dialog({
    title: props.message[props.lang].confirm_del,
    message: `${props.message[props.lang].shelf_del} "${shelf.name}"?`,
    ok: {
      label:  props.message[props.lang].remove, // Текст кнопки подтверждения
      color: 'warning', // Цвет кнопки
      rounded: true
    },
    cancel: {
      label:  props.message[props.lang].prompt_cancel, // Текст кнопки отмены
      color: 'positive', // Цвет кнопки
      flat: true, // Плоский стиль кнопки
    },
    persistent: true
  }).onOk(async () => {
    const url = props.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params: { ENTITY: 'uni_shelves', ID: shelf.id }})
    POST.value(url, props.parent.bx24params, async function (res) {
      shelves.value = await fetchShelves()
      $q.notify({
        type: 'positive',
        position: 'center',
        multiLine: true,
        message: props.message[props.lang].shelf_deleted
      })
    })
  })
}
const saveAgreements = async (arg) => {
  console.log('::saveAgreements ',arg)
  await handleItemSubmit(currentItem.value)

    // agreeDialog.value = false;
  
}
const showagreeDialog = (shelf, item) =>{
  currentShelf.value = shelf;
  currentItem.value = item;
  agreeDialog.value = true;
}

const removeItem = (shelf, item) => {
  $q.dialog({
    title: props.message[props.lang].confirm_del,
    message: `${props.message[props.lang].item_del} "${item.name}"?`,
    ok: {
      label:  props.message[props.lang].remove, // Текст кнопки подтверждения
      color: 'warning', // Цвет кнопки
      rounded: true
    },
    cancel: {
      label:  props.message[props.lang].prompt_cancel, // Текст кнопки отмены
      color: 'positive', // Цвет кнопки
      flat: true, // Плоский стиль кнопки
    },
    persistent: true
  }).onOk(async () => {
    const url = props.parent.bx24Init({ 
      AJAX_ACTION: 'entityItemDel', 
      params: { ENTITY: 'uni_items', ID: item.id }
    })
    const itemIndex = shelf.items.findIndex(el=>el.id==item.id);
    POST.value(url, props.parent.bx24params, async function (res) {
      await removeRelation(item.id, shelf.id);
      if(itemIndex>=0){
        shelf.items.splice(itemIndex,1);
      }
      $q.notify({
        type: 'positive',
        position: 'center',
        multiLine: true,
        message: props.message[props.lang].item_deleted
      })
    })
  })
};

const loadMore = () => {
  loadData()
}

const onDragItemEnd = (arg) => {
  console.log('::onDragItemEnd currentShelf=', currentShelf.value)
  const items_order = currentShelf.value.items.map(el=>el.id);
  if(props.parent.isModer){
    props.parent.appOptionSet(`items_${currentShelf.value.id}_order`, JSON.stringify(items_order));
  }else{
    props.parent.userOption('set',`items_${currentShelf.value.id}_order`, JSON.stringify(items_order));
  }
}

const onDragEnd = () => {
  // Here you can add logic to save the new order to the server if needed
  console.log('New order:', shelves.value)
  const shelves_order = shelves.value.map(el=>el.id);
  if(props.parent.isModer){
    props.parent.appOptionSet('shelves_order', JSON.stringify(shelves_order));
  }else{
    props.parent.userOption('set','shelves_order', JSON.stringify(shelves_order));
  }
}

const handleUpdateCats = (rows) => {
  console.log(':: handleUpdateCats rows=', rows);
  cat_rows.value = rows;
  console.log(':: handleUpdateCats cat_rows.value=', cat_rows.value);
  let forsave=[];
  for(let rowItem of rows){
    forsave.push({
      id: rowItem.id,
      text: props.parent.replaceQuotes(rowItem.text),
    })
  }
  props.parent.appOptionSet('shelf_cats', JSON.stringify(forsave));
};


const itemChecked = (selfItem) => {
  console.log(':1:itemChecked selfItem=', selfItem)
  let user_id = props.parent.user_current.ID;
  let filter = {
    1: {
      LOGIC: "AND",
      'NAME': selfItem.id, // in NAME храним  id материала
    },
  };
  let params = {
    ENTITY: 'uni_items_checks',
    FILTER: filter,
  }

  let url = props.parent.bx24Init({ AJAX_ACTION: "entityItemGet", params: params })
  POST.value(url, props.parent.bx24params, (res)=>{
    console.log(':2:itemChecked res=',res)

    let method = 'entityItemAdd';
    let params = {
      'ENTITY': 'uni_items_checks',
      'NAME': selfItem.id,
      'DETAIL_TEXT': JSON.stringify({[user_id]: 1})
    }

    if (res?.result?.length > 0) {
      const item = res.result[0];
      method = 'entityItemUpdate';
      let data = props.parent.fromJSON(item.DETAIL_TEXT)
      data[user_id] = (data[user_id] || 0) + 1;
      console.log(':2:itemChecked data=',data)
      params.DETAIL_TEXT =  JSON.stringify(data);
      params.ID = item.ID;
    }
      
    const url = props.parent.bx24Init({ AJAX_ACTION: method, params: params })
    POST.value(url, props.parent.bx24params, (res)=>{
      console.log(':3:itemChecked res=',res)
      handleExpand(currentShelf.value, true)
    });

  });

}

// Initialize on mount
onMounted(() => {
  props.parent.appOptionGet('shelf_cats').then(data=>{
    console.log(':: handleUpdateCats shelf_cats data=', data);
    const rows =  props.parent.fromJSON(data.DETAIL_TEXT);
    if(rows){
      cat_rows.value=[];
      for(let rowItem of rows){
        cat_rows.value.push({
          id: rowItem.id,
          text: props.parent.restoreQuotes(rowItem.text),
        })
      }
    }
  });

  
  if (shelves.value.length === 0) {
    loadData()
  }
})
</script>

<style scoped>
.drag-handle {
  cursor: move;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.drag-handle:hover {
  opacity: 1;
}

/* Smooth transition for dragged items */
.flip-list-move {
  transition: transform 0.5s;
}

.item-card {
  margin: 3px;
  width: 100%;
  max-width: 350px;
  max-height:200px;
  flex: 1 1 auto;
  overflow: hidden;
}
.draggable-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Аналог q-gutter-sm */
}
</style>