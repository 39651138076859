/**
 * Yclients
 */

let yclUsersState=null;
let yclDepartments=[];
export function yclientsCurrentUser($this){
    const qp = $this.getQueryParams();
    console.log('::yclientsCurrentUser qp=',qp);
    let user_current={};
    return new Promise(async resolve=>{
        if(!qp?.salon_id || !qp?.hash || qp?.entity_type !== "employee"){
            $this.yclients=false;
            resolve(false)
          }else{
            $this.yclients=true;//Это похоже на yclients
            qp.yclients = 'Y';
            const url=`${$this.apiurl}api/yclients-currentUser`;
            qp.app_id = 'LMS-YCL';
            $this.POST(url, qp, async function (res) {
                if(res){
                    if($this.isJSON(res)){
                        res = JSON.parse(res);
                    }
                    console.log('::yclientsCurrentUser res=',res);
                    if(!res){
                        console.error('::Не получен текущий юзер, возможно требуется переустановка приложения');
                        $this.noBX24 = true;
                        resolve({});
                    }else{
                        let name = [res.name, res.user.name][res.name.length>res.user.name.length ? 0:1]
                        const [firstName, middleName, lastName] = name.split(' ');
                        console.log('::name= ',name, firstName, middleName, lastName)
                        user_current.ID = res.user_id;
                        user_current.email = res.user.email;
                        user_current.NAME = firstName;
                        user_current.SECOND_NAME = middleName;
                        user_current.LAST_NAME = lastName;
                        user_current.PERSONAL_PHOTO = res.avatar;
                        user_current.UF_DEPARTMENT = '';
                        user_current.WORK_POSTION = res.specialization+' '+res.position;
                        // if(res?._embedded?.groups?.length>0){
                        //     user_current.UF_DEPARTMENT=res._embedded.groups.map(el=>el.id);
                        // }

                        $this.member_id = res.member_id;
                        $this.portal = res.domain;
                        $this.lang = res.lang || "ru";
                        $this.isAdmin = res?.is_admin;
                        resolve(user_current)
                    }
                }else{
                    resolve({})
                }
            })
          }
    });

}

export function getUsersYCL($this, isActive, arDepartments, usersIds, color) {
  console.log('::getUsersYCL',isActive, arDepartments, usersIds);
  return new Promise(async (resolve, reject) => {
    if(!yclUsersState){
      yclUsersState = await getUsersYclients($this, isActive);
    }
    console.log('::getUsersYCL yclUsersState=',yclUsersState);

    if(usersIds?.length>0){
      let filtered = yclUsersState.filter(el=>usersIds.includes(el.id))
      resolve(filtered);
    }else{
      let filtered = yclUsersState.filter(user =>
        user.deps?user.deps.some(dep => arDepartments.includes(dep)):false
      );
      resolve(filtered);        
    }
  });
};


export function getUsersYclients($this, isActive=false, search=''){
    return new Promise((resolve) => {
    if(!yclUsersState){
      $this.callMethod('yclients-users', {}).then(res=>{
        console.log('::getUsersYclients res=',res);
        let users=[];
        if(res?.data?.length>0){
          for(let item of res.data){

            let UF_DEPARTMENT = '';

            let position = item?.position || item?.specialization;
            let user = {
              id: item.user_id,
              name: item.name,
              email: item?.user?.email || '',
              deps: UF_DEPARTMENT,
              photo: item?.user?.avatar,
              type: '',
              pos: position
            }
            users.push(user);

          }

        //   getDepartments(res._embedded.users);

        }
        yclUsersState = [...users];
        if(search){
          resolve(yclUsersState.filter(el=>{
            return el.name.indexOf(search)!==-1;
          }));
        }else{
          resolve(yclUsersState);
        }
      });
    }else{
      console.log('::getUsersYclients yclUsersState=',yclUsersState);
      if(search){
        let filtered = yclUsersState.filter(user => 
          user.name.toLowerCase().includes(search.toLowerCase()) || 
          user.email.toLowerCase().includes(search.toLowerCase())
        );
        console.log('::getUsersYclients filtered=',search,filtered);
        resolve(filtered);
      }else{
        resolve(yclUsersState);
      }
    }
  });
  
};

export function extraFilterFnYCL($this, needle){
  return new Promise(resolve => {
    getUsersYclients($this, true, needle).then(users=>{
      let list=[];
      if(users?.length>0) {
        for (let i in users) {
          let item = users[i];
          item.value = item.id;
          item.text = item.name;
          if(item.name)
            list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps, departments:item.departments, pos:item.pos, head:item.head, email:item.email});
        }
      }
      console.log('::extraFilterFnYCL list=',list)
      resolve(list);
    });
  });

};