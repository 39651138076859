import dayjs from 'dayjs'; // Импорт dayjs
import { Notify } from 'quasar';

/**
 * Создает ИПР по переданным компетенциям
 * @param {*} ar_id  - массив id компетенций
 * @param {*} $this - parent
 */
export const makeIPR = (ar_id, $this, user_sched=false) => {
  const user_current = user_sched || $this.parent.user_current;
  console.log(':# 1 #: makeIPR ar_id=',ar_id, user_current);
  const today = dayjs();
  return new Promise( async (resolve) => {
    if(ar_id?.length>0){
      //определим создавался ли уже ИПР по этой компетенции для текущего юзера
      const ipr_res = await $this.parent.competenceGetList('ipr', '', null, null, 1000, user_current.ID);
      console.log(':# 2 #: makeIPR competenceGetList=',ipr_res);
      const ipr_list = ipr_res?.list || [];
      let comps=[];
      let ipr_name='';
      let own_id=user_current.ID;
      let owner='';
      for(let comp_id of ar_id){ //по каждоый компетенции проверяем был ли ИПР
        if(!comp_id || isNaN(parseInt(comp_id))){
          console.log(':# 2.1 #: makeIPR bad comp_id=',comp_id);
          continue;
        }
        let isComp = false;
        for(let iprItem of ipr_list){
          if(iprItem?.comps?.length>0){
            isComp = iprItem.comps.find(el=>el.id==comp_id);
            if(isComp){
              break;
            }
          }
        }

        if(!isComp){
          console.log(':# 3 #: makeIPR компетенция еще не задавалась ',comp_id);
          const comp_res = await $this.parent.competenceGetById(comp_id);
          console.log(':# 4 #: makeIPR comp_res= ',comp_res);
          if(comp_res?.result?.length > 0){
            try{
              let competence = JSON.parse($this.parent.showBase64Text(comp_res.result[0].DETAIL_TEXT));
              console.log(':# 5 #: makeIPR компетенция ',competence);
              if(competence?.indicators?.length>0){
                for(let itemInd of competence.indicators){
                  if(itemInd?.courses?.length>0){
                    //Есть курсы
                    for(let itemCs of itemInd.courses){
                      let duration = $this.parent.isJSON(itemCs.duration)?JSON.parse(itemCs.duration):false;
                      let daysToAdd=1;
                      if(duration?.hours){
                        daysToAdd = duration.hours / 3; //считаем что не больше 3-х часов в день чел может учиться
                      }
                      itemCs.dln = today.add(daysToAdd, 'day').format('YYYY-MM-DD')+' 23:59:59';
                    }
                  }
                }
                own_id = competence?.ownner?.id || own_id;
                owner = competence?.ownner;
                comps.push(competence);
                ipr_name += competence.name+' ';
              }
              
            }catch(ex){
              console.error(ex);
            }
          }

        }

      }

      if(comps?.length>0){
        const user_name=(user_current.NAME||'')+' '+(user_current.SECOND_NAME||'')+' '+(user_current.LAST_NAME||'');
        let user={
          id:user_current.ID,
          value:user_current.ID,
          name:user_name,
          text:user_name
        }

        let ipr={
          name:ipr_name.trim(),
          comps:comps,
          manager: owner?owner:{id:own_id, name:'...'},
          mentor:  owner?owner:{id:own_id, name:'...'},
          person:user,
          own_id:own_id,
          published:'Y',
          status:'new',
          type:"auto"
        }
        console.log(':# 6 #: makeIPR ipr=',ipr);
        const name = user_current.ID+"|"+own_id+"|"+own_id+"|"+own_id
        let res = await $this.parent.competenceSave('ipr', name, ipr, null, true);
        console.log(':# 7 #: makeIPR res=',res);
        const message = user_sched?$this.message[$this.lang].sched_ipr_done:$this.message[$this.lang].make_ipr_done + ': ' + ipr.name;
        Notify.create({
          type: 'done',
          timeout: user_sched?'1000':'20000',
          actions: [{ icon: 'close', color: 'white' }],
          color: 'secondary',
          textColor:'negative',
          position: 'center',
          multiLine: true,
          progress: true,
          message: message,
        });
        resolve(res);
      }else{
        console.log(':# 8 #: makeIPR Такой ИПР уже есть');
        const message = user_sched?$this.message[$this.lang].sched_ipr_done:$this.message[$this.lang].make_ipr_done;
        Notify.create({
          type: 'done',
          timeout:  user_sched?'1000':'20000',
          actions: [{ icon: 'close', color: 'white' }],
          color: 'secondary',
          textColor:'negative',
          position: 'center',
          multiLine: true,
          progress: true,
          message: message,
        });
        resolve(false);
      }
     

    }else{
      resolve(false);
    }  
  })

};

/**
 * Готовит массив для отображения компетенций по их id 
 * @param {*} ar_id 
 * @param {*} comps_options 
 * @param {*} make_ipr 
 * @returns 
 */
export const showCompetence = (ar_id, comps_options, make_ipr) => {
  console.log(':uni_utils:showCompetence ',ar_id)
  let result={};
  if(ar_id?.length>0){
    for(let id of ar_id){
      const comp = comps_options.find(el=>el.id==id); //компетенция по id
      //индикаторы в компетенции
      if(comp?.indicators?.length>0){
        let ret=[];
        for(let ind of comp.indicators){
          if(ind?.rm?.length>0 || ind?.courses?.length>0){
            let item = {
              ind_name: ind.value, //название индикатора
              rm:ind.rm?ind.rm:[], //РМ
              cs:ind.courses?ind.courses.map(el=>el.label):[] //курсы
            }
            ret.push(item);
          }
        }
        if(ret.length>0){
          result[id]={name:comp.name, ind:ret}; //имя компетенции, индикаторы
        }
      }
    }
  }
  if(Object.keys(result).length==0){
    make_ipr = false; // признак если нет компетенций
  }

  console.log(':uni_utils:showCompetence make_ipr,result=', make_ipr,result)
  return {competences:result, updatedMakeIpr:make_ipr};
};

export const groupAdd = (mode, parent)=>{
    let $this = this;
    return new Promise(resolve => {
        parent.modal_list=[];
        parent.getGroupList().then(groups=>{

        parent.modal_list=[...groups];
        parent.modal_flt='';
        parent.model=null;
        if(mode=='id'){
            parent.modal_list_single = true;
            parent.modal_check_label = false;
        }else {
            parent.modal_list_single = false;
            parent.modal_check_label = parent.messageObj[parent.lang].selectgroup_check;
            parent.modal_check = false;
        }
        parent.modal_list_label = parent.messageObj[parent.lang].selectgroups_label;
        parent.modal_title = parent.messageObj[parent.lang].selectgroup_title;
        parent.modal_message = parent.messageObj[parent.lang].selectgroup_message;
        parent.modal_ok = parent.messageObj[parent.lang].selectusers_add;
        parent.modal_cancel = parent.messageObj[parent.lang].selectusers_finish;
        parent.modal_filterFn = null;


        parent.okModal =  ()=> {
            parent.modal_check_label = false;
            console.log('::modal_list_selected= ', parent.modal_list_selected)
            if(mode=='id'){
                let templateGroup_id = parent.modal_list_selected[0];
                let templateGroup = groups.find(el=>{return el.value == templateGroup_id});
                console.log('::templateGroupName= ', templateGroup, groups)
                resolve(templateGroup);
                parent.hideModal();
            }else {
                parent.modal_loading = true;
                let showGroupList = parent.modal_list.filter(el => parent.modal_list_selected.includes(el.value))
                let students_list = $this.getUsersForGroups([...parent.modal_list_selected]);
                resolve({showGroupList:showGroupList, students_list:students_list});
            }
        };
        parent.showModal();

        },err=>console.log('::err=',err));
    })
  };

  export const getUsersForGroups = (list, usersIds, parent, students_list)=>{
    let $this = this;
    students_list = students_list||[];
    // usersIds=usersIds?usersIds:[];
    if(list.length > 0){
      let grp_id = parseInt(list.pop());
      BX24.callMethod('sonet_group.user.get', {
        'ID': parseInt(grp_id)
      }, (result) => {
        let groupUsers = result.data();
        console.log('::sonet_group.user.get=', list.length+1, groupUsers);
        let listUserIds = groupUsers.map(el=>{return el.USER_ID});
        let newListUserIds=usersIds;
        if(parent.modal_check) {
          //Пересечение
          if (listUserIds && listUserIds.length > 0) {
            if (!usersIds) {
              newListUserIds = listUserIds;
            } else {
              newListUserIds = usersIds.filter(value => listUserIds.includes(value));
            }
          }
        } else {
          //Объединение
          usersIds=usersIds?usersIds:[];
          let mergedArray = usersIds.concat(listUserIds);
          newListUserIds = [...new Set(mergedArray)];
        }
        $this.getUsersForGroups(list, newListUserIds, parent, students_list);
      });
    }else{
      //Получены все id юзеров
      console.log(':: Получены все id юзеров=',usersIds)
      if (usersIds && usersIds.length > 0) {
        parent.getUsers(true, '', usersIds).then(users => {
          let items = [];
          for (let i in users) {
            let user = users[i];
            let user_id = parseInt(user.ID);
            let itemUser = {id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, dep: user.UF_DEPARTMENT, color:'primary', type:user.USER_TYPE};
            if (itemUser && !students_list.find(el => {return el.id == itemUser.id})){
              students_list.push(itemUser);
            }
          }
          console.log(':: students=',students_list)
          console.log(':: students_list=',students_list);
          parent.modal_loading = false;
          parent.hideModal();
          return students_list;
          // parent.getSubAccounts(students_list).then(res=>{
          //   students_list = students_list.concat(res);
          //   return students_list;
          //   console.log(':: students_list=',students_list);
          //   parent.modal_loading = false;
          //   parent.hideModal();
          // });
        }, err => {
          console.log(err);
        });
      }else{
        parent.modal_loading=false;
        parent.hideModal();
      }
    }

  };