<template>
  <div style="padding-left: 5px; padding-bottom: 100px;">
     <q-tabs
              v-model="tab"
              class="text-accent"
              active-color="primary"
              align="left"
            >
            <q-tab name="list_courses" :label="message[lang].list_courses" />
            <q-tab name="list_programs" :label="message[lang].list_programs" />
    </q-tabs>

      <q-tab-panels
        v-model="tab"
        class="bg-dark text-accent q-pr-md" >   

        <!-- Программы -->
        <q-tab-panel name="list_programs" style="height: 750px; padding:0px; margin:0px;">
            <!-- @goback="emitgoback" v-bind:back="backScreen" -->
          <list-programs
            v-bind:parent="parent"
            v-bind:lang="lang"
            
            v-bind:mntTab="'list_programs'"
            v-bind:hideTabs="true"
            v-bind:mode="'cat'"
          ></list-programs>
        </q-tab-panel>

        <!-- Курсы -->
        <q-tab-panel name="list_courses" style="height: 750px; padding:0px; margin:0px;">

          <div v-if="parent.isMobile" class="h4 q-ml-md" style="margin-top: -20px">
            <!-- {{message[lang].title}} -->
            <q-btn flat round color="secondary" icon="refresh" @click="loadCourses(true)"></q-btn>
            <q-btn flat round color="positive" :icon="isListViewCourse?'dashboard':'list'" @click="isListViewCourse=!isListViewCourse"></q-btn>
          </div>
          <div v-else class="row items-center justify-between">
            <div class="col-7 text-left q-mb-2">
              <div class="row items-center justify-between">
                <div class="col-5 h4">
                  <!-- {{message[lang].title}} -->
                  <q-btn flat rounded color="secondary" icon="refresh" @click="refreshList">
                    <q-tooltip class="bg-primary h-tt">{{message[lang].refresh}}</q-tooltip>
                  </q-btn>
                  <q-btn flat round color="positive" :icon="isListViewCourse?'dashboard':'list'" @click="isListViewCourse=!isListViewCourse">
                    <q-tooltip v-if="isListViewCourse" class="bg-primary h-tt">{{messageObj[lang].view_cards}}</q-tooltip>
                    <q-tooltip v-else class="bg-primary h-tt">{{messageObj[lang].view_list}}</q-tooltip>
                  </q-btn>
                </div>
              </div>
            </div>
            <div v-if="parent.isModer || parent.isAdmin" class="col-5 text-right q-gutter-xs" style="padding-right: 10px">
              <q-btn padding="xs" :loading="isLoading" size="lg" flat round color="secondary" icon="add_circle_outline" @click="addNewCourse"><q-tooltip class="bg-primary h-tt">{{message[lang].add}}</q-tooltip></q-btn>
              <q-btn padding="xs" :loading="isImportLoading" size="lg" flat round color="positive" icon="file_upload" @click="importCourseTogle"><q-tooltip class="bg-primary h-tt">{{message[lang].import}}</q-tooltip></q-btn>

              <!--  Линк для скачивания в десктопном приложении    -->
              <div v-if="file_download_loading">
                <q-spinner-oval color="positive" size="30px"/>
              </div>
              <div class="text-positive h4" v-else-if="file_download_link">
                {{message[lang].download}}: <span class="text-right text-primary"  v-html="file_download_link"/>
              </div>

            </div>

          </div>

          <div class="row justify-end" v-if="test_tags_opt && test_tags_opt.length>0">
            <div class="col-3">
              <q-select
                ref="TagsSelect"
                debounce="500" v-model="test_tags_find"
                :options="flt_test_tags_opt"
                :label="message[lang].tags"
                use-input use-chips
                @input-value="filterTags"
              />
            </div>
          </div>

          <div class="row justify-center" v-if="isShowImport">
            <div class="col-8">
              <course-import v-bind:lang="lang" v-bind:parent="parent"  @importCourses="importCourses" ></course-import>
            </div>
          </div>

      
          <!-- список курсов   -->
          <div v-if="isListViewCourse">
            <q-list separator>
              <q-item clickable v-ripple v-for="(item,index) in fltListCourses" :key="item.ID">
                <q-item-section avatar v-if="item.DETAIL_PICTURE">
                  <q-avatar rounded>
                    <q-img
                      class="rounded-borders"
                      :src="item.DETAIL_PICTURE"
                      spinner-size="2rem"
                      spinner-color="positive"
                    />
                  </q-avatar>
                </q-item-section>

                <q-item-section @click="viewCourse(item, index)">
                  <q-item-label class="text-positive h5">
                    <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">{{item.ID}}</span>
                    <span v-if="!razdel">{{parent.showCategoria(item.PREVIEW_TEXT)}}</span>
                  </q-item-label>
                  <q-item-label class="text-positive h4" lines="2">{{item.NAME}}</q-item-label>
                  <div v-if="item.CLOSED && (parent.isAdmin || parent.isModer)" class="text-left">
                    <q-separator/>
                    <div class="text-positive h5">--{{message[lang].closed_groups}}---</div>
                    <div v-for="groupItem in item.CLOSED" :key="groupItem.group_id">
                      <a class="text-positive h5" target="_blank" :href="'https://'+parent.portal+'/workgroups/group/'+groupItem.group_id+'/general/'">
                        {{groupItem.group_name}}
                      </a>
                    </div>
                  </div>
                </q-item-section>

                <q-item-section  side top class="cursor-pointer">
                    <q-rating @click="viewCourse(item,index)"
                      readonly
                      v-model="item.rating"
                      size="1.5em"
                      color="yellow-13"
                      icon="star_border"
                      icon-selected="star"
                    />

                  <q-item-label class="text-positive h5">
                    <q-icon name="date_range" :color="availPeriods(item)?'positive':'warning'" size="sm"/>
                    {{formatDate(item)}}
                  </q-item-label>
                  <q-badge v-if="courseComments['course_'+item.ID]" rounded  color="secondary" :label="message[lang].comments +courseComments['course_'+item.ID]"/>
                  <div class="q-gutter-xs" v-if="parent.isModer" align="right">
                    <q-icon :name="item.ACTIVE === 'Y'?'done_all':'remove_done'" :color="item.ACTIVE === 'Y'?'secondary':'warning'" size="sm">
                      <q-tooltip v-if="item.ACTIVE === 'Y'" class="bg-primary h-tt">{{message[lang].published}}</q-tooltip>
                      <q-tooltip v-else class="bg-warning h-tt">{{message[lang].notpublished}}</q-tooltip>
                    </q-icon>
                    <q-icon v-if="item.HIDE" name="hide_source" color="positive" size="md">
                      <q-tooltip class="bg-primary h-tt">{{parent.message[lang].hide_cat}}</q-tooltip>
                    </q-icon>
                    <q-btn flat round color="positive" icon="edit" @click="editCourse(item)">
                      <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
                    </q-btn>
                    <q-btn flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
                      <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
                    </q-btn>
                    <course-export  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpList="saveExpList" ></course-export>
                    <course-export  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpList="cloneExpList" ></course-export>
                  </div>
                </q-item-section>

              </q-item>
            </q-list>
          </div>
          <!--Карточки курсов-->
          <div v-else class="q-pa-md row items-start q-gutter-md">
            <q-card class="cours-card" v-for="(item,index) in fltListCourses" :key="item.ID">
              <q-card-section>
                <div class="row justify-between items-center">
                  <div class="col-8 q-gutter-x-md">
                    <q-btn unelevated color="primary" @click="viewCourse(item,index)">
                      {{message[lang].course}}
                      <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)">_{{item.ID}}</span>
                    </q-btn>
                    <q-img v-if="item.ACTIVE === 'Y'" style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/published.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].published}}</q-tooltip></q-img>
                    <q-img v-else style="cursor: pointer; height: 26px; width: 26px; margin-left: 10px"  src="img/card/cours/secondary/doc/draft.svg"><q-tooltip class="bg-primary h-tt">{{message[lang].notpublished}}</q-tooltip></q-img>
                    <q-icon v-if="parent.isModer && item.HIDE" name="hide_source" color="positive" size="md">
                      <q-tooltip class="bg-primary h-tt">{{parent.message[lang].hide_cat}}</q-tooltip>
                    </q-icon>
                  </div>
                  <div class="col-4 text-right q-gutter-x-sm text-positive">
                    <q-icon name="date_range" :color="availPeriods(item)?'positive':'warning'" size="sm"/>
                    {{formatDate(item)}}
                    <q-badge v-if="courseComments['course_'+item.ID]" rounded  color="secondary" :label="message[lang].comments +courseComments['course_'+item.ID]"/>
                  </div>
                  <div class="col-12 text-positive h5 q-pt-xs">{{parent.showCategoria(item.PREVIEW_TEXT)}}</div>
                </div>
              </q-card-section>

              <q-card-section class="cursor-pointer" horizontal @click="viewCourse(item,index)">
                <q-card-section v-if="item.DETAIL_PICTURE" class="col-5 flex flex-center">
                  <q-img
                    class="rounded-borders"
                    :src="item.DETAIL_PICTURE"
                    spinner-size="2rem"
                    spinner-color="positive"
                  />
                </q-card-section>

                <q-card-section class="q-pa-xs text-positive">
                  {{item.NAME}}
                </q-card-section>

              </q-card-section>
              <q-separator/>
              <q-card-section>
                <div class="row justify-between items-center">
                  <div class="col-12 text-left cursor-pointer" @click="viewCourse(item,index)">
                      <q-rating
                        readonly
                        v-model="item.rating"
                        size="1.5em"
                        color="yellow-13"
                        icon="star_border"
                        icon-selected="star"
                      />

                  </div>
                  <div class="col-10 offset-2 text-right">
                      <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="edit" @click="editCourse(item)">
                        <q-tooltip class="bg-primary h-tt">{{message[lang].edit}}</q-tooltip>
                      </q-btn>
                      <q-btn v-if="parent.isModer || parent.isAdmin" flat round color="positive" icon="delete_outline" @click="showModalDel(item,index)">
                        <q-tooltip class="bg-primary h-tt">{{message[lang].remove}}</q-tooltip>
                      </q-btn>
                      <course-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" @saveExpList="saveExpList" ></course-export>
                      <course-export v-if="parent.isModer || parent.isAdmin"  v-bind:lang="lang"  v-bind:id="item.ID" v-bind:list="[]" v-bind:parent="parent" v-bind:is-clone="true" @cloneExpList="cloneExpList" ></course-export>
                  </div>
                </div>
                <div v-if="item.CLOSED && (parent.isAdmin || parent.isModer)" class="text-left">
                  <q-separator/>
                    <div class="text-positive h4">--{{message[lang].closed_groups}}---</div>
                    <div v-for="groupItem in item.CLOSED" :key="groupItem.group_id">
                        <a class="positive h4" target="_blank" :href="'https://'+parent.portal+'/workgroups/group/'+groupItem.group_id+'/general/'">
                            {{groupItem.group_name}}
                        </a>
                    </div>
                </div>
              </q-card-section>


            </q-card>
          </div>

          <div v-if="parent.justSearched && Object.keys(parent.listCourses).length === 0">
            {{message[lang].notfound}}
          </div>
          <div class="text-center" v-else-if="isMore">
            <q-btn v-if="isListViewCourse" @click="getCoursesVsClosed(true)" round flat color="positive" icon="keyboard_arrow_down" />
            <q-btn v-else @click="getCoursesVsClosed(true)" class="glossy" size="lg" round color="primary" icon="keyboard_arrow_down" />
          </div>
          <div v-if="isLoading" class="text-center"><q-spinner-oval color="accent" size="48px"/></div>
        </q-tab-panel>
      </q-tab-panels>
    
   
    
  </div>
</template>
<script>
    import { ref } from 'vue';
    import dayjs from 'dayjs'
    import courseExport from "./courseExport.vue";
    import courseImport from "./courseImport.vue";
    import {saveAs} from "file-saver";
    import XLSX from 'xlsx';
    // import {messageObj} from "src/helpers/message";
    import listPrograms from "src/ListOfPrograms";
    
    export default {
        props:{
            parent:Object,
            lang:String,
            categoria:String,
            back:String
        },
        // emits: ['emitgoback'],
        data: function () {
            return {
              tab:'list_courses',
              isListViewCourse:false,
              courseComments:{},
              flt_test_tags_opt:[],
              test_tags_find:null,
              test_tags_opt:'',
              file_download_link:'',
              file_download_loading:false,
              start:0,
              total:0,
              count:15,

              aRating:ref(''),
                goback:'',
                expFormat_selected:'xlsx',
                isImportLoading:false,
                isShowImport:false,
                isLoading:false,
                // listCourses:[],
                course_id:0,
                course_idx:-1,
                pics_courses:[],
                lastCategoria:-1,
              //   level_options: {
              //       ru: [
              //           {text: 'легкий', value: 'low'},
              //           {text: 'средний', value: 'moderate'},
              //           {text: 'сложный', value: 'high'},
              //       ],
              //       en: [
              //           {text: 'low', value: 'low'},
              //           {text: 'moderate', value: 'moderate'},
              //           {text: 'high', value: 'high'},
              //       ],
              //     ua: [
              //       {text: 'легкий', value: 'low'},
              //       {text: 'середній', value: 'moderate'},
              //       {text: 'складний', value: 'high'},
              //     ],
              //     fr:[
              //       {text: 'faible', value: 'low'},
              //       {text: 'moyen', value: 'moderate'},
              //       {text: 'fort', value: 'high'},
              //     ],
              //     it: [
              //       {text: 'basso', value: 'low'},
              //       {text: 'moderato', value: 'moderate'},
              //       {text: 'alto', value: 'high'},
              //     ],
              //     tr: [
              //       {text: 'düşük', value: 'low'},
              //       {text: 'ılıman', value: 'moderate'},
              //       {text: 'yüksek', value: 'high'},
              //     ],
              //     de: [
              //       {text: 'niedrig', value: 'low'},
              //       {text: 'mäßig', value: 'moderate'},
              //       {text: 'hoch', value: 'high'},
              //     ],
              //     pl: [
              //       {text: 'niska', value: 'low'},
              //       {text: 'umiarkowany', value: 'moderate'},
              //       {text: 'wysoka', value: 'high'},
              //     ],
              //     pt: [
              //       {text: 'baixo', value: 'low'},
              //       {text: 'moderado', value: 'moderate'},
              //       {text: 'alto', value: 'high'},
              //     ],
              //     es: [
              //       {text: 'bajo', value: 'low'},
              //       {text: 'moderado', value: 'moderate'},
              //       {text: 'alto', value: 'high'},
              //     ],
              //     vn: [
              //       {text: 'thấp', value: 'low'},
              //       {text: 'vừa phải', value: 'moderate'},
              //       {text: 'cao', value: 'high'},
              //     ],

              //   },
              //   typeTest_options: {
              //       ru: [
              //           {text: 'Итоговый', value: 'final'},
              //           {text: 'Промежуточный', value: 'midpoint'},
              //       ],
              //       en: [
              //           {text: 'Final', value: 'final'},
              //           {text: 'Midpoint', value: 'midpoint'},
              //       ],
              //     ua: [
              //       {text: 'Підсумковий', value: 'final'},
              //       {text: 'Проміжний', value: 'midpoint'},
              //     ],
              //     fr: [
              //       {text: 'Final', value: 'final'},
              //       {text: 'Moyenne', value: 'midpoint'},
              //     ],
              //     it: [
              //       {text: 'Finale', value: 'final'},
              //       {text: 'Intermedio', value: 'midpoint'},
              //     ],
              //     tr: [
              //       {text: 'Son', value: 'final'},
              //       {text: 'Orta nokta', value: 'midpoint'},
              //     ],
              //     de: [
              //       {text: 'Finale', value: 'final'},
              //       {text: 'Mittelpunkt', value: 'midpoint'},
              //     ],
              //     pl: [
              //       {text: 'Finał', value: 'final'},
              //       {text: 'Środek', value: 'midpoint'},
              //     ],
              //     pt: [
              //       {text: 'Final', value: 'final'},
              //       {text: 'Ponto médio', value: 'midpoint'},
              //     ],
              //     es: [
              //       {text: 'Final', value: 'final'},
              //       {text: 'Punto medio', value: 'midpoint'},
              //     ],
              //     vn: [
              //       {text: 'Cuối cùng', value: 'final'},
              //       {text: 'Điểm giữa', value: 'midpoint'},
              //     ],

              //   },
              // typeQuest_options: {
              //   ru: [
              //     {text: 'Выбор', value: 'qa'},
              //     {text: 'Последовательность', value: 'seq'},
              //     {text: 'Соответствие', value: 'eqw'},
              //     {text: 'Открытый', value: 'opn'},
              //     {text: 'Подстановка', value: 'sub'}
              //   ],
              //   en: [
              //     {text: 'Option', value: 'qa'},
              //     {text: 'Sequencing', value: 'seq'},
              //     {text: 'Matching', value: 'eqw'},
              //     {text: 'Open', value: 'opn'},
              //     {text: 'Substitution', value: 'sub'}
              //   ],
              //   ua: [
              //     {text: 'вибір', value: 'qa'},
              //     {text: 'послідовність', value: 'seq'},
              //     {text: 'відповідність', value: 'eqw'},
              //     {text: 'Відкритий', value: 'opn'},
              //     {text: 'Підстановка', value: 'sub'}
              //   ],
              //   fr:[
              //     {text: 'Choix multiple', value: 'qa'},
              //     {text: 'Séquence', value: 'seq'},
              //     {text: 'Correspondance', value: 'eqw'},
              //     {text: 'Ouvert', value: 'opn'},
              //     {text: 'Substitution', value:'sub'}
              //   ],
              //   it: [
              //     {text: 'Opzione', value: 'qa'},
              //     {text: 'Sequenziamento', value: 'seq'},
              //     {text: 'Matching', value: 'eqw'},
              //     {text: 'Aperto', value: 'opn'},
              //     {text: 'Sostituzione', value:'sub'}
              //   ],
              //   tr: [
              //     {text: 'Seçenek', value: 'qa'},
              //     {text: 'Sıralama', value: 'seq'},
              //     {text: 'Eşleştirme', value: 'eqw'},
              //     {text: 'Açık', value: 'opn'},
              //     {text: 'Ikame', value: 'sub'}
              //   ],
              //   de: [
              //     {text: 'Möglichkeit', value: 'qa'},
              //     {text: 'Sequenzierung', value: 'seq'},
              //     {text: 'Passende', value: 'eqw'},
              //     {text: 'Offen', value: 'opn'},
              //     {text: 'Substitution', value: 'sub'}
              //   ],
              //   pl: [
              //     {text: 'Opcja', value: 'qa'},
              //     {text: 'Sekwencjonowanie', value: 'seq'},
              //     {text: 'Dopasowanie', value: 'eqw'},
              //     {text: 'Otwarty', value: 'opn'},
              //     {text: 'Podstawienie', value: 'sub'}
              //   ],
              //   pt: [
              //     {text: 'Opção', value: 'qa'},
              //     {text: 'Sequenciamento', value: 'seq'},
              //     {text: 'Coincidindo', value: 'eqw'},
              //     {text: 'Abrir', value: 'opn'},
              //     {text: 'Substituição', value:'sub'}
              //   ],
              //   es: [
              //     {text: 'Opción', value: 'qa'},
              //     {text: 'Secuenciación', value: 'seq'},
              //     {text: 'Pareo', value: 'eqw'},
              //     {text: 'Abierto', value: 'opn'},
              //     {text: 'Sustitución', value:'sub'}
              //   ],
              //   vn: [
              //     {text: 'Lựa chọn', value: 'qa'},
              //     {text: 'Giải trình tự', value: 'seq'},
              //     {text: 'Phù hợp', value: 'eqw'},
              //     {text: 'Mở ra', value: 'opn'},
              //     {text: 'Thay', value: 'sub'}
              //   ],

              // },
              // message:{
                  // ru:{
                  //   list_courses:"Курсы",
                  //   list_programs:"Программы",
                  //   QUEST_BLOCK_NAME:"Название блока",
                  //   QUEST_BLOCK_ID:"N блолка",
                  //   QUEST_BLOCK_COUNT:"Кол-вл вопросов",
                  //   QUEST_BLOCK_RAND:"Случайно",
                  //   TEST_FLAGS:"Служебные отметки",
                  //   LOGO_PICTURE:"Логотип",
                  //   TEST_BLOCKS:"Блоки",
                  //   QUEST_BLOCK:"Блок",
                  //   comments:"К.",
                  //   direct_link:"Прямая ссылка",
                  //   copy:"Копия",
                  //   tags:"Тэги",
                  //   download:"Скачать",
                  //   edit:"Редактировать",
                  //   refresh:"Обновить список",
                  //   date_pub:"Дата публикации",
                  //   status:"Статус",
                  //   maxCount:3,
                  //   goback:"Вернуться",
                  //   title:"Список курсов",
                  //   loading:"Загрузка",
                  //   remove:"Удалить",
                  //   add:"Добавить курс",
                  //   course:"Курс",
                  //   confirm_del:"Подтвердите удаление",
                  //   cancel :"Отмена",
                  //   ok:"Да",
                  //   viewCourse:"Смотреть",
                  //   notpublished:"Черновик",
                  //   published:"Опубликован",
                  //   notfound:"Ничего не найдено",
                  //   NAME:"Название курса",
                  //   TARGET:"Цель",
                  //   DURATION_H:"Продолжительность, часы",
                  //   DURATION_L:"Продолжительность, уроки",
                  //   PROGRAM:"Проаграмма",
                  //   THEMATICS:"Тематика",
                  //   YOUTUBE:"Ролик в youtube",
                  //   NAME_LESSON:"Название урока",
                  //   NAME_TEST:"Название теста",
                  //   DESCRIPTION:"Описание",
                  //   INTERVAL:"Интервал тестирования в днях",
                  //   TODISPLAY:"Сколько вопросов задавать",
                  //   LEVEL:"уровень теста",
                  //   SCORE:"Количество правильных ответов для положительной оценки",
                  //   TIME:"Время на тест",
                  //   TYPE:"Тип теста",
                  //   QUEST_TEXT:"Текст вопроса",
                  //   QUEST_TYPE:"Тип вопроса",
                  //   ANSW_TEXT:"Текст ответа",
                  //   right:'правильный',
                  //   import:"Импорт",
                  //   closed_groups:"Закрытые группы",
                  //   QUEST_WEIGHT:"Вес вопроса"
                  // },
              //     en:{
              //       list_courses: "Courses",
              //       list_programs: "Programs",
              //       QUEST_BLOCK_NAME:"Block name",
              //       QUEST_BLOCK_ID:"N block",
              //       QUEST_BLOCK_COUNT:"Qty",
              //       QUEST_BLOCK_RAND:"Random",
              //       TEST_FLAGS:"Service Flags",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Blocks",
              //       QUEST_BLOCK:"Block",
              //       comments:"C.",
              //       direct_link:"Direct link",
              //       copy:"Copy",
              //       tags:"Tags",
              //       download:"Download",
              //       edit:"Edit",
              //       refresh: "Refresh the list",
              //           date_pub:"Date of publication",
              //           status:"Status",
              //           goback:"Back",
              //           title:"Course list",
              //           loading:"Loading",
              //           remove:"Remove",
              //           add:"Add a course",
              //           course:"Course",
              //           confirm_del:"Confirm deletion",
              //           cancel :"Cancel",
              //           ok:"OK",
              //           viewCourse:"View",
              //           notpublished:"Draft",
              //           published:"Published",
              //           notfound:"Nothing found",
              //           NAME:"Course name",
              //           TARGET:"Goal",
              //           DURATION_H:"Duration, hours",
              //           DURATION_L:"Duration, lessons",
              //           PROGRAM:"Program",
              //           THEMATICS:"Subject",
              //           YOUTUBE:"YouTube video",
              //           NAME_LESSON:"Lesson name",
              //           NAME_TEST:"Test name",
              //           DESCRIPTION:"Description",
              //           INTERVAL:"Testing interval, days",
              //           TODISPLAY:"How many questions to display",
              //           LEVEL:"test level",
              //           SCORE:"Number of correct answers for positive assessment",
              //           TIME:"Testing time",
              //           TYPE:"Test type",
              //           QUEST_TEXT:"Question",
              //           QUEST_TYPE:"Question type",
              //           ANSW_TEXT:"Answer",
              //           right:'correct',
              //           import:"Import",
              //           closed_groups:"Closed classes",
              //         QUEST_WEIGHT:"Question weight"
              //       },
              //     ua:{
              //       list_courses: "Курси",
              //       list_programs: "Програми",
              //       QUEST_BLOCK_NAME:"Назва блоку",
              //       QUEST_BLOCK_ID:"N блоку",
              //       QUEST_BLOCK_COUNT:"У питаннях",
              //       QUEST_BLOCK_RAND:"Випадково",
              //       TEST_FLAGS:"Службові позначки",
              //       LOGO_PICTURE:"Логотип",
              //       TEST_BLOCKS:"Блоки",
              //       QUEST_BLOCK: "Блок",
              //       comments:"К.",
              //       direct_link:"Пряме посилання",
              //       copy: "копія",
              //       tags: "теги",
              //       download:"Завантажити",
              //       edit: "Редагувати",
              //       refresh: "Оновити список",
              //       date_pub:"Дата публікації",
              //       status:"Статус",
              //       maxCount:3,
              //       goback:"Повернутися",
              //       title:"Список курсів",
              //       loading:"Завантаження",
              //       remove:"Видалити",
              //       add:"Добавити курс",
              //       course:"Курс",
              //       confirm_del:"Підтвердіть видалення",
              //       cancel :"Відміна",
              //       ok:"Так",
              //       viewCourse:"Дивитися",
              //       notpublished:"Чернетка",
              //       published:"Опублікований",
              //       notfound:"Нічого не знайдено",
              //       NAME:"Назва курсу",
              //       TARGET:"Мета",
              //       DURATION_H:"Тривалість, години",
              //       DURATION_L:"Тривалість, уроки",
              //       PROGRAM:"Програма",
              //       THEMATICS:"Тематика",
              //       YOUTUBE:"Ролик в youtube",
              //       NAME_LESSON:"Назва уроку",
              //       NAME_TEST:"Назва тесту",
              //       DESCRIPTION:"Опис",
              //       INTERVAL:"Інтервал тестування у днях",
              //       TODISPLAY:"Скільки питань задавати",
              //       LEVEL:"Рівень тесту",
              //       SCORE:"Кількість правильних відповідей для позитивної оцінки",
              //       TIME:"Час на тест",
              //       TYPE:"Тип тесту",
              //       QUEST_TEXT:"Текст вопросу",
              //       QUEST_TYPE:"Тип вопросу",
              //       ANSW_TEXT:"Текст відповіді",
              //       right:'правильний',
              //       import:"Імпорт",
              //       closed_groups:"Закриті групи",
              //       QUEST_WEIGHT:"Вага питання"
              //     },
              //     fr:{
              //       list_courses: "Cours",
              //       list_programs: "Programmes",
              //       QUEST_BLOCK_NAME:"Nom du bloc",
              //       QUEST_BLOCK_ID:"Bloc N",
              //       QUEST_BLOCK_COUNT:"Quantité",
              //       QUEST_BLOCK_RAND:"Aléatoire",
              //       TEST_FLAGS: "Drapeaux de service",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Blocs",
              //       QUEST_BLOCK:"Bloquer",
              //       comments:"C.",
              //       direct_link:"Lien direct",
              //       copy: "Copie",
              //       tags: "Tags",
              //       download: "Télécharger",
              //       edit: "Modifier",
              //       refresh: "Actualiser la liste",
              //       date_pub:"Date de publication",
              //       status:"Statut",
              //       goback:"Retour",
              //       title:"Intitulé de l’enseignement",
              //       loading:"Chargement",
              //       remove:"Retirer",
              //       add:"Ajouter un enseignement",
              //       course:"Enseignement",
              //       confirm_del:"Confirmer la suppression ",
              //       cancel :"Annuler",
              //       ok:"OK",
              //       viewCourse:"Regarder",
              //       notpublished:"Brouillon",
              //       published:"Publié",
              //       notfound:"Aucun résultat",
              //       NAME:"Titre de l’enseignement",
              //       TARGET:"Objectif",
              //       DURATION_H:"Durée en heures",
              //       DURATION_L:"Durée en nb de leçons",
              //       PROGRAM:"Programme",
              //       THEMATICS:"Thème",
              //       YOUTUBE:"Vidéo YouTube",
              //       NAME_LESSON:"Intitulé de la leçon",
              //       NAME_TEST:"Intitulé du test ",
              //       DESCRIPTION:"Description",
              //       INTERVAL:"Intervalle entre deux tests, en jours",
              //       TODISPLAY:"Nombre de questions à afficher",
              //       LEVEL:"Niveau du test ",
              //       SCORE:"Nombre de réponses correctes pour réussir le test",
              //       TIME:"Durée du test",
              //       TYPE:"Type de test ",
              //       QUEST_TEXT:"Question",
              //       QUEST_TYPE:"Type de question",
              //       ANSW_TEXT:"Réponse",
              //       right:"Exact",
              //       import:"Importer",
              //       closed_groups:"Classes privées",
              //       QUEST_WEIGHT:"Coefficient de la question"
              //     },
              //     it:{
              //       list_courses: "Corsi",
              //       list_programs: "Programmi",
              //       QUEST_BLOCK_NAME:"Nome blocco",
              //       QUEST_BLOCK_ID:"N blocco",
              //       QUEST_BLOCK_COUNT:"Qtà",
              //       QUEST_BLOCK_RAND:"Casuale",
              //       TEST_FLAGS:"Flag di servizio",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Blocchi",
              //       QUEST_BLOCK:"Blocca",
              //       comments:"C.",
              //       direct_link:"Collegamento diretto",
              //       copy:"Copia",
              //       tags:"Tag",
              //       download:"Scarica",
              //       edit: "Modifica",
              //       refresh: "Aggiorna elenco",
              //       date_pub:"Data di pubblicazione",
              //       status:"Status",
              //       goback:"Indietro",
              //       title:"Lista corsi",
              //       loading:"Caricamento",
              //       remove:"Rimuovi",
              //       add:"Aggiungi corso",
              //       course:"Corso",
              //       confirm_del:"Conferma eliminazione",
              //       cancel :"Cancella",
              //       ok:"OK",
              //       viewCourse:"Vedi",
              //       notpublished:"Bozza",
              //       published:"Pubblicato",
              //       notfound:"Nessun risultato",
              //       NAME:"Nome corso",
              //       TARGET:"Obiettivo",
              //       DURATION_H:"Durata, ore",
              //       DURATION_L:"Durata, lezioni",
              //       PROGRAM:"Programma",
              //       THEMATICS:"Materia",
              //       YOUTUBE:"Video YouTube",
              //       NAME_LESSON:"Nome lezione",
              //       NAME_TEST:"Nome test",
              //       DESCRIPTION:"Descrizione",
              //       INTERVAL:"Intervallo di test, giorni",
              //       TODISPLAY:"Quante domande mostrare",
              //       LEVEL:"livello test",
              //       SCORE:"Numero di risposte corrette per una valutazione positiva",
              //       TIME:"Tempo test",
              //       TYPE:"Tipo test",
              //       QUEST_TEXT:"Domanda",
              //       QUEST_TYPE:"Tipo domanda",
              //       ANSW_TEXT:"Risposta",
              //       right:'corretto',
              //       import:"Importa",
              //       closed_groups:"Classi chiuse",
              //       QUEST_WEIGHT:"Peso domanda"
              //     },
              //     tr:{
              //       list_courses: "Kurslar",
              //       list_programs: "Programlar",
              //       QUEST_BLOCK_NAME:"Blok adı",
              //       QUEST_BLOCK_ID:"N blok",
              //       QUEST_BLOCK_COUNT:"Adet",
              //       QUEST_BLOCK_RAND:"Rastgele",
              //       TEST_FLAGS:"Hizmet Bayrakları",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Bloklar",
              //       QUEST_BLOCK:"Engelle",
              //       comments:"C.",
              //       direct_link:"Doğrudan bağlantı",
              //       copy:"Kopya",
              //       tags:"Etiketler",
              //       download:"İndir",
              //       edit:"Düzenlemek",
              //       refresh: "Listeyi yenile",
              //       date_pub:"Basım tarihi",
              //       status:"Durum",
              //       goback:"Geri",
              //       title:"Ders listesi",
              //       loading:"Yükleniyor",
              //       remove:"Kaldırmak",
              //       add:"Ders ekle",
              //       course:"Kurs",
              //       confirm_del:"Silmeyi onayla",
              //       cancel :"İptal",
              //       ok:"Tamam",
              //       viewCourse:"Görüş",
              //       notpublished:"Taslak",
              //       published:"Yayınlanan",
              //       notfound:"Hiçbirşey Bulunamadı",
              //       NAME:"Kurs adı",
              //       TARGET:"Hedef",
              //       DURATION_H:"Süre, saat",
              //       DURATION_L:"Süre, dersler",
              //       PROGRAM:"Programı",
              //       THEMATICS:"Ders",
              //       YOUTUBE:"Youtube videosu",
              //       NAME_LESSON:"Ders adı",
              //       NAME_TEST:"Test adı",
              //       DESCRIPTION:"Açıklama",
              //       INTERVAL:"Test aralığı, günler",
              //       TODISPLAY:"Kaç soru görüntülenecek",
              //       LEVEL:"test seviyesi",
              //       SCORE:"Olumlu değerlendirme için doğru cevap sayısı",
              //       TIME:"Test süresi",
              //       TYPE:"Test türü",
              //       QUEST_TEXT:"Soru",
              //       QUEST_TYPE:"Soru tipi",
              //       ANSW_TEXT:"Cevap",
              //       right:'doğru',
              //       import:"İçe aktarmak",
              //       closed_groups:"Kapalı sınıflar",
              //       QUEST_WEIGHT:"Soru ağırlığı"
              //     },
              //     de:{
              //       list_courses: "Kurse",
              //       list_programs: "Programme",
              //       QUEST_BLOCK_NAME:"Blockname",
              //       QUEST_BLOCK_ID:"N-Block",
              //       QUEST_BLOCK_COUNT:"Menge",
              //       QUEST_BLOCK_RAND:"Zufällig",
              //       TEST_FLAGS:"Service-Flags",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Blöcke",
              //       QUEST_BLOCK:"Blockieren",
              //       comments:"C.",
              //       direct_link:"Directe link",
              //       copy:"Kopie",
              //       tags:"Tags",
              //       download:"Herunterladen",
              //       edit:"Bearbeiten",
              //       refresh: "Aktualisieren Sie die Liste",
              //       date_pub:"Veröffentlichungsdatum",
              //       status:"Status",
              //       goback:"Zurück",
              //       title:"Kursliste",
              //       loading:"Wird geladen",
              //       remove:"Entfernen",
              //       add:"Kurs hinzufügen",
              //       course:"Kurs",
              //       confirm_del:"Löschung bestätigen",
              //       cancel :"Abbrechen",
              //       ok:"OK",
              //       viewCourse:"Sicht",
              //       notpublished:"Luftzug",
              //       published:"Veröffentlicht",
              //       notfound:"Nichts gefunden",
              //       NAME:"Kursname",
              //       TARGET:"Ziel",
              //       DURATION_H:"Dauer, Stunden",
              //       DURATION_L:"Dauer, Unterricht",
              //       PROGRAM:"Programm",
              //       THEMATICS:"Gegenstand",
              //       YOUTUBE:"YouTube video",
              //       NAME_LESSON:"Lektionsname",
              //       NAME_TEST:"Testname",
              //       DESCRIPTION:"Beschreibung",
              //       INTERVAL:"Prüfintervall, Tage",
              //       TODISPLAY:"Wie viele Fragen angezeigt werden",
              //       LEVEL:"Teststufe",
              //       SCORE:"Anzahl richtiger Antworten bei positiver Bewertung",
              //       TIME:"Testzeit",
              //       TYPE:"Testtyp",
              //       QUEST_TEXT:"Frage",
              //       QUEST_TYPE:"Fragetyp",
              //       ANSW_TEXT:"Antworten",
              //       right:'Korrekt',
              //       import:"Importieren",
              //       closed_groups:"Geschlossene Klassen",
              //       QUEST_WEIGHT:"Fragegewicht"
              //     },
              //     pl:{
              //       list_courses: "Kursy",
              //       list_programs: "Programy",
              //       QUEST_BLOCK_NAME:"Nazwa bloku",
              //       QUEST_BLOCK_ID:"Blok N",
              //       QUEST_BLOCK_COUNT:"Ilość",
              //       QUEST_BLOCK_RAND: "Losowo",
              //       TEST_FLAGS:"Flagi usług",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Bloki",
              //       QUEST_BLOCK:"Zablokuj",
              //       comments:"K.",
              //       direct_link:"Bezpośredni link",
              //       copy:"Kopia",
              //       tags:"Tagi",
              //       download:"Pobierz",
              //       edit:"Edytować",
              //       refresh: "Odśwież listę",
              //       date_pub:"Data publikacji",
              //       status:"Status",
              //       goback:"Plecy",
              //       title:"Lista kursów",
              //       loading:"Ładowanie",
              //       remove:"Usunąć",
              //       add:"Dodaj kurs",
              //       course:"Kierunek",
              //       confirm_del:"Potwierdź usunięcie",
              //       cancel :"Anulować",
              //       ok:"OK",
              //       viewCourse:"Pogląd",
              //       notpublished:"Projekt",
              //       published:"Opublikowany",
              //       notfound:"Nic nie znaleziono",
              //       NAME:"Nazwa kursu",
              //       TARGET:"Bramka",
              //       DURATION_H:"Czas trwania, godziny",
              //       DURATION_L:"Czas trwania, lekcje",
              //       PROGRAM:"Program",
              //       THEMATICS:"Podmiot",
              //       YOUTUBE:"Film na YouTube",
              //       NAME_LESSON:"Nazwa lekcji",
              //       NAME_TEST:"Nazwa testu",
              //       DESCRIPTION:"Opis",
              //       INTERVAL:"Interwał testowania, dni",
              //       TODISPLAY:"Ile pytań wyświetlić",
              //       LEVEL:"poziom testowy",
              //       SCORE:"Liczba poprawnych odpowiedzi do oceny pozytywnej",
              //       TIME:"Czas testowania",
              //       TYPE:"Rodzaj testu",
              //       QUEST_TEXT:"Pytanie",
              //       QUEST_TYPE:"Typ pytania",
              //       ANSW_TEXT:"Odpowiedź",
              //       right:'prawidłowy',
              //       import:"Import",
              //       closed_groups:"Zajęcia zamknięte",
              //       QUEST_WEIGHT:"Waga pytania"
              //     },
              //     pt:{
              //       list_courses: "Cursos",
              //       list_programs: "Programas",
              //       QUEST_BLOCK_NAME:"Nome do bloco",
              //       QUEST_BLOCK_ID:"N bloco",
              //       QUEST_BLOCK_COUNT:"Quantidade",
              //       QUEST_BLOCK_RAND:"Aleatório",
              //       TEST_FLAGS:"Sinalizadores de serviço",
              //       LOGO_PICTURE:"Logo",
              //       TEST_BLOCKS:"Blocos",
              //       QUEST_BLOCK:"Bloquear",
              //       comments:"C.",
              //       direct_link:"Link direto",
              //       copy:"cópia",
              //       tags:"Tags",
              //       download:"Baixar",
              //       edit:"Editar",
              //       refresh: "Atualize a lista",
              //       date_pub:"Data da publicação",
              //       status:"Status",
              //       goback:"Voltar",
              //       title:"Lista de cursos",
              //       loading:"Carregando",
              //       remove:"Retirar",
              //       add:"Adicionar um curso",
              //       course:"Curso",
              //       confirm_del:"Confirme a exclusão",
              //       cancel :"Cancelar",
              //       ok:"OK",
              //       viewCourse:"Visualizar",
              //       notpublished:"Projeto",
              //       published:"Publicados",
              //       notfound:"Nada encontrado",
              //       NAME:"Nome do curso",
              //       TARGET:"Meta",
              //       DURATION_H:"Duração, horas",
              //       DURATION_L:"Duração, aulas",
              //       PROGRAM:"Programa",
              //       THEMATICS:"Sujeito",
              //       YOUTUBE:"Vídeo do youtube",
              //       NAME_LESSON:"Nome da aula",
              //       NAME_TEST:"Nome de teste",
              //       DESCRIPTION:"Descrição",
              //       INTERVAL:"Intervalo de teste, dias",
              //       TODISPLAY:"Quantas perguntas mostrar",
              //       LEVEL:"nível de teste",
              //       SCORE:"Número de respostas corretas para avaliação positiva",
              //       TIME:"Tempo de teste",
              //       TYPE:"Tipo de teste",
              //       QUEST_TEXT:"Pergunta",
              //       QUEST_TYPE:"Tipo de pergunta",
              //       ANSW_TEXT:"Responder",
              //       right:'correto',
              //       import:"Importar",
              //       closed_groups:"Aulas fechadas",
              //       QUEST_WEIGHT:"Peso da pergunta"
              //     },
              //     es:{
              //       list_courses: "Cursos",
              //       list_programs: "Programas",
              //       QUEST_BLOCK_NAME:"Nombre del bloque",
              //       QUEST_BLOCK_ID:"Bloque N",
              //       QUEST_BLOCK_COUNT:"Cantidad",
              //       QUEST_BLOCK_RAND:"Aleatorio",
              //       TEST_FLAGS:"Indicadores de servicio",
              //       LOGO_PICTURE:"Logotipo",
              //       TEST_BLOCKS:"Bloques",
              //       QUEST_BLOCK:"Bloquear",
              //       comments:"C.",
              //       direct_link:"Enlace directo",
              //       copy:"Copia",
              //       tags:"Etiquetas",
              //       download:"Descargar",
              //       edit:"Editar",
              //       refresh: "Actualizar la lista",
              //       date_pub:"Fecha de publicación",
              //       status:"Estatus",
              //       goback:"Volver",
              //       title:"Lista de cursos",
              //       loading:"Carga",
              //       remove:"Quitar",
              //       add:"Añadir un curso",
              //       course:"Curso",
              //       confirm_del:"Confirmar eliminación",
              //       cancel :"Cancelar",
              //       ok:"OK",
              //       viewCourse:"Vista",
              //       notpublished:"Proyecto",
              //       published:"Publicado",
              //       notfound:"No se encontró nada",
              //       NAME:"Nombre del curso",
              //       TARGET:"Objetivo",
              //       DURATION_H:"Duración, horas",
              //       DURATION_L:"Duración, lecciones",
              //       PROGRAM:"Programa",
              //       THEMATICS:"Tema",
              //       YOUTUBE:"Video de YouTube",
              //       NAME_LESSON:"Nombre de la lección",
              //       NAME_TEST:"Nombre de la prueba",
              //       DESCRIPTION:"Descripci",
              //       INTERVAL:"Intervalo de ensayo, días",
              //       TODISPLAY:"Cuántas preguntas mostrar",
              //       LEVEL:"nivel de prueba",
              //       SCORE:"Número de respuestas correctas para la evaluación positiva",
              //       TIME:"Tiempo de prueba",
              //       TYPE:"Tipo de ensayo",
              //       QUEST_TEXT:"Pregunta",
              //       QUEST_TYPE:"Tipo de pregunta",
              //       ANSW_TEXT:"Respuesta",
              //       right:'correcto',
              //       import:"Importar",
              //       closed_groups:"Clases cerradas",
              //       QUEST_WEIGHT:"Peso de la pregunta"
              //     },
              //     vn:{
              //       list_courses: "Khóa học",
              //       list_programs: "Chương trình",
              //       QUEST_BLOCK_NAME:"Tên khối",
              //       QUEST_BLOCK_ID:"Khối N",
              //       QUEST_BLOCK_COUNT:"Số lượng",
              //       QUEST_BLOCK_RAND:"Ngẫu nhiên",
              //       TEST_FLAGS:"Cờ dịch vụ",
              //       LOGO_PICTURE:"Biểu trưng",
              //       TEST_BLOCKS:"Khối",
              //       QUEST_BLOCK:"Chặn",
              //       comments:"C.",
              //       direct_link:"Liên kết trực tiếp",
              //       copy: "Sao chép",
              //       tags:"Loại",
              //       download:"Tải",
              //       edit:"Chỉnh sửa",
              //       refresh: "Làm mới danh sách",
              //       date_pub:"Ngày công bố",
              //       status:"Trạng thái",
              //       goback:"Mặt sau",
              //       title:"Danh sách khóa học",
              //       loading:"Đang tải",
              //       remove:"Di dời",
              //       add:"Thêm một khóa học",
              //       course:"Món ăn",
              //       confirm_del:"Xác nhận xóa",
              //       cancel :"Hủy bỏ",
              //       ok:"OK",
              //       viewCourse:"Quan điểm",
              //       notpublished:"Bản nháp",
              //       published:"Được phát hành",
              //       notfound:"Không kết quả",
              //       NAME:"Tên khóa học",
              //       TARGET:"Ghi bàn",
              //       DURATION_H:"Thời lượng, giờ",
              //       DURATION_L:"Thời lượng, bài học",
              //       PROGRAM:"Chương trình",
              //       THEMATICS:"Chủ thể",
              //       YOUTUBE:"Video YouTube",
              //       NAME_LESSON:"Tên bài học",
              //       NAME_TEST:"Tên bài kiểm tra",
              //       DESCRIPTION:"Sự miêu tả",
              //       INTERVAL:"Khoảng thời gian thử nghiệm, ngày",
              //       TODISPLAY:"Có bao nhiêu câu hỏi để hiển thị",
              //       LEVEL:"mức độ kiểm tra",
              //       SCORE:"Số câu trả lời đúng cho đánh giá tích cực",
              //       TIME:"Thời gian kiểm tra",
              //       TYPE:"Loại thử nghiệm",
              //       QUEST_TEXT:"Câu hỏi",
              //       QUEST_TYPE:"Loại Câu Hỏi",
              //       ANSW_TEXT:"Bài giải",
              //       right:'chính xác',
              //       import:"Nhập khẩu",
              //       closed_groups:"Các lớp học đã đóng cửa",
              //       QUEST_WEIGHT:"Trọng lượng câu hỏi"
              //     },

              // },
              messageObj:this.parent.dictionary,
              message:this.parent.dictionary
            }
        },
        mounted: function(){
          console.log(':listOfCourses: mounted isModer=',this.parent.isModer);
          this.goback = this.back ? this.back : 'studentProfile';
          this.parent.userOption('get','isListViewCourse').then(res=>{
            this.isListViewCourse =  res === "false" ? false : Boolean(res);;
          });
          this.parent.checkPulse('courses list').then(res=>{
            if(!this.parent.listCourses || !this.parent.justSearched && Object.keys(this.parent.listCourses).length === 0 ){
              this.maxCount=this.parent.getMaxCountByTarif('courses');
              this.loadCourses();
            }else{
              this.checkComments().then(res=>{
                this.courseComments = res;
              })
            }

            this.parent.appOptionGet('test_tags').then((data)=>{
              // console.log('points=',data);
              this.test_tags_opt = JSON.parse(data.DETAIL_TEXT);
              this.test_tags_opt=this.test_tags_opt.sort(function compare(a, b) {
                if (a.toLowerCase() > b.toLowerCase()) { return 1; }
                if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                return 0;
              });
              this.flt_test_tags_opt = this.test_tags_opt;
              //
            },err=>{
              console.log(err);
            });

            this.parent.userOption('get','expformat').then(expFormat_selected=>{
              this.expFormat_selected = expFormat_selected ? expFormat_selected : "xlsx";  
            });
            
          }, err=>{
            this.$emit('goback', 'studentProfile');
          });

        },
        watch:{
          isListViewCourse:function(arg){
            this.parent.userOption('set','isListViewCourse', arg);
          },
          aRating:function(){
            console.log('::aRating= ', this.aRating);
          },
            categoria:function(){
                // console.log('::categoria ', this.categoria, this.lastCategoria);
                // if(this.lastCategoria !== -1 || !this.parent.listCourses || Object.keys(this.parent.listCourses).length === 0) {
              // if(this.lastCategoria !== -1 && this.lastCategoria != this.categoria) {
                this.getCoursesVsClosed();
                // this.parent.getIdsTestCourseInGroup('course').then(list=>{
                //           // console.log('::getIdsTestCourseInGroup = ',list)
                //           this.getCourses(list);
                // });
              // this.lastCategoria = this.categoria;
            },
          test_tags_find:function (arg){
            let $this=this;
            console.log(':watch:test_tags_find arg= ',arg);
            this.getCourses();
          },
        },
        computed:{
          razdel(){
            return parseInt(this.parent.categoria) ? this.parent.categoria : ''
          },
          isMore(){
            return this.parent.listCourses && this.total && !this.isLoading && Object.keys(this.parent.listCourses).length >= this.count;
          },
          fltListCourses(){
            let ret=false;
            if(!this.parent.listCourses){
                ret=false;
            }else{
                ret=this.parent.listCourses.filter((course, index) => {
                    // console.log(course);
                    return course && (course.ACTIVE === 'Y' && !course.CLOSED && !course.HIDE || this.parent.isModer);
                });
            }
            return ret;
          }
        },
        methods: {
          // copy(id) {
          //   this.$refs['clone'+id].focus();
          //   document.execCommand('copy');
          // },
          // getDirectLink(id){
          //     const link = `${this.parent.appURL}?course=${id}`;
          //     try {
          //       navigator.clipboard.writeText(link);
          //       console.log('::getDirectLink ',link);
          //     }catch (e){
          //       console.log('::e=',e);
          //     }
          // },
          availPeriods(item){
            // console.log('::availPeriods ',item);
            let ret = true;
            if(this.parent.isJSON(item.PROPERTY_VALUES.flags)){
              item.PROPERTY_VALUES.flags = JSON.parse(item.PROPERTY_VALUES.flags);
            }
            if(item.PROPERTY_VALUES.flags && item.PROPERTY_VALUES.flags.avail_period && item.PROPERTY_VALUES.flags.avail_days && item.PROPERTY_VALUES.flags.avail_days.length>0){
              let today=dayjs().format('YYYY-MM-DD');
              ret = false;
              for(let test of item.PROPERTY_VALUES.flags.avail_days){
                if(today >= test.from && today <= test.to){
                  ret = true;
                  break;
                }
              }
            }
            return ret;
          },
          filterTags (val) {
            // console.log('::filterTags val=',val,this.test_tags_opt)
            const needle = val?val.toLowerCase():'';
            this.flt_test_tags_opt = this.test_tags_opt.filter(v => {return !val || v.toLowerCase().indexOf(needle) > -1})
            // console.log('::filterTags flt_test_tags_opt=',this.flt_test_tags_opt)
            this.$refs.TagsSelect.refresh();
            this.$refs.TagsSelect.showPopup();
          },
          getCoursesVsClosed(more){
            this.parent.getIdsTestCourseInGroup('course').then(list=>{
              this.getCourses(list, more);
            },err=>console.error(err));
          },
            refreshList(){
                this.maxCount=this.parent.getMaxCountByTarif('courses');
                this.loadCourses();
            },
            loadCourses(){
              this.getCoursesVsClosed();
            },
            importCourses(arg){
                // console.log('::importCourses ',arg);
                window.localStorage.setItem('lessons', '');
                window.localStorage.setItem('listLessons', '');
                window.localStorage.setItem('courses', '');
                window.localStorage.setItem('grp_courses', '');
                window.localStorage.setItem('listCourses', '');
                window.localStorage.setItem('tests', '');
                window.localStorage.setItem('grp_tests', '');
                window.localStorage.setItem('listTests', '');
                this.parent.clearCountCache('courses');
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.listLessons = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.listTests = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.listCourses = null;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.isLoading=false;
              // eslint-disable-next-line vue/no-mutating-props
              this.isImportLoading=false;
              window.pics_lessons='';
              this.getCoursesVsClosed();
              this.isShowImport=false;
            },
            saveExpCourse(course, back, list){
                console.log('::saveExpCourse ',course);

                if(this.expFormat_selected === 'udata') {
                  let fname = 'course_' + course.id + '.udata';

                    // console.log('::saveExpCourse b64Data=', b64Data);
                  // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
                  //   let b64Data = btoa(unescape(encodeURIComponent(JSON.stringify(course.data))));
                  //   this.file_download_loading=true;
                  //   this.parent.getFileLinkPureB64(fname, btoa(b64Data)).then(link=>{
                  //     console.log(':1: file_download_link=', this.file_download_link);
                  //     this.file_download_link = link;
                  //     this.file_download_loading=false;
                  //   });
                  // }else {
                    let b64Data = btoa(unescape(encodeURIComponent(JSON.stringify(course.data))));
                    saveAs(new Blob([this.parent.s2ab(b64Data)],
                      {type: "application/octet-stream"}), fname);
                  // }
                    if(back){
                        back(list);
                    }
                }else{
                    this.saveCourseAsXlsx(course, back, list);
                }
            },
          cloneExpList(list) {
            console.log(':0:cloneExpList list=',list);
            let course=list&&list.length>0?list[0]:{};
            console.log(':1:cloneExpList course=',course);
            let $this=this;
              let item = course.data;
              console.log(':2:cloneExpList item=',item);
              let method = 'entityItemAdd';
              let params = {
                'ENTITY': 'uni_courses',
                'PREVIEW_TEXT': item.PREVIEW_TEXT,
                'NAME': item.NAME+'_'+this.message[this.lang].copy,
                // 'DETAIL_TEXT': item.DETAIL_TEXT,
                'DATE_CREATE': dayjs().format('DD.MM.YYYY'),
                'ACTIVE':'N',
                'DATE_ACTIVE_FROM':'',
                'PROPERTY_VALUES': item.PROPERTY_VALUES
              };
              // BX24.callMethod(method, params, function (res) {
            var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });//.then(params=> {
            $this.parent.bx24params.DETAIL_TEXT = item.DETAIL_TEXT;
            $this.parent.bx24params.DETAIL_PICTURE = item.DETAIL_PICTURE;
            $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
              console.log(':3:cloneExpList res=',res);
              $this.getCourses();
            }, "json");

          },
            saveExpList(list){
                // console.log('::saveExpList list=',list);
                if(list && Object.keys(list).length > 0){
                    let course = list.pop();
                    this.saveExpCourse(course, this.saveExpList, list);
                }
            },
            saveCourseAsXlsx(course, back, list){
                // console.log(':: savecourseAsXlsx course=', course);
                let forXlsx=[];
                let course_id = parseInt(course.id);
                let data = course.data;
                // console.log(':: savecourseAsXlsx course=', data);
                forXlsx.push({A:'#'}); //--------
                forXlsx.push({
                    A:'COURSE',
                    B:this.message[this.lang].NAME,
                    C:this.message[this.lang].TARGET,
                    D:this.message[this.lang].DURATION_H,
                    E:this.message[this.lang].DURATION_L,
                    F:this.message[this.lang].PROGRAM,
                    G:this.message[this.lang].THEMATICS,
                });
                let duration = data.PROPERTY_VALUES.duration ? JSON.parse(data.PROPERTY_VALUES.duration):{hours:0, lessons:0};
                forXlsx.push({
                    A:'',
                    B:data.NAME,
                    C:data.PROPERTY_VALUES.target,
                    D:duration.hours,
                    E:duration.lessons,
                    F:data.PROPERTY_VALUES.program,
                    G:data.PROPERTY_VALUES.thematics,
                    H:data.DETAIL_PICTURE
                });

                let lessons_tests=JSON.parse(data.PROPERTY_VALUES.course);
                // console.log(':: lessons_tests=', lessons_tests);

                for(let i in lessons_tests){
                    let item = lessons_tests[i];
                    if(item.type==='lesson'){
                        let lesson = data.lessons_exp.find((element, index, array)=>{
                            if(parseInt(element.id) === parseInt(item.id)) return true; else return false }, this);
                        if(lesson){
                            let lesson_data = JSON.parse(lesson.data);
                            // console.log(':: lesson_data=', lesson_data);
                            let youtube = lesson_data?.PROPERTY_VALUES?.youtube ? 'https://youtu.be/'+lesson_data.PROPERTY_VALUES.youtube : '';
                            forXlsx.push({A:'#'}); //--------
                            forXlsx.push({
                                A:'LESSON',
                                B:this.message[this.lang].NAME_LESSON,
                                C:this.message[this.lang].YOUTUBE,
                            });
                            forXlsx.push({
                                A:'',
                                B:lesson_data.NAME,
                                C:youtube,
                            });
                        }
                    }else if(item.type==='test'){
                        let test = data.tests_exp.find((element, index, array)=>{
                            if(parseInt(element.id) === parseInt(item.id)) return true; else return false }, this);
                        if(test?.data && this.parent.isJSON(test.data) && JSON.parse(test.data)) {
                            let test_data = JSON.parse(test.data);
                            console.log(':: test_data=', test_data);
                            let level_value = test_data.PROPERTY_VALUES.level;
                            let level=this.message[this.lang].level_options.find((element, index, array)=>
                            { if(element.value == level_value) return true; else return false }, this);
                            // console.log('::saveTestAsXlsx level_value=',level_value, level);
                            level = level ? level.text : level_value;

                            let type_value = test_data.PROPERTY_VALUES.type;
                            let type=this.message[this.lang].typeTest_options.find((element, index, array)=>
                            { if(element.value == type_value) return true; else return false }, this);
                            // console.log('::saveTestAsXlsx type_value=',type_value, type);
                            type = type? type.text : type_value;

                          let show_results_value = test_data.PROPERTY_VALUES.show_results?test_data.PROPERTY_VALUES.show_results:'';
                          let show_results_text = this.message[this.lang].show_results_opt.find((element, index, array) => {
                            if (element.value == show_results_value) { return true; } else { return false; }
                          }, this);
                          show_results_text = show_results_text ? show_results_text.text : this.message[this.lang].show_results_opt[0].text;


                          let no_prev_value = test_data.PROPERTY_VALUES.no_prev?test_data.PROPERTY_VALUES.no_prev:'N';
                          let no_prev_text = this.message[this.lang].YesNo.find((element, index, array) => {
                            if (element.value == no_prev_value) { return true; } else { return false; }
                          }, this);
                          no_prev_text = no_prev_text ? no_prev_text.text : this.message[this.lang].YesNo[0].text;

                          let flags = test_data.PROPERTY_VALUES.flags ? test_data.PROPERTY_VALUES.flags : '';
                          let blocks = test_data.PROPERTY_VALUES.blocks ? test_data.PROPERTY_VALUES.blocks : '';


                          forXlsx.push({A:'#'}); //--------
                            forXlsx.push({
                                A:'TEST',
                                B:this.message[this.lang].test_name,
                                C:this.message[this.lang].thematics,
                                D:this.message[this.lang].description,
                                E:this.message[this.lang].interval,
                                F:this.message[this.lang].todisplay,
                                G:this.message[this.lang].level,
                                H:this.message[this.lang].score,
                                I:this.message[this.lang].time,
                                J:this.message[this.lang].typeTest,
                              K:this.message[this.lang].show_results,
                              L:this.message[this.lang].no_prev,
                              M:this.message[this.lang].prog_logo,
                              N:this.message[this.lang].flags,
                            });
                            forXlsx.push({
                                A:'',
                                B:test_data.NAME,
                                C:test_data.PROPERTY_VALUES.thematics,
                                D:test_data.PROPERTY_VALUES.description,
                                E:test_data.PROPERTY_VALUES.interval,
                                F:test_data.PROPERTY_VALUES.todisplay,
                                G:level,
                                H:test_data.PROPERTY_VALUES.score,
                                I:test_data.PROPERTY_VALUES.time,
                                J:type,
                              K:show_results_text,
                              L:no_prev_text,
                              M:test_data.DETAIL_PICTURE,
                              N: flags,
                            });

                          let answers='';
                          let questions='';

                          let detail_text = this.parent.showBase64Text(test_data.DETAIL_TEXT);
                          if(this.parent.isJSON(detail_text)){
                            const tmp=JSON.parse(detail_text);
                            questions = tmp.questions;
                            answers = tmp.answers;
                          }else if (this.parent.isJSON(test_data.PROPERTY_VALUES.answers)){
                            answers=JSON.parse(test_data.PROPERTY_VALUES.answers);
                            if(this.parent.isJSON(test_data.PROPERTY_VALUES.questions)) {
                              questions = JSON.parse(test_data.PROPERTY_VALUES.questions);
                            }
                          }

                          if (this.parent.isJSON(blocks)) {
                              blocks = JSON.parse(blocks);
                              console.log(':***: blocks=', blocks)

                              forXlsx.push({
                                A: 'BLOCKS',
                                B: this.message[this.lang].QUEST_BLOCK_NAME,
                                C: this.message[this.lang].QUEST_BLOCK_ID,
                                D: this.message[this.lang].QUEST_BLOCK_COUNT,
                                E: this.message[this.lang].QUEST_BLOCK_RAND
                              });
                              for (let item of blocks) {
                                forXlsx.push({
                                  A: '',
                                  B: item.name,
                                  C: item.id,
                                  D: item.count,
                                  E: item.rand
                                });
                              }
                              forXlsx.push({});
                            }

                            if (questions) {
                              for (let i in questions) {
                                let quest = questions[i];
                                forXlsx.push({A: '#'}); //--------
                                forXlsx.push({
                                  A: 'QUEST',
                                  B: this.message[this.lang].QUEST_TEXT,
                                  C: this.message[this.lang].QUEST_TYPE,
                                  D: this.message[this.lang].QUEST_WEIGHT,
                                  E: this.message[this.lang].QUEST_BLOCK
                                });
                                let type_quest = this.message[this.lang].typeQuest_options.find((element, index, array) => {
                                  if (element.value == quest.type) return true; else return false
                                }, this);
                                type_quest = type_quest ? type_quest.text : 'qa';

                                forXlsx.push({
                                  A: '',
                                  B: quest.text,
                                  C: type_quest,
                                  D: quest.weight,
                                  E: quest.block

                                });

                                forXlsx.push({A: '#'}); //--------
                                forXlsx.push({
                                  A: 'ANSW',
                                  B: this.message[this.lang].ANSW_TEXT,
                                });

                                if (answers && answers[quest.value]) {
                                  for (let j in answers[quest.value]) {
                                    let answ = answers[quest.value][j];
                                    let item = {
                                      A: '',
                                      B: answ.text,
                                    };
                                    if (quest.type === 'qa') {
                                      item.C = answ.right ? this.message[this.lang].right : '';
                                    } else if (quest.type === 'eqw') {
                                      item.C = answ.text2;
                                    }
                                    forXlsx.push(item);
                                  }
                                }

                              }
                            }
                          }
                    }
                }

                let Head =[];
                Head.push([[]]);

                let ws = XLSX.utils.aoa_to_sheet(Head);
                let wscols = [
                    {wch:10},
                    {wch:40},
                    {wch:20},
                    {wch:5},
                    {wch:5},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                    {wch:30},
                ];
                ws['!cols'] = wscols;

                XLSX.utils.sheet_add_json(ws, forXlsx, {
                    skipHeader:true,
                    origin:0
                });

                let fname = 'course_'+course_id+'.xlsx';
                let wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'course');
              // if(navigator.userAgent.search(/BitrixDesktop/g)>=0){
              //   let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'base64'});
              //   this.file_download_loading=true;
              //   this.parent.getFileLinkPureB64(fname, wbout).then(link=>{
              //     console.log(':2: file_download_link=', this.file_download_link);
              //     this.file_download_link = link;
              //     this.file_download_loading=false;
              //   });
              // }else {
                let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
                saveAs(new Blob([this.parent.s2ab(wbout)],
                  {type: "application/octet-stream"}), fname);
              // }
                if(back){
                    back(list);
                }
            },
            importCourseTogle(){
                // this.isShowImport=!this.isShowImport;
                this.isImportLoading = true;
                this.parent.tarifAlert('courses').then(isOk=>{
                    this.isImportLoading = false;
                    if(!isOk) {
                        console.error(':: Courses Exceeded the tariff limit');
                    }else{
                        this.isShowImport=true;//!this.isShowImport;
                    }
                });
            },
            showRating(rating){
                let avg = 0;
                if(rating){
                    let prop = JSON.parse(rating);
                    avg = prop.average;
                }
                return avg;
            },
            // loadPics(isClearCache){
            //     let $this = this;
            //     if(!isClearCache && window.pics_courses){
            //          $this.pics_courses = window.pics_courses;
            //     }else {
            //
            //         let arBatch = {};
            //         for (let i in this.parent.listCourses) {
            //             let id = this.parent.listCourses[i].ID;
            //             arBatch['get_' + id] = ['entity.item.get',
            //                 {
            //                     'ENTITY': 'uni_courses',
            //                     'SORT': {},
            //                     'FILTER': {'ID': parseInt(id)}
            //                 }
            //             ]
            //         }
            //         $this.pics_courses = [];
            //         $this.callBatch(arBatch);
            //         // //console.log(':: loadPics arBatch=',arBatch);
            //
            //     }
            //
            // },
            // callBatch(arBatch){
            //     let $this = this;
            //     // console.log('::callBatch arBatch.len=',Object.keys(arBatch).length);
            //     if (arBatch && Object.keys(arBatch).length > 0) {
            //         let arBatchChine = {};//arBatch.splice(0,46);
            //         let arBatch1={};
            //         let n=0;
            //         for(let i in arBatch){
            //             if(n<45){
            //                 arBatchChine[i]=arBatch[i];
            //             }else{
            //                 arBatch1[i]=arBatch[i];
            //             }
            //             n++;
            //         }
            //         BX24.callBatch(arBatchChine, function (result) {
            //             // //console.log('::callBatch data=', result);
            //
            //             for (let key in result) {
            //                 // //console.log(key, item);
            //                 if(result[key].answer.result[0]) {
            //                     let item = result[key].answer.result[0];
            //                     if(item.DETAIL_TEXT) {
            //                         $this.pics_courses[item.ID] = item.DETAIL_TEXT;
            //                     }
            //                 }
            //             }
            //             $this.callBatch(arBatch1);
            //
            //         });
            //     } else {
            //         // console.log('::callBatch finish');
            //         if($this.pics_courses && Object.keys($this.pics_courses).length > 0 ) {
            //             window.pics_courses = $this.pics_courses;
            //             // console.log('::callBatch pics_courses=', $this.pics_courses);
            //             $this.isLoading = true;
            //             $this.$nextTick(() => {
            //                 $this.isLoading = false;
            //             });
            //         }
            //     }
            // },
            viewCourse(course, index){
              // eslint-disable-next-line vue/no-mutating-props
                this.parent.backScreen=this.parent.currentScreen;
                let arg={course:course, back:'coursesList', index:index}
                this.$emit('courseView', arg);
            },
            editCourse(course){
                this.$emit('courseEdit', course);
            },
            showModalDel(course, index) {
                this.course_id = parseInt(course.ID);
                this.course_idx = index;
                // this.$refs['del-modal'].show();
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.do_confirm = this.delCourse;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.title_confirm = this.message[this.lang].remove+' '+this.message[this.lang].course;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.message_confirm = this.message[this.lang].confirm_del + ' ' + this.message[this.lang].course +'№'+ this.course_id;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.confirm=true;
            },
            delCourse(){
              window.localStorage.setItem('courses', '');
              window.localStorage.setItem('grp_courses', '');
              this.parent.clearCountCache('courses');
              this.hideModalDel();
              let $this = this;
              let params = {
                ENTITY: 'uni_courses',
                ID: this.course_id
              };
              this.isLoading=true;
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemDel', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                $this.isLoading=false;
                    //console.log(':: -- delCourse resp', resp);
                    $this.parent.listCourses.splice($this.course_idx,1);
                    window.localStorage.setItem('courses', '');
                    window.localStorage.setItem('grp_courses', '');
                }, "json");
                // this.$emit('courseEdit', course);
            },
            hideModalDel(){
                // this.$refs['del-modal'].hide();
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.confirm=false;
            },
            formatDate(item){
                let d = item.DATE_ACTIVE_FROM ? item.DATE_ACTIVE_FROM : item.DATE_CREATE;
                let fd = dayjs(d).format('DD.MM.YYYY');
                // //console.log('::formatDate ', d, fd);
                return fd;
            },
            addNewCourse(){
                this.$emit('coursesList', 'add');
            },
            getCourses(closedGroups, more){
              let cat = parseInt(this.parent.categoria) ? this.parent.categoria : '';
              this.maxCount=this.parent.getMaxCountByTarif('courses');
              const $this = this;
              let filter = {};
              if (cat) {
                filter = {
                  '1': {
                    'LOGIC': "AND",
                    'PREVIEW_TEXT': cat
                  }
                };
              }else{
                filter = {
                  '1':{}
                }
              }
              if(this.test_tags_find){
                filter={'1':{
                    LOGIC: 'AND',
                    '%PROPERTY_thematics':`%${this.test_tags_find}%`
                  }
                };
              }

              if(more===true){
                let last_id = 9999999;
                if($this.parent.listCourses && Object.keys($this.parent.listCourses).length > 0){
                  last_id = $this.parent.listCourses[Object.keys($this.parent.listCourses).length-1].ID;
                }
                if(!filter['1']){
                  filter['1']={};
                }
                filter['1']['<ID'] = last_id;
              }else{
                // eslint-disable-next-line vue/no-mutating-props
                $this.parent.listCourses=[];
              }
              if(!this.parent.isModer && !this.parent.isAdmin){
                filter['1'].ACTIVE = 'Y';
              }
              // console.log('::getCourses more,filter=',more, filter);
              let params={
                  ENTITY: 'uni_courses',
                  SORT: {ID: 'DESC'}, //{PREVIEW_TEXT:'ASC', ID: 'DESC'},
                  FILTER: filter
              };
                console.log(':2:getCourses params=', params);

              this.isLoading=true;
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (result) {
                console.log(':2:getCourses result=', result);
                $this.isLoading=false;
                let arResult = result.result;
                let isQuota = false;
                $this.total = false;
                  if (arResult) {
                    let closed = false;
                    for (let i in arResult) {
                      let course = arResult[i];
                      $this.total = true;
                      isQuota = $this.maxCount > Object.keys($this.parent.listCourses).length;
                      if (isQuota && parseInt(i)<$this.count) {
                          //для курсов, которые назначены в группах добавим эти группы к курсу для показа в карточке
                          if (closedGroups) {
                              closed = [];
                              for (let j in closedGroups) {
                                  if (closedGroups[j].id == course.ID) {
                                      closed.push(closedGroups[j]);
                                  }
                              }
                              if (Object.keys(closed).length == 0) {
                                  closed = false;
                              }
                          }
                        // console.log(':3:course= ',course);

                        let flags = $this.parent.isJSON(course.PROPERTY_VALUES.flags)?JSON.parse(course.PROPERTY_VALUES.flags):course.PROPERTY_VALUES.flags;
                        let hide_extra = flags.hide_extra && ($this.parent.user_current.USER_TYPE === 'extranet' || $this.parent.isModer);
                        let hide_dep = flags.hide_dep && ($this.parent.arraysHaveNoCommonElements($this.parent.user_current.UF_DEPARTMENT, flags.hidden_deps) || $this.parent.isModer);

                        // eslint-disable-next-line vue/no-mutating-props
                        $this.parent.listCourses.push({
                              ID: course.ID,
                              PREVIEW_TEXT: course.PREVIEW_TEXT,
                              NAME: course.NAME,
                              ACTIVE: course.ACTIVE,
                              PROPERTY_VALUES: course.PROPERTY_VALUES,
                              DETAIL_PICTURE: course.DETAIL_PICTURE,
                              DATE_ACTIVE_FROM: course.DATE_ACTIVE_FROM,
                              DATE_CREATE: course.DATE_CREATE,
                              CLOSED: closed,
                              HIDE: flags.hide_cat || hide_extra || hide_dep,
                              rating: $this.showRating(course.PROPERTY_VALUES.rating)
                          });
                      } else {
                          break;
                      }
                    }
                    $this.checkComments().then(res=>{
                      $this.courseComments=res;
                    });

                    // console.log(':4:listCourses= ',$this.parent.listCourses);

                    
                  }
              // }
              });
            },
          checkComments(){
            let $this = this;
            let list={};
            let nn=0;
            let params = {
              ENTITY: 'uni_comments',
              SORT: {ID: 'DESC'},
              FILTER: {'NAME': `%course_%`},
              LIMIT: 1000
            }
            return new Promise(resolve=> {
              let nn=0;
              // BX24.callMethod('entity.item.get', {
              //   ENTITY: 'uni_comments',
              //   SORT: {ID: 'DESC'},
              //   FILTER: {'NAME': `%course_%`},
              // }, function(result){
              //   if(result.error()){
              //     console.log(':: checkComments ',result.error())
              //     resolve(list);
              //   }else{
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (result) {
                  let data = result.result;
                  for(let item of data) {
                    list[item.NAME] = list[item.NAME] ? list[item.NAME] + 1 : 1;
                  }
                  // if(result.more() && nn++ < 200) {
                  //   result.next();
                  // }
                  // else{
                    // console.log('::getComments list=', list)
                    resolve(list);
                  // }
                // }
              });
            });
          },


        },
        components: {
          courseExport,
          courseImport,
          listPrograms,
          
        }
    }
</script>

<style scoped>


</style>

