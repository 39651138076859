<template>
  <div>
    <div v-if="mode_view">
      <q-btn 
        flat 
        round 
        color="positive" 
        icon="visibility"
        @click="viewElement"/>

    </div>
    <div v-else>
      <!-- Загрузка SCORM -->
      <div class="col-8 text-left">
        <div class="q-pa-sm h5 text-positive">
          <q-icon name="info" />{{ message[lang].SCORMhelp }}
        </div>
        <streaming-file
          v-if="entityId && !scormLink"
          @streamingSuccess="handleScormSuccess"
          @streamingFailed="handleScormFailed"
          :lang="lang"
          :parent="parent"
          :accept="'.zip'"
          :url="`sendSCORMfile?id=${entityId}`"
          :dense="true"
          :unelevated="true"
        />
        <div v-if="scormAlert" class="text-warning q-my-sm">{{ scormError }}</div>
      </div>

      <!-- Предпросмотр SCORM -->
      <div v-if="scormLink" class="row q-mt-md justify-center">
        <iframe :src="scormLink" width="200" frameborder="0"></iframe>
      </div>

      <!-- Удаление SCORM -->
      <div class="q-mt-md" v-if="scormLink">
        <q-btn
          flat
          rounded
          no-caps
          icon="clear"
          color="positive"
          :label="message[lang].remove + ' SCORM'"
          @click="handleScormRemove"
          :loading="removeLoading"
        />
      </div>
    </div>
  </div>

  
  <q-dialog v-model="dialogOpen" maximized>
  <q-card class="full-height column no-wrap q-pa-none">
    <!-- Заголовок (фиксированный) -->
    <q-bar class="bg-primary text-negative">
      <div class="text-h6">SCORM</div>
      <q-space />
      <q-btn round dense unelevated color="secondary" icon="done" @click="handleDone">
        <q-tooltip>{{ message[lang].item_checked }}</q-tooltip>
      </q-btn>
      <!-- Новая кнопка для открытия в отдельном окне -->
      <q-btn 
        round 
        dense 
        flat 
        icon="open_in_new" 
        @click="openScormInNewWindow"
        class="q-mr-sm"
      >
        <q-tooltip>{{ message[lang].open_new_tab }}</q-tooltip>
      </q-btn>
      <q-btn round dense flat icon="close" @click="handleDone">
        <q-tooltip>{{ message[lang].close }}</q-tooltip>
      </q-btn>
    </q-bar>

    <!-- Основной контент (скроллируемая область) -->
    <q-scroll-area class="col"> <!-- Весь контент внутри скроллится -->
      <div class="column full-height" style="min-height: 100%;">
        <!-- IFrame (растягивается на доступную высоту) -->
        <iframe
          :src="scormLink"
          class="full-width"
          frameborder="0"
          allowfullscreen
          :style="selfItem.allowComments?'height: calc(100vh - 300px); min-height: 400px;':'height: calc(100vh); min-height: 400px;'"
          ref="scormIframe"
        />

        <!-- Блок комментариев (остаётся внизу) -->
        <div 
          v-if="selfItem.allowComments" 
          class="bg-dark q-mt-md"
          style="height: auto; min-height: 200px;"
        >
          <Comments 
                v-bind:lang="lang" 
                v-bind:entity-id="selfItem.id" 
                v-bind:user_current="parent.user_current" 
                v-bind:parent="parent" 
                v-bind:mode="'list'" 
                v-bind:heght="900" />
        </div>
      </div>
    </q-scroll-area>
  </q-card>
</q-dialog>

</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue'
import { useQuasar } from 'quasar'
import streamingFile from '/src/streamingFile';
import Comments from 'src/Comments.vue';

const $q = useQuasar()

const props = defineProps({
  selfItem: Object,
  element:{
    type: Object
  },
  entityId: {
    type: String,
    default: ''
  },
  mode_view: Boolean,
  lang: {
    type: String,
    required: true
  },
  parent: {
    type: Object,
    required: true
  },
  message: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['scorm-loaded', 'scorm-removed', 'error', 'item-checked'])

const scormAlert = ref(false)
const scormError = ref('')
const removeLoading = ref(false)
const scormLink = ref(props?.element?.href)
const scormVersion = ref(props?.element?.version)
const saveSCORMitem = ref({})
let dialogOpen = ref(false)

const viewElement = () => {
  dialogOpen.value = true;
}

const alertSCORM = (message) => {
  scormAlert.value = true
  scormError.value = message
  emit('error', message)
}

//props.message[props.lang].block_popup
const openScormInNewWindow = () => {
  // Закрываем диалог
  dialogOpen.value = false;
  
  // Размеры окна (80% от доступного экрана)
  const width = Math.floor(window.screen.availWidth * 0.8);
  const height = Math.floor(window.screen.availHeight * 0.8);
  
  // Позиционирование по центру
  const left = (window.screen.availWidth - width) / 2;
  const top = (window.screen.availHeight - height) / 2;
  
  const features = `
    width=${width},
    height=${height},
    left=${left},
    top=${top},
    scrollbars=yes,
    resizable=yes,
    toolbar=no,
    menubar=no,
    location=no,
    status=no
  `;
  
  const newWindow = window.open(scormLink.value, '_blank', features);
  
  if (!newWindow) {
    // Если всплывающее окно было заблокировано
    alert(props.message[props.lang].block_popup);
    dialogOpen.value = true;
  }
};

const handleScormSuccess = (response) => {
  try {
    if (response && props.parent.isJSON(response)) {
      const res = JSON.parse(response)
      if (res.error) {
        alertSCORM(res.error)
      } else {
        // console.log('Файл успешно загружен res=', res)
        scormLink.value = res?.result
        emit('scorm-loaded', {href:res?.result, version:res.version[0]})
      }
    } else {
      alertSCORM(props.message[props.lang].scorm_error)
    }
  } catch (e) {
    alertSCORM(props.message[props.lang].failed_to_load)
  }
}

const handleScormFailed = (error) => {
  alertSCORM(error.message || props.message[props.lang].scorm_error)
}

const doScormRemove = () => {
  // console.log('scormRemove ', props.entityId)
  const url = `${props.parent.app_server_url}api/removeSCORMdir?lesson_id=${props.entityId}&portal=${props.parent.portal}&access_token=${props.parent.access_token}`
  return new Promise(resolve => {
    props.parent.POST(url, {}, (res) => {
      // console.log('::scormRemove lesson_id res=', res)
      resolve(res)
    })
  })
}

const handleScormRemove = async () => {
  $q.dialog({
    title: props.message[props.lang].attention,
    message: props.message[props.lang].del_SCORM + ' ?',
    position: 'top',
    persistent: true,
    ok: {
      label: props.message[props.lang].remove,
      color: 'warning',
      rounded: true
    },
    cancel: {
      label: props.message[props.lang].prompt_cancel,
      color: 'positive',
      flat: true
    }
  }).onOk(async () => {
    try {
      removeLoading.value = true
      await doScormRemove()
      scormLink.value = ''
      emit('scorm-removed', {href:'', version:''})
      $q.notify({
        type: 'positive',
        message:props.message[props.lang].success,
      });
    } catch (error) {
      alertSCORM(error.message || props.message[props.lang].rev_error_title)
    } finally {
      removeLoading.value = false
    }
  })
}

const saveLessonToAppOptions = () => {
  // console.log('::saveLessonToAppOptions ',saveSCORMitem.value)
  let user_id = parseInt(props.parent.user_current.ID);
  return new Promise(resolve => {
    props.parent.uCoursesTestsSet('lesson', user_id, saveSCORMitem.value,  saveSCORMitem.value.id).then(res => {
      // console.log('::saveLessonToAppOptions res= ', res);
      if(!saveSCORMitem.value.id) {
        saveSCORMitem.value.id = parseInt(res.result);
      }
      resolve(res.result);
    });

  });
}

const getLessonFromAppOptions = (item_id) => {
  let user_id = parseInt(props.parent.user_current.ID);
  // console.log('::getLessonFromAppOptions ',user_id)
  return new Promise(resolve => {
    props.parent.uCoursesTestsGetActive('lesson', user_id, item_id, true).then(res => {
      // console.log('::getLessonFromAppOptions res=',res)
      resolve(res.data?res.data:{})
    }, err=>{
      // console.log('::getLessonFromAppOptions err=',err)
      resolve({})
    });
  });
}

const initSCORM = (noEvents) => {
    var settings = {
      logLevel: 0, // 0 - отключает все логи
      showLogs: false, // Дополнительное отключение
      logging: {
        enabled: false // Полное отключение логов (если API поддерживает)
      }
    };
    // # AICC
    // window.API = new AICC(settings);

    //# SCORM 1.2
    window.API = new Scorm12API(settings);

    // # SCORM 2004
    window.API_1484_11 = new Scorm2004API(settings);
    const sco_ver = scormVersion.value?scormVersion.value.indexOf('2004')!==-1?'API_1484_11':'API':'API';
    // console.log(':!!!: window_api ',window[sco_ver])

    getLessonFromAppOptions().then(data=>{

      if(data.suspend_data){
        //в suspend_data будут и правильные и неправильные ответы.
        // Значит нужно перед стартом спрашивать: начать с начала или продолжить
        $q.dialog({
          title: props.message[props.lang].scorm_title,
          message: props.message[props.lang].scorm_message,
          position: 'top',
          persistent: true,
          ok: {
            label: props.message[props.lang].scorm_do,
            color: 'warning',
            rounded: true
          },
          cancel: {
            label: props.message[props.lang].scorm_no,
            color: 'positive',
            flat: true
          }
        }).onOk(async () => {
          try {
              // console.log(':!!!:getLessonFromAppOptions ',data)
              if(data.suspend_data)
                window[sco_ver].cmi.suspend_data = atob(data.suspend_data);

              if(data.entry)
                window[sco_ver].cmi.entry = data.entry;

              if(data.location)
                window[sco_ver].cmi.location = data.location;

              doInitSCORM();
          } catch (error) {
            alertSCORM(error.message || props.message[props.lang].rev_error_title)
          } finally {
            doInitSCORM();
          }
        }).onCancel(() => {
          doInitSCORM();
        })
      }else{
        doInitSCORM();
      }
      //

    });

  }

  const doInitSCORM = () => {
    const sco_ver = scormVersion.value?scormVersion.value.indexOf('2004')!==-1?'API_1484_11':'API':'API';
    props.parent.getServerTimeStamp().then(startTime=> {
      saveSCORMitem.value.entityId = props.entityId;
      saveSCORMitem.value.startTime = startTime;

      saveLessonToAppOptions().then(res=>{
        //2004
        window[sco_ver].on("SetValue.cmi.suspend_data", function(CMIElement, value) {
          // console.log(':+++: ', window[sco_ver]);
          saveSCORMitem.value.suspend_data = btoa(value);
          saveLessonToAppOptions();
        });
        window[sco_ver].on("SetValue.cmi.core.score.raw", function(CMIElement, value) {
          // console.log(':+++: ', CMIElement, '=', value);
          saveSCORMitem.value.score = value;
          saveLessonToAppOptions();
        });
        window[sco_ver].on("SetValue.cmi.core.score.lesson_status", function(CMIElement, value) {
          // console.log(':+++: ', CMIElement, '=', value);
          saveSCORMitem.value.status = value;
          saveLessonToAppOptions();
        });
        window[sco_ver].on("SetValue.cmi.core.score.session_time", function(CMIElement, value) {
          // console.log(':+++: ', CMIElement, '=', value);
          saveSCORMitem.value.session_time = value;
          saveLessonToAppOptions();
        });
        window[sco_ver].on("Commit", function(CMIElement, value) {
          // console.log(':+++: ', CMIElement, '=', value);
          props.parent.getServerTimeStamp().then(endTime=> {
            saveSCORMitem.value.endTime = endTime;
            saveLessonToAppOptions();
          });
        });
        window[sco_ver].on("Finish", function(CMIElement, value) {
          // console.log(':+++: ', CMIElement, '=', value);
          props.parent.getServerTimeStamp().then(endTime=> {
            saveSCORMitem.value.endTime = endTime;
            saveLessonToAppOptions();
          });
        });

        // 1.2
        window[sco_ver].on("LMSSetValue.cmi.*", function(CMIElement, value) {
          // console.log(':!!!: ', CMIElement, '=', value);
          if(CMIElement=='cmi.suspend_data') {
            // console.log(':!!!: suspend_data=', CMIElement, value);
              saveSCORMitem.value.suspend_data = btoa(value);
              saveLessonToAppOptions();
          }
          if(CMIElement=='cmi.entry'){
            // console.log(':!+!+!: entry=',value);
            saveSCORMitem.value.entry = value;
            saveLessonToAppOptions();
          }
          if(CMIElement=='cmi.location'){
            // console.log(':!+!+!: location=',value);
            saveSCORMitem.value.location = value;
            saveLessonToAppOptions();
          }
          if(CMIElement=='cmi.core.score.raw'){
            //Процент верных ответов
            // console.log(':!!!: Процент верных ответов=',value);
            saveSCORMitem.value.score = value;
            saveLessonToAppOptions();
          }
          if(CMIElement=='cmi.core.lesson_status'){
            //Пройден/Не пройден
            // console.log(':!!!: Статус теста=',value);
            saveSCORMitem.value.status = value;
            saveLessonToAppOptions();
          }
          if(CMIElement=='cmi.core.session_time'){
            //Затраченное время
            // console.log(':!!!: Затраченное время=',value);
            saveSCORMitem.value.session_time = value;
            saveLessonToAppOptions();
          }


        });
        //
        window[sco_ver].on("LMSCommit", function(commitInput) {
          // console.log(":-LMSCommit-: " + commitInput);
          props.parent.getServerTimeStamp().then(endTime=> {
            saveSCORMitem.value.endTime = endTime;
            saveLessonToAppOptions();
          });
        });
        window[sco_ver].on("LMSFinish", function(finishInput) {
          // console.log(":-LMSFinish-: " + finishInput);
          props.parent.getServerTimeStamp().then(endTime=> {
            saveSCORMitem.value.endTime = endTime;
            saveLessonToAppOptions();
          });
        });

      });

    });
  }
  
  const handleDone = () => {
    emit('item-checked', props.selfItem);
    dialogOpen.value=false;
  }

onMounted(async () => {
  const data = await getLessonFromAppOptions();
  initSCORM();
  
});
  
</script>
<style scoped>
/* Дополнительные стили для полного устранения отступов */
/* .q-card {
  display: flex;
  flex-direction: column;
}
.q-card__section {
  flex-grow: 1;
  overflow: hidden;
} */
 .q-card {
  padding: 0 !important;
}
.q-card-section {
  padding: 0 !important;
}
</style>