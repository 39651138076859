/**
 * Moyskald
 */

let mskUsersState=null;
let mskDepartments=null;
export function moyskladCurrentUser($this){
    const qp = $this.getQueryParams();
    console.log('::moyskladCurrentUser qp=',qp);
    let user_current={};
    return new Promise(async resolve=>{
        if(!qp?.contextKey || !qp?.appUid  || qp.appUid !== "brusnikalms.novushcm"){
            $this.moysklad=false;
            resolve(false)
          }else{
            $this.moysklad=true;//Это похоже на moysklad
            qp.moysklad = 'Y';
            const url=`${$this.apiurl}api/moysklad-currentUser`;
            qp.app_id = 'LMS-MSK';
            $this.POST(url, qp, async function (res) {
                console.log(':1:moyskladCurrentUser res=',res);
                
                if(res){
                    if($this.isJSON(res)){
                        res = JSON.parse(res);
                    }
                    console.log(':2:moyskladCurrentUser res=',res);
                    if(!res){
                        console.error('::Не получен текущий юзер, возможно требуется переустановка приложения');
                        $this.noBX24 = true;
                        resolve({});
                    }else{
                        let group='';
                        if(res?.group?.meta?.href){
                            const uuid = res.group.meta.href.split('/').pop(); 
                            group = [$this.parent.myEx(uuid)];
                        }
                        user_current.ID = $this.parent.myEx(res.id);
                        user_current.email = res.email;
                        user_current.NAME = res.firstName || '';
                        user_current.SECOND_NAME = res.middleName || '';
                        user_current.LAST_NAME = res.lastName || '';
                        user_current.PERSONAL_PHOTO = res?.image?.tiny?.href;
                        user_current.UF_DEPARTMENT = group;
                        user_current.WORK_POSTION = res.position || '';

                        $this.member_id = res.accountId;
                        $this.portal = res.domain;
                        $this.lang = res.lang || "ru";
                        $this.isAdmin = res?.permissions?.admin?.view === 'ALL';
                        console.log(':3:moyskladCurrentUser user_current=',$this.isAdmin,user_current);
                        resolve(user_current)
                    }
                }else{
                    resolve({})
                }
            })
          }
    });
}

export function getUsersMSK($this, isActive, arDepartments, usersIds, color) {
  console.log('::getUsersMSK',isActive, arDepartments, usersIds);
  return new Promise(async (resolve, reject) => {
    if(!mskUsersState){
      mskUsersState = await getUsersMoysklad($this, isActive);
    }
    console.log('::getUsersMSK mskUsersState=',mskUsersState);

    if(usersIds?.length>0){
      let filtered = mskUsersState.filter(el=>usersIds.includes(el.id))
      resolve(filtered);
    }else{
      let filtered = mskUsersState.filter(user =>
        user.deps?user.deps.some(dep => arDepartments.includes(dep)):false
      );
      resolve(filtered);        
    }
  });
};


    

export function getUsersMoysklad($this, isActive=false, search=''){
    return new Promise((resolve) => {
      if(!mskUsersState || search){
        $this.callMethod('moysklad-users', {search:search}).then(res=>{
          console.log('::getUsersMoysklad res=',res);
          let users=[];
          if(res?.rows?.length>0){
            for(let item of res.rows){
                let group='';
                if(item?.group?.meta?.href){
                    const uuid = item.group.meta.href.split('/').pop(); 
                    group = [$this.parent.myEx(uuid)];
                }

              let user = {
                id: $this.parent.myEx(item.id),
                name: item.fullName,
                email: item?.email || '',
                deps: group,
                photo: item?.image?.tiny?.href,
                type: '',
                pos: item.position
              }
              users.push(user);

            }
          }
          if(search){
            if(users?.length > 0){
                //Объединение mskUsersState и users
                const uniqueMap = {};
                // Добавляем элементы из первого массива
                mskUsersState.forEach(item => {
                    uniqueMap[item.id] = item;
                });

                // Добавляем элементы из второго массива (дубликаты перезапишутся)
                users.forEach(item => {
                    uniqueMap[item.id] = item;
                });

                // Преобразуем объект обратно в массив
                mskUsersState = Object.values(uniqueMap);
            }
            resolve(users);
          }else{          
            mskUsersState = [...users];
            resolve(mskUsersState);
          }
        });
      }else{
        console.log('::getUsersMoysklad mskUsersState=',mskUsersState);
        resolve(mskUsersState);
      }
    });

  };

   export function extraFilterFnMSK($this, needle){
        return new Promise(resolve => {
          getUsersMoysklad($this, true, needle).then(users=>{
            let list=[];
            if(users?.length>0) {
              for (let i in users) {
                let item = users[i];
                item.value = item.id;
                item.text = item.name;
                if(item.name)
                  list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps, departments:item.departments, pos:item.pos, head:item.head, email:item.email});
              }
            }
            console.log('::extraFilterFnMSK list=',list)
            resolve(list);
          });
        });
  
      };

      export function getDepartmentsMSK($this) {
        console.log('::getDepartmentsMSK');
        return new Promise(async (resolve, reject) => {
          if(!mskDepartments?.length){
            mskDepartments = [];
            $this.callMethod('moysklad-departments', {}).then(res=>{
                console.log('::getUsersMoysklad res=',res);
                if(res?.rows?.length > 0){
                    mskDepartments = res.rows.map(item => ({
                        parent: "",
                        text: item.name,
                        uf_head: '',
                        value:  $this.parent.myEx(item.id).toString(),
                    }))
                }
                resolve(mskDepartments);

            });
          }else{
            resolve(mskDepartments);
          }
        });
      };

    //   function getDepartments(users){
    //     const departments = users.flatMap(item =>
    //       item._embedded.groups.map(group => ({
    //         parent: "",
    //         text: group.name,
    //         uf_head: '',//item.id.toString(), // Используем id родительского элемента
    //         value: group.id.toString(),
    //       }))
    //     );
        
    //     // Убираем дубликаты по value
    //     mskDepartments = Array.from(new Map(departments.map(item => [item.value, item])).values());
    //     console.log('::getDepartments ',mskDepartments);
    //   }