<template>
  <q-page class="q-pa-xs">
    <q-card-section class="row items-center q-pb-md">
      <div class="h3 text-primary">{{message[lang].gallery}}</div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
      
    <!-- Поле поиска по имени файла -->
    <q-input
      v-model="searchQuery"
      :label="message[lang].search_by_name"
      class="q-mb-md"
      clearable
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
    <!-- Загрузка файлов через drag-and-drop -->
    <div class="row q-mb-md">
      <div class="col-4">
        <q-uploader
            v-if="!loading"
            class="q-mb-md"
            :label="message[lang].upload_gallery"
            multiple
            :accept="props.accept"
            @added="handleFileAdded"
            @uploaded="handleFileUploaded"
            @failed="handleUploadFailed"
            @removed="handleFileRemoved"
            :url="`${parent.app_server_url}api/sendimage?client_key=${parent.member_id}&portal=${parent.portal}&access_token=${parent.access_token}&app_id=${parent.appID}`"
                    
        />
        </div>
        <div class="col-5 text-warning text-left q-pt-sm" >
          <div class="row items-center" v-if="logo_added">
            <q-icon name="arrow_back" color="warning"/>
            {{message[lang].logo_added}}
          </div>
        </div>
        <!-- Информация о количестве файлов и их размере -->    
        <div v-if="fileCount>0" class="col-3 q-pl-md">
            <div class="text-body1">
             {{ message[lang].files_num }} {{ fileCount }}
            </div>
            <div class="text-body1">
                {{ message[lang].total_size }} {{ totalSize }} MB
            </div>
        </div>
    </div>

    <!-- Индикатор загрузки -->
    <div v-if="loading" class="text-center">
      <q-spinner color="primary" size="3em" />
    </div>

    <!-- Галерея файлов -->
    <div v-else>
      <div class="row q-col-gutter-md">
        <div v-for="(file, index) in filteredFiles" :key="index" class="col-3">
          <q-card class="my-card  text-positive">
            <!-- Миниатюра для изображений -->
            <q-img
              v-if="isImage(file.name)"
              :src="file.href"
              :alt="file.name"
              class="cursor-pointer"
              @click="openImage(file.href)"
            />
            <!-- Иконка для других типов файлов -->
            <q-icon
              v-else
              :name="getFileIcon(file.name)"
              size="xl"
              class="q-mt-md q-mb-md"
            />
           <q-card-section class="file-name-section">
              <div class="h5 file-name">{{ file.name }}</div>
            </q-card-section>

            <!-- Кнопка "Выбрать" -->
            <q-card-actions align="around">
              <q-btn v-if="!emitAsBase64"
                color="primary"
                rounded flat
                :label="message[lang].choose"
                @click="emitFile(file)"
                :disabled="!isFileAccepted(file)"
              />
              <q-btn v-else
                color="secondary"
                rounded flat
                :label="message[lang].choose"
                @click="emitFileAsBase64(file)"
                :disabled="!isFileAccepted(file)"
              />

              <!-- Кнопка "Удалить" -->
              <q-btn
                color="positive"
                rounded flat
                icon="delete"
                @click="confirmDeleteFile(index)"
              />
            </q-card-actions>
          </q-card>
        </div>
      </div>
    </div>

    <!-- Диалог для просмотра изображений -->
    <q-dialog v-model="imageDialog">
      <q-img :src="selectedImage" :alt="selectedImage" />
    </q-dialog>
  </q-page>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useQuasar } from 'quasar';

const props = defineProps({
  lang: {
    type: String,
    default: 'ru',
  },
  parent: {
    type: Object,
    default: () => ({}),
  },
  message: {
    type: Object,
    default: () => ({}),
  },
  emitAsBase64: {
    type: Boolean,
    default: false,
  },
  accept:{
    type: String,
    default:"image/*, .svg"
  }
});

const emit = defineEmits(['file-selected', 'uploading']);

const $q = useQuasar();
const mediaFiles = ref([]);
const loading = ref(true);
const imageDialog = ref(false);
const selectedImage = ref('');
const searchQuery = ref(''); // Поисковый запрос
const logo_added = ref(false);

// Количество файлов
const fileCount = computed(() => mediaFiles.value.length);

// Общий размер файлов в мегабайтах
const totalSize = computed(() => {
  const totalBytes = mediaFiles.value.reduce((sum, file) => sum + (file.size || 0), 0);
  return (totalBytes / (1024 * 1024)).toFixed(2); // Преобразуем в MB
});

// Фильтрация файлов по поисковому запросу
const filteredFiles = computed(() => {
  if (!searchQuery.value) {
    return mediaFiles.value; // Если запрос пустой, возвращаем все файлы
  }
  const query = searchQuery.value.toLowerCase();
  return mediaFiles.value.filter((file) =>
    file.name.toLowerCase().includes(query)
  );
});

// Проверка, соответствует ли тип файла параметру accept
const isFileAccepted = (file) => {

  if (!props.accept || !file?.name) return true; // Если accept не задан, разрешаем все файлы

  const acceptedTypes = props.accept.split(',').map((type) => type.trim());
  return acceptedTypes.some((type) => {
    if (type.startsWith('.')) {
      // Проверка по расширению (например, .pdf)
      return file.name.toLowerCase().endsWith(type);
    } else if (type.endsWith('/*')) {
      // Проверка по MIME-типу (например, image/*)
      const category = type.split('/')[0];
      return file.type?.startsWith(category) || isImage(file.name);
    } else {
      // Точное совпадение MIME-типа (например, application/pdf)
      return file.type === type;
    }
  });
};

// Загрузка медиафайлов с сервера
const fetchMedia = async () => {
  try {
    const response = await fetch(`${props.parent.app_server_url}api/getmedia?member_id=${props.parent.member_id}&portal=${props.parent.portal}&access_token=${props.parent.access_token}`);
    const data = await response.json();
    mediaFiles.value = data;
    console.log('::fetchMedia mediaFiles=', mediaFiles.value)
  } catch (error) {
    $q.notify({
      type: 'negative',
      message: props.message[props.lang].failed_to_load,
    });
  } finally {
    loading.value = false;
  }
};

// Определение типа файла по расширению
const getFileType = (fileName) => {
  const extension = fileName?fileName.split('.').pop().toLowerCase():'none';
  return extension;
};

// Проверка, является ли файл изображением
const isImage = (fileName) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];
  return imageExtensions.includes(getFileType(fileName));
};

// Получение иконки для файла
const getFileIcon = (fileName) => {
  const type = getFileType(fileName);
  if (type === 'pdf') return 'picture_as_pdf';
  if (type === 'xlsx' || type === 'csv') return 'insert_drive_file';
  if (type === 'docx' || type === 'doc') return 'description';
  if (type === 'zip' || type === 'rar') return 'folder_zip';
  return 'insert_drive_file';
};

// Открытие изображения в диалоге
const openImage = (imageUrl) => {
  selectedImage.value = imageUrl;
  imageDialog.value = true;
};

// Возврат файла через emit
const emitFile = (file) => {
  console.log(':: emitFile ',file)
  emit('file-selected', { name: file.name, href: file.href });
};

// Возврат файла в формате base64
const emitFileAsBase64 = async (file) => {
  try {
    const response = await fetch(file.href);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onloadend = () => {
      emit('file-selected', { name: file.name, href: reader.result });
    };
    reader.readAsDataURL(blob);
  } catch (error) {
    $q.notify({
      type: 'negative',
      message: props.message[props.lang].failed_to_convert,
    });
  }
};
const handleFileRemoved = () => {
  console.log('::handleFileRemoved')
  logo_added.value=false;
};

// Обработка загрузки файлов через drag-and-drop
const handleFileAdded = async (files) => {
    console.log('::handleFileAdded files=',files)
    if(files?.length>0){
      for(let file of files){
        // const res = await isFileExist(file.name);
        const fileexist = mediaFiles.value.find(el=>{
          return el.name == file.name
        })
        console.log('::handleFileAdded fileexist=',fileexist);
        // if(res?.fileexist){
        if(fileexist){
          $q.notify({
            type: 'warning',
            position: 'center',
            multiLine: true,
            message:props.message[props.lang].file_exist + ': '+file.name,
          });
        }
        logo_added.value=true;
      }
    }else{
      logo_added.value=false;
    }
  $q.notify({
    type: 'positive',
    message:props.message[props.lang].success_loaded,
  });
};

// const isFileExist = (name) => {
//   let url = `${props.parent.app_server_url}api/sendmedia?client_key=${props.parent.member_id}&portal=${props.parent.portal}&access_token=${props.parent.access_token}`;
//   let params = { base64: 'xxx', name: name, verify:'Y' };
//   return new Promise((resolve, reject) => {
//     fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(params),
//     })
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then((data) => {
//         resolve(data);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//         reject(false);
//       });
//   });
// };

// Обработка успешной загрузки файлов
const handleFileUploaded = ({ files }) => {
    console.log('::handleFileUploaded files=',files)
    logo_added.value = false;
  $q.notify({
    type: 'positive',
    message:props.message[props.lang].success_loaded,
  });
  fetchMedia();

};

// Обработка ошибок загрузки файлов
const handleUploadFailed = () => {
  $q.notify({
    type: 'negative',
    message: props.message[props.lang].failed_to_load,
  });
};

// Подтверждение удаления файла
const confirmDeleteFile = (index) => {
  $q.dialog({
    title: props.message[props.lang].del_some_title,
    message: props.message[props.lang].del_program_msg+'?',
    // cancel: true,
    ok: {
          label: props.message[props.lang].yes,
          flat:true,
          rounded:true,
          color:'primary'
        },
    cancel: {
        color: 'positive',
        flat:true,
        rounded:true,
        label: props.message[props.lang].prompt_cancel
    },
    persistent: true,
  }).onOk(() => {
    deleteFile(index);
  });
};


// Удаление файла
const deleteFile = (index) => {
  let name = mediaFiles.value[index].name;
  let url = `${props.parent.app_server_url}api/clearimage?client_key=${props.parent.member_id}&portal=${props.parent.portal}&name=${name}&access_token=${props.parent.access_token}&app_id=${props.parent.appID}`;
    props.parent.POST(url, {name: name}, function (response) {
    mediaFiles.value.splice(index, 1);
    });
  $q.notify({
    type: 'positive',
    message:props.message[props.lang].file_deleted,
  });
};

onMounted(() => {
  fetchMedia();
});
</script>

<style scoped>
.q-page {
  min-height: 100%;
}
.my-card {
  max-width: 300px;
  margin: auto;
  height: 100%;
}

.file-name-section {
  max-height: 60px;
  overflow: hidden; /* Скрываем текст, выходящий за пределы */
}

.file-name {
  white-space: normal; /* Разрешаем перенос слов */
  word-wrap: break-word; /* Переносим длинные слова */
  overflow: hidden; /* Скрываем текст, выходящий за пределы */
  text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Ограничиваем количество строк */
  -webkit-box-orient: vertical;
}

</style>