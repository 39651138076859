<template>
  <div>
    <div v-if="mode_view">
      <q-btn 
        flat 
        round 
        color="positive" 
        icon="visibility"
        @click="viewElement"/>
    </div>
    <div v-else>
      <!-- File Upload -->
      <div class="col-8 text-left" v-if="entityId">
        <div class="q-pa-sm h5 text-positive">
          <q-icon name="info" />{{ message[lang].fileUploadHelp }}
        </div>
        <q-btn
            color="primary"  rounded
            :label="message[lang].choose"
            @click="openGalleryDialog(false)"
          />
        <!-- <streaming-file
          @streamingSuccess="handleUploadSuccess"
          @streamingFailed="handleUploadFailed"
          :lang="lang"
          :parent="parent"
          :accept="'*'"
          :url="`sendfile?id=${entityId}`"
          :dense="true"
          :unelevated="true"
        /> -->
        <div v-if="fileAlert" class="text-warning q-my-sm">{{ fileError }}</div>
      </div>

      <!-- List of uploaded files -->
      <div class="q-mt-md" v-if="files.length > 0">
        <div class="text-accent h5">{{ message[lang].uploadedFiles }}</div>

        <draggable 
            v-model="files" 
            item-key="name"
            bordered 
            separator
            handle=".drag-handle"
            @end="emitFiles"
            >
            <template #item="{ element: file, index }">
                <q-item>
                <!-- Добавляем иконку для перетаскивания -->
                <q-item-section side>
                    <q-icon name="drag_indicator" class="drag-handle cursor-grab" />
                </q-item-section>

                <q-item-section>
                    <q-item-label>{{ file.name }}</q-item-label>
                    <q-item-label caption>{{ file.type }} - {{ formatFileSize(file.size) }}</q-item-label>
                </q-item-section>

                <q-item-section side>
                    <div class="row q-gutter-xs">
                    <q-btn 
                        flat 
                        round 
                        color="primary" 
                        icon="download"
                        @click="downloadFile(file)"
                    />
                    <q-btn
                        flat
                        round
                        color="positive"
                        icon="clear"
                        @click="handleFileRemove(file, index)"
                        :loading="removeLoading[index]"
                    />
                    </div>
                </q-item-section>
                </q-item>
            </template>
        </draggable>
      </div>
    </div>
  </div>

  <q-dialog
    v-model="dialogOpen"
    maximized
  >
    <q-card class="full-height column no-wrap">
      <q-bar class="bg-primary text-negative">
        <div class="text-h6">{{ message[lang].uploadedFiles }}</div>
        <q-space />
        <q-btn round dense unelevated color="secondary" icon="done" @click="handleDone">
          <q-tooltip>{{ message[lang].item_checked }}</q-tooltip>
        </q-btn>
        <q-btn dense flat round icon="close" @click="handleDone">
          <q-tooltip>{{message[lang].close}}</q-tooltip>
        </q-btn>
      </q-bar>

      <q-scroll-area class="col"> <!-- Используем q-scroll-area -->
        <div class="column full-height" style="min-height: 100%;">
          <q-list bordered separator 
             :style="selfItem.allowComments?'height: calc(100vh - 300px); min-height: 400px;':'height: calc(100vh); min-height: 400px;'">
            <q-item v-for="(file, index) in files" :key="index" clickable >
              <q-item-section @click="viewFile(file)">
                <q-item-label>{{ file.name }}</q-item-label>
                <q-item-label caption>{{ file.type }} - {{ formatFileSize(file.size) }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon name="download" color="primary"  @click="downloadFile(file)" />
              </q-item-section>
            </q-item>
          </q-list>
          <div 
            v-if="selfItem.allowComments"
            class="bg-dark q-mt-md" 
            style="height: auto; min-height: 200px;">
              <Comments 
                v-bind:lang="lang" 
                v-bind:entity-id="selfItem.id" 
                v-bind:user_current="parent.user_current" 
                v-bind:parent="parent" 
                v-bind:mode="'list'" 
                v-bind:heght="900" />
            </div>
        </div>
      </q-scroll-area>
    </q-card>
  </q-dialog>

  <!-- Диалог с компонентом MediaGallery -->
    <q-dialog v-model="galleryDialog">
      <q-card style="width: 80%; max-width: 1200px;">
        <q-card-section>
          <MediaGallery
            :parent="parent"
            :lang="lang"
            :message="message"
            :emitAsBase64="false"
            :accept="null"
            @file-selected="handleFileSelected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

</template>

<script setup>
import { ref, onMounted, computed, defineProps, defineEmits } from 'vue'
import { useQuasar } from 'quasar'
import draggable from 'vuedraggable'
import streamingFile from '/src/streamingFile';
import Comments from 'src/Comments.vue';
import MediaGallery from "src/components/MediaGallery";

const $q = useQuasar()

const props = defineProps({
    selfItem: Object,
  element: {
    type: Array
  },
  entityId: {
    type: String,
    default: ''
  },
  mode_view: Boolean,
  lang: {
    type: String,
    required: true
  },
  parent: {
    type: Object,
    required: true
  },
  message: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['files-loaded', 'files-removed', 'error','item-checked'])

const fileAlert = ref(false)
const fileError = ref('')
const removeLoading = ref({})
const files = ref(props?.element || [])
const dialogOpen = ref(false)
const galleryDialog = ref(false)

const openGalleryDialog = (emit_func) => {
  if(emit_func){
    handleFileSelected = emit_func;
  }
  galleryDialog.value = true;
};
// Обработка выбора файла из галереи
const handleFileSelected = (res) => {
  galleryDialog.value = false; // Закрыть диалог после выбора
  console.log('Выбран файл:', res); 
  const newFile = {
    href: res.href,
    name: res.name,
    type: formatFileType(res.type),
    size: res.size || 0
  }
  files.value.push(newFile)
  emitFiles()
  $q.notify({
    type: 'positive',
    message: props.message[props.lang].success_loaded,
  });

};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i))).toFixed(2) + ' ' + sizes[i];
};

const formatFileType = (mime_type) => {
    if (!mime_type) return 'file';
    
    // Разделяем MIME-тип на основную часть и подтип
    const [type, subtype] = mime_type.split('/');
    
    // Обрабатываем основные типы
    switch (type) {
        case 'image':
            return 'image';
        case 'video':
            return 'video';
        case 'audio':
            return 'audio';
        case 'text':
            return 'text';
        case 'application':
            // Обрабатываем подтипы для application
            switch (subtype) {
                case 'pdf':
                    return 'pdf';
                case 'msword':
                case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return 'doc';
                case 'vnd.ms-excel':
                case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return 'xls';
                case 'vnd.ms-powerpoint':
                case 'vnd.openxmlformats-officedocument.presentationml.presentation':
                    return 'ppt';
                case 'zip':
                case 'x-zip-compressed':
                case 'vnd.rar':
                case 'x-7z-compressed':
                    return 'archive';
                case 'json':
                    return 'json';
                case 'octet-stream':
                    return 'binary';
                default:
                    return subtype.split('.').pop() || 'file';
            }
        default:
            // Для остальных случаев возвращаем основной тип или подтип
            return subtype || type || 'file';
    }
};

const viewElement = () => {
  dialogOpen.value = true;
}

const alertError = (message) => {
  fileAlert.value = true
  fileError.value = message
  emit('error', message)
}

// const handleUploadSuccess = (response) => {
//   try {
//     if (response && props.parent.isJSON(response)) {
//       const res = JSON.parse(response)
//       console.log('::handleUploadSuccess res=',res)
//       if (res.error) {
//         alertError(res.error)
//       } else {
//         const newFile = {
//           href: res.href,
//           name: res.name,
//           type: formatFileType(res.type),
//           size: res.size || 0
//         }
//         files.value.push(newFile)
//         emitFiles()
//         $q.notify({
//           type: 'positive',
//           message: props.message[props.lang].success_loaded,
//         });
//       }
//     } else {
//       alertError(props.message[props.lang].failed_to_load)
//     }
//   } catch (e) {
//     alertError(props.message[props.lang].failed_to_load)
//   }
// }

const emitFiles = () => {
  emit('files-loaded', files.value)
}

const handleUploadFailed = (error) => {
  alertError(error.message || props.message[props.lang].failed_to_load)
}

const viewFile = (file) => {
  console.log('::viewFile ', file)
  window.open(file.href, '_blank');
}
const downloadFile = async (file) => {
  console.log('::downloadFile ', file) 
    try {
        const response = await fetch(file.href);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = blobUrl;
        link.download = file.name;
        
        document.body.appendChild(link);
        link.click();
        
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
        }, 100);
    } catch (error) {
        console.error('Download error:', error);
        // Fallback to original method if fetch fails
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = file.href;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => document.body.removeChild(link), 100);
    }
}
// const downloadFile = (file) => {
//     // Создаем скрытую ссылку
//     const link = document.createElement('a');
//     link.style.display = 'none';
//     link.href = file.href;
//     link.download = file.name; 
//     link.target = '_blank';

//     // Добавляем к DOM и эмулируем клик
//     document.body.appendChild(link);
//     link.click();

//     // Убираем ссылку
//     setTimeout(() => {
//         document.body.removeChild(link);
//         window.URL.revokeObjectURL(link.href);
//     }, 100);
// }

const doFileRemove = (file) => {
  return new Promise(resolve => {
    let url = `${props.parent.app_server_url}api/clearimage?client_key=${props.parent.member_id}&portal=${props.parent.portal}&name=${file.name}&access_token=${props.parent.access_token}&app_id=${props.parent.appID}`;
    props.parent.POST(url, { name: file.name }, function (response) {
      resolve(true);
    });
  })
}

const handleFileRemove = async (file, index) => {
  $q.dialog({
    title: props.message[props.lang].attention,
    message: `${props.message[props.lang].remove} ${file.name}?`,
    position: 'top',
    persistent: true,
    ok: {
      label: props.message[props.lang].remove,
      color: 'warning',
      rounded: true
    },
    cancel: {
      label: props.message[props.lang].prompt_cancel,
      color: 'positive',
      flat: true
    }
  }).onOk(async () => {
    try {
      removeLoading.value[index] = true
      await doFileRemove(file)
      files.value.splice(index, 1)
      emit('files-removed', files.value)
      $q.notify({
        type: 'positive',
        message: props.message[props.lang].file_deleted,
      });
    } catch (error) {
      alertError(error.message || props.message[props.lang].rev_error_title)
    } finally {
      removeLoading.value[index] = false
    }
  })
}

const handleDone = () => {
  emit('item-checked', props.selfItem);
  dialogOpen.value=false;
}

onMounted(() => {
    // console.log('::onMounted files=',props.element?.files)
    if (props.element?.files) {
        files.value = props.element.files
    }
});
</script>

<style scoped>
.q-card {
  display: flex;
  flex-direction: column;
}
/* .q-card__section {
  flex-grow: 1;
  overflow: hidden;
} */
.cursor-grab {
  cursor: grab;
}
.cursor-grab:active {
  cursor: grabbing;
}
.drag-handle {
  opacity: 0.6;
  transition: opacity 0.2s;
}
.drag-handle:hover {
  opacity: 1;
}
</style>