<template>
  <div>
    <div v-if="!mode">
      <q-btn size="xs" unelevated outline round icon="add" color="primary" @click="addRow(null, null, list)">
        <q-tooltip>{{message[lang].agree_user}}</q-tooltip>
      </q-btn>
    </div>
    <draggable 
      v-model="list"
      item-key="id"
      class="bordered separator"
      handle=".drag-handle"
      @end="save"
      :disabled="mode"
    >
      <template #item="{ element: rowItem, index: rowIndex }">
        <div class="row items-center justify-left">
          <div class="col-11">
            <q-icon v-if="!mode" class="cursor-pointer drag-handle" name="drag_handle" color="blue-grey-3" />
            <q-avatar size="24px" color="blue-grey-1" text-color="blue-grey-5">{{ rowIndex + 1 }}</q-avatar>
            <q-chip 
              v-for="(colItem, colIndex) in rowItem" 
              :key="colIndex" 
              :color="colItem.checked && colItem.checked !== 'false' ? 'secondary' : 'blue-grey-3'" 
              text-color="white"
              :icon="colItem.checked && colItem.checked !== 'false' ? 'done' : 'remove_circle_outline'"
              :removable="!mode" 
              @remove="delCol(colIndex, rowItem, rowIndex, list)" 
              size="md"
            >
              {{ colItem.user.name }} 
              <q-toggle v-if="mode && isMyQueue && colItem.user.value==parent.user_current.ID || parent.isModer"
                v-model="colItem.checked"
                @update:model-value='save'
                color="negative"
                >
                <q-tooltip>{{message[lang].to_approove}}</q-tooltip>
              </q-toggle>
            </q-chip>
            <q-btn v-if="!mode" size="xs" unelevated outline round icon="add" color="secondary" @click="addCol(rowItem)" >
                <q-tooltip>{{message[lang].agree_user}}</q-tooltip>
            </q-btn>
          </div>
          <div class="col-12 q-py-xs"><q-separator inset /></div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import draggable from 'vuedraggable';

const props = defineProps({
    parent: Object,
    message: Object,
    lang: String,
    co_ordinators: Object,
    mode: String,
});

const emit = defineEmits(['save']);

const list = ref([]);

// const isOneDimensionalArray = (array) => {
//   return array && array.every(item => !Array.isArray(item));
// };

const listInit = () => {
  // if (isOneDimensionalArray(props.co_ordinators)) {
  //   list.value = [];
  //   for (let i in props.co_ordinators) {
  //     list.value.push([{
  //       user: props.co_ordinators[i],
  //       checked: false
  //     }]);
  //   }
  //   save();
  // } else {
    list.value = props.co_ordinators ? [...props.co_ordinators] : [];
  // }
};

/**
 * Проверяет не моя ли очередь согласовывать
 */
const isMyQueue = computed(() => {
    const co_ordinators = list.value;
    const user_current_id = props.parent.user_current.ID;
    // Проходим по каждому подмассиву в co_ordinators
    for (const group_index in co_ordinators) {
        const group = co_ordinators[group_index];
        // Ищем индекс элемента, где user.value === user_current_id
        const userIndex = group.findIndex(item => item.user.value == user_current_id);
        console.log(':: userIndex=',userIndex,group_index, group)
        if (userIndex !== -1) {
            // Проверяем все ли предстоящие группы прочеканы
            for( let g=0; g < group_index; g++){
                for (let i in co_ordinators[g]) {
                    const item = co_ordinators[g][i];
                    if (!item.checked) {
                        console.log(':: ret 1 ',g, item, co_ordinators[g]);
                        return false;
                    }
                }
            }

            // Проверяем все элементы **перед** найденным
            for (let i = 0; i < userIndex; i++) {
                if (!group[i].checked) {
                    console.log(':: ret 2');
                    return false;
                }
            }
            return true;
        }
  }
  // Если user_current_id не найден ни в одном подмассиве
  return false;
})

const addRow = async (rowItem, rowIndex, list) => {
    const res = await props.parent.usersAdd([], true);
    console.log('::addRow res=')
    if(res?.length > 0){
        let user = res[0];
        if (!list.find(el => el[0].user.id == user.id)) {
        delete user.photo; delete user.sub; delete user.sup; delete user.url; delete user.position;
        let obj = [{ user: user, checked: false }];
        if (rowIndex === null) {
            list.push(obj);
        } else {
            list.splice(rowIndex, 0, obj);
        }
        }
        save();
    }


//   BX24.selectUser((user) => {
//     if (!list.find(el => el[0].user.id == user.id)) {
//       delete user.photo; delete user.sub; delete user.sup; delete user.url; delete user.position;
//       let obj = [{ user: user, checked: false }];
//       if (rowIndex === null) {
//         list.push(obj);
//       } else {
//         list.splice(rowIndex, 0, obj);
//       }
//     }
//     save();
//   });
};

const addCol = async (list) => {
    const res = await props.parent.usersAdd([], true);
    console.log('::addCol res=')
    if(res?.length > 0){
        let user = res[0];
        if (!list.find(el => el.user.id == user.id)) {
            delete user.photo; delete user.sub; delete user.sup; delete user.url; delete user.position;
            let obj = { user: user, checked: false };
            list.push(obj);
        }
        save();
    }
//   BX24.selectUser((user) => {
//     if (!list.find(el => el.user.id == user.id)) {
//       delete user.photo; delete user.sub; delete user.sup; delete user.url; delete user.position;
//       let obj = { user: user, checked: false };
//       list.push(obj);
//     }
//     save();
//   });
};

const delCol = (index1, list1, index2, list2) => {
  list1.splice(index1, 1);
  if (list1.length == 0) {
    list2.splice(index2, 1);
  }
  save();
};

const save = () => {
  emit('save', list.value);
};

onMounted(() => {
  listInit();
});
</script>

<style scoped>
    .draggable-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Аналог q-gutter-sm */
}
</style>