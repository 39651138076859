<template>
  <q-dialog
    v-model="showDialog"
    persistent
    @hide="resetForm"
  >
    <q-card style="min-width: 400px">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-accent h3">{{ isEditMode ? message[lang].shelf_edit : message[lang].shelf_add }}</div>
        <q-space />
        <q-btn icon="close" color="positive" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section>
        <q-form @submit="handleSubmit" class="q-gutter-md">
          <!-- Аватар -->
          <div class="flex flex-center q-mb-md">
            <q-avatar size="100px" class="cursor-pointer" @click="openGalleryDialog(false)">
              <img v-if="form.avatar" :src="form.avatar">
              <q-icon v-else name="photo_camera" size="50px" />
              <q-tooltip>{{message[lang].click_for_load}}</q-tooltip>
            </q-avatar>
          </div>

          <!-- Название полки -->
          <q-input
            v-model="form.name"
            :label="message[lang].shelf_name+'*'"
            outlined
            rounded
            lazy-rules
            :rules="[val => !!val || message[lang].requaried_field]"
          />

          <!-- Описание -->
          <q-input
            v-model="form.description"
            :label="message[lang].description"
            outlined
            rounded
            type="textarea"
            autogrow
          />

          <!-- Категория -->
          <q-select
            v-model="form.category"
            :options="categories"
            :label="message[lang].category"
            outlined
            rounded
            emit-value
            map-options
            options-dense
            option-value="id"
            option-label="text"
          />

          <!-- Компетенция -->
          <q-select
            v-model="form.competence"
            :options="competences"
            :label="message[lang].ipr_comp"
            outlined
            rounded
            use-input
            map-options
            emit-value
            multiple
            use-chips
            option-value="id"
            option-label="name"
            input-debounce="500"
            @filter="filterCompetences"
            lazy-rules
            :loading="competencesLoading"
          />

          <!-- Разрешить комментарии -->
          <q-toggle
            v-model="form.allowComments"
            :label="message[lang].comments_available"
          />

          <!-- Выводить АОС -->
          <q-toggle
            v-model="form.showAOS"
            :label="message[lang].show_AOS"
          />

          <!-- Баллы -->
          <q-input
            v-model="form.points"
            :label="message[lang].points"
            outlined
            rounded
            type="number"
            min="0"
          />

          <!-- Скрыть в каталоге -->
          <q-toggle
            v-model="form.hideInCatalog"
            :label="message[lang].hide_cat"
          />

          <!-- Скрыть от экстранет -->
          <q-toggle
            v-model="form.hideFromExtranet"
            :label="message[lang].hide_extra"
          />

          <!-- Только для подразделений -->
          <q-select
            v-model="form.allowedDepartments"
            :options="departments"
            :label="message[lang].hide_dep"
            outlined
            rounded
            use-input
            map-options
            emit-value
            multiple
            use-chips
            option-value="value"
            option-label="text"
            input-debounce="500"
            @filter="filterDepartments"
            lazy-rules
            :loading="departmentsLoading"
          />

          <q-card-actions align="right" class="q-pt-none">
            <!-- Публиковать -->
            <q-toggle
              v-model="form.isPublished"
              :label="message[lang].publish"
            />

            <q-btn
              flat
              rounded
              :label="message[lang].prompt_cancel"
              color="primary"
              v-close-popup
            />
            <q-btn
              rounded
              type="submit"
              :label="message[lang].save"
              color="primary"
              :loading="isLoading"
            />
          </q-card-actions>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>

  <!-- Диалог с компонентом MediaGallery -->
    <q-dialog v-model="galleryDialog">
      <q-card style="width: 80%; max-width: 1200px;">
        <q-card-section>
          <MediaGallery
            :parent="parent"
            :lang="lang"
            :message="message"
            :emitAsBase64="false"
            @file-selected="handleFileSelected"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

</template>

<script setup>
import { ref, computed, watch, toRaw } from 'vue'
import { useQuasar } from 'quasar'
import MediaGallery from "src/components/MediaGallery";

const $q = useQuasar()

// Пропсы
const props = defineProps({
  parent: {
    type: Object,
    default: () => ({})
  },
  message: {
    type: Object,
    default: () => ({})
  },
  lang:{
    type:String,
    default:'ru'
  },
  modelValue: Boolean,
  shelfData: {
    type: Object,
    default: () => ({})
  },
  saved_categories: {
    type: Array,
    default: () => null
  }
})

// Эмиты
const emit = defineEmits(['update:modelValue', 'submit'])

// Состояние компонента
const showDialog = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const categories = computed(() => {
  return props.saved_categories?props.saved_categories:
  [
    { text: props.message?.[props.lang]?.books || 'Books', id: 'books' },
    { text: props.message?.[props.lang]?.documents || 'Documents', id: 'documents' },
    { text: props.message?.[props.lang]?.media || 'Media', id: 'media' },
    { text: props.message?.[props.lang]?.other || 'Other', id: 'other' }
  ]
})

const galleryDialog = ref(false);

const isEditMode = computed(() => !!(props.shelfData && props.shelfData.id))

const form = ref({
  id: null,
  name: '',
  description: '',
  avatar: null,
  category: 'books',
  isPublished: false,
  competence: null,
  allowComments: false,
  showAOS: false,
  points: 0,
  hideInCatalog: false,
  hideFromExtranet: false,
  allowedDepartments: [],
})

const fileInput = ref(null)
const isLoading = ref(false)

// Для lazy load полей
const competences = ref([])
const competencesLoading = ref(false)
const departments = ref([])
const departmentsLoading = ref(false)

// Функции
const resetForm = () => {
  form.value = {
    id: null,
    name: '',
    description: '',
    avatar: null,
    category: 'books',
    isPublished: false,
    competence: null,
    allowComments: false,
    showAOS: false,
    points: 0,
    hideInCatalog: false,
    hideFromExtranet: false,
    allowedDepartments: [],
  }
}

const filterCompetences = async (val, update) => {
  // if (val === '') {
  //   update(() => {
  //     competences.value = []
  //   })
  //   return
  // }
  
  competencesLoading.value = true
  try {
    // Здесь должна быть логика загрузки компетенций с сервера
    // Это пример - замените на реальный API-вызов
    const response = await props.parent.competenceGetList("competence", true, null, null, null, null, val);
    console.log(':: response=',response)
    update(() => {
      competences.value = response
    })
  } catch (error) {
    console.error(error)
    $q.notify({
      type: 'negative',
      message: 'error'//'Ошибка при загрузке компетенций'
    })
  } finally {
    competencesLoading.value = false
  }
}

const filterDepartments = async (val, update) => {
  // if (val === '') {
  //   update(() => {
  //     departments.value = []
  //   })
  //   return
  // }
  
  departmentsLoading.value = true
  try {
    // Здесь должна быть логика загрузки подразделений с сервера
    // Это пример - замените на реальный API-вызов
    const response = await props.parent.getDepartments();
    
    update(() => {
      if(val?.trim()){
        departments.value =  response.filter(el=>el.text.toLowerCase()?.indexOf(val)!==-1);
      }else{
        departments.value =  response
      }
    })
  } catch (error) {
    console.error(error)
    $q.notify({
      type: 'negative',
      message: 'error'//'Ошибка при загрузке подразделений'
    })
  } finally {
    departmentsLoading.value = false
  }
}



// const triggerFileInput = () => {
//   fileInput.value?.click()
// }
const openGalleryDialog = (emit_func) => {
  if(emit_func){
    handleFileSelected = emit_func;
  }
  galleryDialog.value = true;
}
// Обработка выбора файла из галереи
const handleFileSelected = (file) => {
  galleryDialog.value = false; // Закрыть диалог после выбора
  console.log('Выбран файл:', file); 
  form.value.avatar = file.href;
}

const handleFileUpload = (event) => {
  const file = event.target.files[0]
  if (!file) return

  // Проверка типа файла
  if (!file.type.match('image.*')) {
    $q.notify({
      type: 'negative',
      color: 'warning',
      message: props.message[props.lang].only_image_allowed
    })
    return
  }

  if (file.size > 20 * 1024 * 1024) {
    $q.notify({
      type: 'negative',
      color: 'warning',
      message: props.message[props.lang].file_too_large
    })
    return
  }

  const reader = new FileReader()
  reader.onload = (e) => {
    form.value.avatar = e.target.result
  }
  reader.onerror = () => {
    $q.notify({
      type: 'negative',
      color: 'warning',
      message: props.message[props.lang].file_read_error
    })
  }
  reader.readAsDataURL(file)
}

const handleSubmit = async () => {
  isLoading.value = true
  
  try {
    // Преобразуем Proxy в обычный объект перед отправкой
    const formData = { 
      ...toRaw(form.value),
      // Дополнительные преобразования при необходимости
      name: String(form.value.name),
      description: String(form.value.description),
      points: Number(form.value.points) || 0
    }

    await new Promise(resolve => setTimeout(resolve, 1000))
    
    emit('submit', formData)
    $q.notify({
      type: 'positive',
      message: isEditMode.value ? props.message[props.lang].shelf_updated : props.message[props.lang].shelf_added
    })
    showDialog.value = false
  } catch (error) {
    $q.notify({
      type: 'negative',
      color: 'warning',
      message: props.message[props.lang].save_error,
      caption: error.message
    })
  } finally {
    isLoading.value = false
  }
}

// Watcher с обработкой Proxy
watch(() => props.shelfData, (newVal) => {
  if (newVal && newVal.id) {
    // Используем toRaw для копирования данных из Proxy
    form.value = { 
      ...toRaw(newVal),
      // Убедимся, что новые поля инициализированы, если их нет в shelfData
      competence: newVal.competence || null,
      allowComments: newVal.allowComments || false,
      showAOS: newVal.showAOS || false,
      points: newVal.points || 0,
      hideInCatalog: newVal.hideInCatalog || false,
      hideFromExtranet: newVal.hideFromExtranet || false,
      allowedDepartments: newVal.allowedDepartments || [],
    }
  } else {
    resetForm()
  }
}, { immediate: true })


</script>

<style scoped>
.q-avatar {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s;
}

.q-avatar:hover {
  border-color: var(--q-primary);
}


</style>