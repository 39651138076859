<template>
  <div>
    <div v-if="mode_view">
      <q-btn 
        flat 
        round 
        color="positive" 
        icon="visibility"
        @click="viewElement"/>
    </div>
    <div v-else>
      <!-- Link Input -->
      <div class="col-8 text-left" v-if="entityId">
        <div class="q-pa-sm h5 text-positive">
          <q-icon name="info" />{{ message[lang].linkAddHelp }}
        </div>
        <div class="row q-gutter-sm items-center">
          <q-input
            v-model="newLink.name"
            :label="message[lang].linkName"
            outlined
            dense
            class="col"
          />
          <q-input
            v-model="newLink.url"
            :label="message[lang].linkUrl"
            outlined
            dense
            class="col"
            type="url"
          />
          <q-btn
            rounded
            color="positive"
            :label="message[lang].add"
            @click="addLink"
            :disable="!newLink.name || !newLink.url"
          />
        </div>
        <div v-if="linkAlert" class="text-warning q-my-sm">{{ linkError }}</div>
      </div>

      <!-- List of links -->
      <div class="q-mt-md" v-if="links.length > 0">
        <div class="text-accent h5">{{ message[lang].linksList }}</div>

        <draggable 
          v-model="links" 
          item-key="name"
          bordered 
          separator
          handle=".drag-handle"
          @end="emitLinks"
        >
          <template #item="{ element: link, index }">
            <q-item>
              <!-- Drag handle icon -->
              <q-item-section side>
                <q-icon name="drag_indicator" class="drag-handle cursor-grab" />
              </q-item-section>

              <q-item-section>
                <q-item-label>
                  <q-popup-edit 
                    v-model="link.name" 
                    @save="emitLinks"
                    :validate="val => val.length > 0"
                    v-slot="scope"
                  >
                    <q-input
                      v-model="scope.value"
                      dense
                      autofocus
                      :label="message[lang].editName"
                      :rules="[val => !!val || message[lang].fieldRequired]"
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                  <span class="cursor-pointer">{{ link.name }}</span>
                </q-item-label>
                <q-item-label caption>
                  <q-popup-edit 
                    v-model="link.url" 
                    @save="emitLinks"
                    :validate="validateUrl"
                    v-slot="scope"
                  >
                    <q-input
                      v-model="scope.value"
                      dense
                      autofocus
                      :label="message[lang].editUrl"
                      :rules="[
                        val => !!val || message[lang].fieldRequired,
                        val => validateUrl(val) || message[lang].invalidUrl
                      ]"
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                  <span class="cursor-pointer">{{ link.url }}</span>
                </q-item-label>
              </q-item-section>

              <q-item-section side>
                <div class="row q-gutter-xs">
                  <q-btn 
                    flat 
                    round 
                    color="primary" 
                    icon="open_in_new"
                    @click="openLink(link)"
                  />
                  <q-btn
                    flat
                    round
                    color="positive"
                    icon="clear"
                    @click="handleLinkRemove(link, index)"
                    :loading="removeLoading[index]"
                  />
                </div>
              </q-item-section>
            </q-item>
          </template>
        </draggable>
      </div>
    </div>
  </div>

  <q-dialog
    v-model="dialogOpen"
    maximized
  >

    <q-card class="full-height column no-wrap">
      <q-bar class="bg-primary text-white">
        <div class="text-h6">{{ message[lang].linksList }}</div>
        <q-space />
        <q-btn round dense unelevated color="secondary" icon="done" @click="handleDone">
          <q-tooltip>{{ message[lang].item_checked }}</q-tooltip>
        </q-btn>
        <q-btn dense round flat icon="close" @click="handleDone">
          <q-tooltip>{{message[lang].close}}</q-tooltip>
        </q-btn>
      </q-bar>

      <q-scroll-area class="col"> <!-- Используем q-scroll-area -->
        <div class="column full-height" style="min-height: 100%;">
          <q-list bordered separator 
            :style="selfItem.allowComments?'height: calc(100vh - 300px); min-height: 400px;':'height: calc(100vh); min-height: 400px;'"
            >
            <q-item v-for="(link, index) in links" :key="index" clickable>
              <q-item-section @click="openLink(link)">
                <q-item-label>{{ link.name }}</q-item-label>
                <q-item-label caption>{{ link.url }}</q-item-label>
              </q-item-section>
              <q-item-section side @click="openLink(link)">
                <q-icon name="open_in_new" color="primary" />
              </q-item-section>
            </q-item>
          </q-list>
          <!-- Блок комментариев (остаётся внизу) -->
          <div 
            v-if="selfItem.allowComments" 
            class="bg-dark q-mt-md"
            style="height: auto; min-height: 200px;" >
            <Comments 
                v-bind:lang="lang" 
                v-bind:entity-id="selfItem.id" 
                v-bind:user_current="parent.user_current" 
                v-bind:parent="parent" 
                v-bind:mode="'list'" 
                v-bind:heght="900" />
          </div>
        </div>
      </q-scroll-area>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue'
import { useQuasar } from 'quasar'
import draggable from 'vuedraggable'
import Comments from 'src/Comments.vue';

const $q = useQuasar()

const props = defineProps({
  selfItem: Object,
  element: {
    type: Array
  },
  entityId: {
    type: String,
    default: ''
  },
  mode_view: Boolean,
  lang: {
    type: String,
    required: true
  },
  parent: {
    type: Object,
    required: true
  },
  message: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['links-loaded', 'links-removed', 'error','item-checked'])

const linkAlert = ref(false)
const linkError = ref('')
const removeLoading = ref({})
const links = ref(props?.element || [])
const dialogOpen = ref(false)
const newLink = ref({
  name: '',
  url: ''
})

const validateUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

const viewElement = () => {
  dialogOpen.value = true;
}

const alertError = (message) => {
  linkAlert.value = true
  linkError.value = message
  emit('error', message)
}

const addLink = () => {
  try {
    linkAlert.value = false;
    if (!validateUrl(newLink.value.url)) {
      alertError(props.message[props.lang].invalidUrl)
      return
    }

    links.value.push({
      name: newLink.value.name,
      url: newLink.value.url
    })
    
    newLink.value = { name: '', url: '' }
    emitLinks()
    $q.notify({
      type: 'positive',
      message: props.message[props.lang].linkAdded,
    });
  } catch (e) {
    alertError(props.message[props.lang].failedToAddLink)
  }
}

const emitLinks = () => {
  emit('links-loaded', links.value)
}

const openLink = (link) => {
  window.open(link.url, '_blank');
}

const handleLinkRemove = async (link, index) => {
  $q.dialog({
    title: props.message[props.lang].attention,
    message: `${props.message[props.lang].remove} ${link.name}?`,
    position: 'top',
    persistent: true,
    ok: {
      label: props.message[props.lang].remove,
      color: 'warning',
      rounded: true
    },
    cancel: {
      label: props.message[props.lang].prompt_cancel,
      color: 'positive',
      flat: true
    }
  }).onOk(async () => {
    try {
      removeLoading.value[index] = true
      links.value.splice(index, 1)
      emit('links-removed', links.value)
      $q.notify({
        type: 'positive',
        message: props.message[props.lang].linkDeleted,
      });
    } catch (error) {
      alertError(error.message || props.message[props.lang].rev_error_title)
    } finally {
      removeLoading.value[index] = false
    }
  })
}

const handleDone = () => {
  emit('item-checked', props.selfItem);
  dialogOpen.value=false;
}

onMounted(() => {
  if (props.element?.links) {
    links.value = props.element.links
  } else if (props.element) {
    links.value = props.element
  }
});
</script>

<style scoped>
.q-card {
  display: flex;
  flex-direction: column;
}
.q-card__section {
  flex-grow: 1;
  overflow: hidden;
}
.cursor-grab {
  cursor: grab;
}
.cursor-grab:active {
  cursor: grabbing;
}
.drag-handle {
  opacity: 0.6;
  transition: opacity 0.2s;
}
.drag-handle:hover {
  opacity: 1;
}
</style>