<template>
  <div>
    <div v-if="mode_view">
      <q-btn 
        flat 
        round 
        color="positive" 
        icon="visibility"
        @click="viewElement"/>
    </div>
    <div v-else>
      <!-- Text Block Input -->
      <div class="col-8 text-left" v-if="entityId">
        <div class="q-pa-sm h5 text-positive">
          <q-icon name="info" />{{ message[lang].textAddHelp }}
        </div>
        <div class=" q-gutter-y-sm items-center">
            <div>
            <q-input
                v-model="newBlock.name"
                :label="message[lang].textName"
                outlined
                dense
                class="col"
            />
            </div>
            <div>
            <q-input
                v-model="newBlock.keywords"
                :label="message[lang].keywords"
                outlined
                dense
                class="col"
                :hint="message[lang].textKeyWords"
            />
            </div>
            <div>
            <q-btn
                rounded
                color="positive"
                :label="message[lang].add"
                @click="addTextBlock"
                :disable="!newBlock.name"
            />
            </div>
        </div>
        <div v-if="blockAlert" class="text-warning q-my-sm">{{ blockError }}</div>
      </div>

      <!-- List of text blocks -->
      <div class="q-mt-md" v-if="textBlocks?.length > 0">
        <div class="text-accent h5">{{ message[lang].textBlocksList }}</div>

        <draggable 
          v-model="textBlocks" 
          item-key="name"
          bordered 
          separator
          handle=".drag-handle"
          @end="emitTextBlocks"
        >
          <template #item="{ element: block, index }">
            <q-item>
              <!-- Drag handle icon -->
              <q-item-section side>
                <q-icon name="drag_indicator" class="drag-handle cursor-grab" />
              </q-item-section>

              <q-item-section>
                <q-item-label>
                  <q-popup-edit 
                    v-model="block.name" 
                    @save="emitTextBlocks"
                    :validate="val => val.length > 0"
                    v-slot="scope"
                  >
                    <q-input
                      v-model="scope.value"
                      dense
                      autofocus
                      :label="message[lang].editName"
                      :rules="[val => !!val || message[lang].fieldRequired]"
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                  <span class="cursor-pointer">{{ block.name }}</span>
                </q-item-label>
                <q-item-label caption>
                  <span class="cursor-pointer" @click="editBlockContent(block)">{{ message[lang].clickme }}</span>
                </q-item-label>
                <q-item-label caption v-if="block?.keywords">
                    <div class="row q-gutter-xs cursor-pointer">
                        <div  v-for="(keyword, idx) in block.keywords.split(',')" :key="idx">
                            <q-chip 
                                v-if="keyword.trim()"
                                dense 
                                outline
                                color="positive" 
                            >
                                {{ keyword.trim() }}
                            </q-chip>
                            <q-popup-edit v-if="keyword.trim()"
                                v-model="block.keywords" 
                                @save="emitTextBlocks"
                                v-slot="scope"
                            >
                                <q-input
                                v-model="scope.value"
                                dense
                                autofocus
                                :label="message[lang].editKeywords"
                                :hint="message[lang].textKeyWords"
                                @keyup.enter="scope.set"
                                />
                            </q-popup-edit>
                        </div>
                    </div>
                </q-item-label>
              </q-item-section>

              <q-item-section side>
                <div class="row q-gutter-xs">
                  <q-btn
                    flat
                    round
                    color="positive"
                    icon="clear"
                    @click="handleBlockRemove(block, index)"
                    :loading="removeLoading[index]"
                  />
                </div>
              </q-item-section>
            </q-item>
          </template>
        </draggable>
      </div>
    </div>
  </div>

  <!-- Editor Dialog -->
  <q-dialog
    v-model="editorDialogOpen"
    maximized
  >
    <q-card class="full-height">
      <q-bar class="bg-primary text-white">
        <div class="text-h6">{{ currentBlock?.name || message[lang].textEditor }}</div>
        <q-space />
        <q-btn round dense flat icon="close" v-close-popup>
          <q-tooltip>{{message[lang].close}}</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section class="q-pa-none full-height">
        <Editor
          v-if="editorDialogOpen"
          ref="editorRef"
          v-model="currentBlockContent"
          @file-upload="handleFileUpload"
          api-key="no-api-key"
          :init="{
            license_key: 'gpl',
            plugins: 'lists link image media table code help wordcount preview searchreplace',
            toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | forecolor backcolor emoticons | help',
            language: lang,
            browser_spellcheck: true,
            max_height: 1500,
            max_width: 900,
            min_height: 300,
            min_width: 600,
            images_upload_url: `${parent.app_server_url}api/sendimage?client_key=`+ parent.member_id+'&portal='+parent.portal+'&lesson_id='+entityId+'&access_token='+parent.access_token+`&app_id=${parent.appID}`,
            automatic_uploads: true,
            content_css:'local/css/uni.css',
            file_picker_types: 'file image media video',
            file_picker_callback: (callback, value, meta) => {
              openFileUploadDialog(callback, value, meta);
            },
            extended_valid_elements: 'iframe[src|width|height|frameborder|allow|allowfullscreen]',
          }"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          rounded 
          color="primary" 
          :label="message[lang].save" 
          @click="saveBlockContent"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <!-- View Dialog -->
  <q-dialog
    v-model="viewDialogOpen"
    maximized
  >
    <q-card class="full-height column no-wrap">
      <q-bar class="bg-primary text-negative">
        <div class="text-h6">{{ message[lang].longread }}</div>
        <q-space />
        <q-btn round dense flat icon="close" v-close-popup>
          <q-tooltip>{{message[lang].close}}</q-tooltip>
        </q-btn>
      </q-bar>

      <q-scroll-area class="col"> <!-- Используем q-scroll-area -->
      <q-card-section>
        <q-list bordered separator>
          <q-item v-for="(block, index) in textBlocks" :key="index">
            <q-item-section>
              <q-item-label class="text-accent h3">{{ block.name }}</q-item-label>
              <q-item-label caption>
                <div class="row q-gutter-xs">
                    <div  v-for="(keyword, idx) in block.keywords.split(',')" :key="idx">
                        <q-chip 
                            v-if="keyword.trim()"
                            dense 
                            outline
                            color="positive" 
                        >
                            {{ keyword.trim() }}
                        </q-chip>
                    </div>
                </div>
              </q-item-label>
              <q-item-label>
                <div v-html="block.content"></div>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <div class="row bg-dark q-mt-md" v-if="selfItem.allowComments">
                <div class="col-6 offset-3">
                <Comments 
                    v-bind:lang="lang" 
                    v-bind:entity-id="selfItem.id" 
                    v-bind:user_current="parent.user_current" 
                    v-bind:parent="parent" 
                    v-bind:mode="'list'" 
                    v-bind:heght="900" />
                </div>
          </div>
        </q-card-section >
      </q-scroll-area>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue'
import { useQuasar } from 'quasar'
import draggable from 'vuedraggable'
import Editor from '@tinymce/tinymce-vue'
import Comments from 'src/Comments.vue';

const $q = useQuasar()

const props = defineProps({
  selfItem: Object,
  element: {
    type: Array
  },
  entityId: {
    type: String,
    default: ''
  },
  mode_view: Boolean,
  lang: {
    type: String,
    required: true
  },
  parent: {
    type: Object,
    required: true
  },
  message: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['longread-loaded', 'longread-removed', 'error'])

const blockAlert = ref(false)
const blockError = ref('')
const removeLoading = ref({})
const textBlocks = ref(props?.element || [])
const editorDialogOpen = ref(false)
const viewDialogOpen = ref(false)
const newBlock = ref({
  name: '',
  content: '',
  keywords: ''
})
const currentBlock = ref(null)
const currentBlockContent = ref('')
const editorRef = ref(null)

const viewElement = () => {
  viewDialogOpen.value = true
}

const alertError = (message) => {
  blockAlert.value = true
  blockError.value = message
  emit('error', message)
}

const addTextBlock = () => {
  try {
    blockAlert.value = false
    
    textBlocks.value.push({
      name: newBlock.value.name,
      content: newBlock.value.content || '',
      keywords: newBlock.value.keywords || ''
    })
    
    newBlock.value = { name: '', content: '', keywords: '' }
    emitTextBlocks()
    $q.notify({
      type: 'positive',
      message: props.message[props.lang].blockAdded,
    })
  } catch (e) {
    alertError(props.message[props.lang].failedToAddBlock)
  }
}

const emitTextBlocks = () => {
  let for_save = [];
  for(let item of textBlocks.value){
    for_save.push({
      name: props.parent.replaceQuotes(item.name),
      content: props.parent.utf8_to_b64(item.content),
      keywords: props.parent.replaceQuotes(item.keywords)
    })
  }
  emit('longread-loaded', for_save)
}

const editBlockContent = (block) => {
  currentBlock.value = block
  currentBlockContent.value = block.content
  editorDialogOpen.value = true
}

const saveBlockContent = () => {
  if (currentBlock.value) {
    currentBlock.value.content = currentBlockContent.value
    emitTextBlocks()
    editorDialogOpen.value = false
    $q.notify({
      type: 'positive',
      message: props.message[props.lang].done_success,
    })
  }
}

const handleBlockRemove = async (block, index) => {
  $q.dialog({
    title: props.message[props.lang].attention,
    message: `${props.message[props.lang].remove} ${block.name}?`,
    position: 'top',
    persistent: true,
    ok: {
      label: props.message[props.lang].remove,
      color: 'warning',
      rounded: true
    },
    cancel: {
      label: props.message[props.lang].prompt_cancel,
      color: 'positive',
      flat: true
    }
  }).onOk(async () => {
    try {
      removeLoading.value[index] = true
      textBlocks.value.splice(index, 1)
      emit('longread-removed', textBlocks.value)
      $q.notify({
        type: 'positive',
        message: props.message[props.lang].success,
      })
    } catch (error) {
      alertError(error.message || props.message[props.lang].rev_error_title)
    } finally {
      removeLoading.value[index] = false
    }
  })
}

const handleFileUpload = (file) => {
  // Handle file upload logic here
  console.log('File uploaded:', file)
}

const openFileUploadDialog = (callback, value, meta) => {
  // Implement file picker dialog logic here
  console.log('File picker callback:', callback, value, meta)
}

onMounted(() => {
  // console.log('::onMounted textBlocks=', props.element)
  if (props?.element?.length > 0) {
    textBlocks.value = [];
    for(let item of props?.element){
      textBlocks.value.push({
        name: props.parent.restoreQuotes(item.name),
        content: props.parent.showBase64Text(item.content),
        keywords: props.parent.restoreQuotes(item.keywords || '')
      })
    }
  }
})
</script>

<style scoped>
.q-card {
  display: flex;
  flex-direction: column;
}
.q-card__section {
  flex-grow: 1;
  overflow: hidden;
}
.cursor-grab {
  cursor: grab;
}
.cursor-grab:active {
  cursor: grabbing;
}
.drag-handle {
  opacity: 0.6;
  transition: opacity 0.2s;
}
.drag-handle:hover {
  opacity: 1;
}
</style>