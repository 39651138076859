<template>
<div>
    <q-list padding  >
        <q-item v-for="item in fltMenuItems" :key="item.name"
            clickable
            @click="goToMy(item.name)"
            :active="tab_name === item.name"
            active-class="text-active"
        >

          <q-item-section :class="`text-${item.color}`">
                <div class="row items-center">
                  <div class="col-3"><q-icon size="md" :color="item.status===false?'positive':item.color" :name="item.icon" /></div> 
                  <div :class="`col-9 ${item.status===false?'text-positive':''}`">
                    {{item.label}}
                  </div>
                </div>

                <div v-if="menu_edit && isModer && !item.ro">
                  <q-toggle toggle-indeterminate size='xs' color="positive" class="text-positive h6"
                  v-model="left_menu_statuses[item.name]" 
                  :label="props.message[props.lang][item.status===null?'left_menu_status_null':(item.status?'left_menu_status_true':'left_menu_status_false')]" 
                  />
                </div>
                <div v-else-if="menu_edit">
                  <q-toggle v-if="item.status===null" size='xs' color="positive" class="text-positive h6"
                  v-model="user_menu_statuses[item.name]" 
                  :label="props.message[props.lang][item.user_status?'left_menu_status_true':'left_menu_status_false']" 
                  />
                  <span v-else class="text-positive">
                    {{props.message[props.lang][item.user_status?'left_menu_status_true':'left_menu_status_false']}}
                  </span>
              </div>
          </q-item-section>

          <q-badge v-if="item.badge"
              rounded
              floating
              color="warning"
              style="margin-right: 10px"
            >
              {{ item.badge }}
            </q-badge>
        </q-item>

        <q-item>
          <q-item-section>
            <div class="row justify-end">
              <q-btn flat rounded no-caps size="sm" color="positive" :label="props.message[props.lang][menu_edit?'save':'edit_left_menu']" @click="btnEdit('save')"/>
              <q-btn v-if="menu_edit" flat rounded no-caps size="sm" color="positive" :label="props.message[props.lang].cancel" @click="btnEdit(false)"/>
            </div>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>
            <div class="row justify-center">
              <q-select
                  style="width: 140px"
                  outlined
                  rounded
                  v-model="lang_model"
                  color="positive"
                  :options="props.parent?.lang_options || []"
                  map-options
                  option-label="text"
                  emit-value
                  @update:model-value="changeLang"
              />
            </div>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section align="center">
             <a href="https://www.novus-hcm.com/" target="blank" class="text-positive h5">Novus-HCM</a>
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>
            <div ref="widgetContainer"></div>
          </q-item-section>
        </q-item>
    
    </q-list>
    


    <!-- <div
        class="row justify-center"
        style="width: 100%; position: absolute; bottom: 40px"
        >
        <q-select
            style="width: 140px"
            outlined
            rounded
            v-model="lang_model"
            color="positive"
            :options="props.parent?.lang_options || []"
            map-options
            option-label="text"
            emit-value
            @update:model-value="changeLang"
        />
    </div>

    <div
        class="text-center"
        style="width: 100%; position: absolute; bottom: 10px"
        >
        <a href="https://www.novus-hcm.com/" target="blank" class="text-positive h5"
            >Novus-HCM</a
        >
    </div> -->

</div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

// Props
const props = defineProps({
    isMobile:Boolean,
    isModer:Boolean,
    tab_name:String,
    lang: String,
    message: Object,
    countNotifications: Number,
    schedulesTests: Number,
    schedulesCourses: Number,
    countDegree360: Number,
    countOpens: Number,
    badgeCompetece: Number,
    hide_cat_courses:String,
    hide_cat_tests:String,
    hide_cat_events:String,
    hide_cat_shelves:String,
    parent:Object
});

const emit = defineEmits(['go-to-my', 'change-lang']);

const menu_edit = ref(false);

let left_menu_statuses = ref({});
let user_menu_statuses = ref({});

const lang = props.parent.lang_options.find(el=>{return el.value==props.lang});
// console.log(';: lang=',props.lang, lang, props.parent.lang_options)
let lang_model = lang?.text?ref(lang.text):ref('');


const fltMenuItems = computed(() => {
  return menuItems.value.filter(item => {
    // console.log(':: ',item.notvisible, props.isModer, item.status, item.user_status, menu_edit.value)
    return !item.notvisible && 
      (props.isModer || item.status===true || item.status===null && (item.user_status===true || menu_edit.value))});
});

const goToMy = (action) =>{
    emit('go-to-my', action);
}

const changeLang = async (lang) => {
  const $this = props.parent;
  if (!$this) {
    console.error('Parent is not defined');
    return;
  }
  const langObj = $this.lang_options.find(el => el.value === lang);
  try {
    
    if (!$this.dictionary[lang]) {
      $this.loading = true;
      const res = await $this.getDictionary(lang);
      $this.dictionary[lang] = res;
      $this.message = $this.dictionary;
      $this.messageObj = $this.dictionary;
      $this.loading = false;
    }
    // initMenuItems(lang);
    emit('change-lang', langObj?.text);
  } catch (error) {
    console.error('Error fetching dictionary:', error);
  }
};

const menuItems = computed(() => {
  const items = [
    {name:'notifications', label:props.message[props.lang].my_notifications, icon:"notifications_none", color:"primary", badge: props.countNotifications, status:true, user_status:true, ro:true},
    {name:'profile', label:props.message[props.lang].my_education, icon:"person", color:"primary", status:true, user_status:true},
    {name:'rating', label:props.message[props.lang].my_rating, icon:"insights", color:"primary", status:true, user_status:true},
    {name:'tests', label:props.message[props.lang].my_tests, icon:"quiz", color:"primary", badge: props.schedulesTests, status:true, user_status:true},
    {name:'courses', label:props.message[props.lang].my_courses, icon:"cast_for_education", color:"primary", badge: props.schedulesCourses, status:true, user_status:true},
    // {name:'mygroups', label:props.message[props.lang].my_groups, icon:"groups", color:"primary", status:true, user_status:true},
    {name:'assessments', label:props.message[props.lang].my_assessments, icon:"task_alt", color:"primary", badge: props.countDegree360, status:true, user_status:true},
    {name:'iresponsible', label:props.message[props.lang].my_opens, icon:"flaky", color:"primary", badge: props.countOpens, status:true, user_status:true},
    {name:'certs', label:props.message[props.lang].my_certs, icon:"workspace_premium", color:"primary", status:true, user_status:true},
    {name:'competence', label:props.message[props.lang].my_competence, icon:"assignment_ind", color:"primary", badge:props.badgeCompetece , status:true, user_status:true},
    {name:'events', label:props.message[props.lang].my_events, icon:"event", color:"primary", status:true, user_status:true},
    {name:'programs', label:props.message[props.lang].my_programs, icon:"dashboard", color:"primary", status:true, user_status:true},
    {name:'lk_ipr', label:props.message[props.lang].ipr_title, icon:"follow_the_signs", color:"primary", status:true, user_status:true},
    {name:'lk_report', label:props.message[props.lang].reports, icon:"analytics", color:"primary", status:true, user_status:true},
    {name:'my_items', label:props.message[props.lang].my_items, icon:"menu_book", color:"primary", status:true, user_status:true},
    // для мобильных дубль верхнего меню
    {name:'coursesList', label:props.message[props.lang].courses, icon:"cast_for_education", color:"primary", notvisible: !props.isMobile || !props.isModer && props.hide_cat_courses === 'Y', status:true, user_status:true},
    {name:'testsList', label:props.message[props.lang].tests, icon:"playlist_add_check", color:"primary", notvisible: !props.isMobile || (props.isModer && props.hide_cat_tests === 'Y'), status:true, user_status:true},
    {name:'eventsList', label:props.message[props.lang].events, icon:"event_available", color:"primary", notvisible: !props.isMobile || (props.isModer && props.hide_cat_events === 'Y'), status:true, user_status:true},
    {name:'baza_znaniy', label:props.message[props.lang].baza_znaniy, icon:"menu_book", color:"primary", notvisible: !props.isMobile || (props.isModer && props.hide_cat_shelves === 'Y'), status:true, user_status:true},
    {name:'help', label:props.message[props.lang].help, icon:"help_outline", color:"secondary", status:true, user_status:true},
  ];

  // Общие настройки
  if (left_menu_statuses.value && Object.keys(left_menu_statuses.value).length > 0) {
    for (let item of items) {
      if (left_menu_statuses.value.hasOwnProperty(item.name) && !item.ro) {
        item.status = left_menu_statuses.value[item.name];
      }
    }
  }
  //Личные настройки
  if (user_menu_statuses.value && Object.keys(user_menu_statuses.value).length > 0) {
    for (let item of items) {
      if (user_menu_statuses.value.hasOwnProperty(item.name) && !item.ro) {
        item.user_status = user_menu_statuses.value[item.name];
      }
    }
  }

  // console.log(':***: onMounted menuItems=', menuItems.value)
  
  return items;
});

const btnEdit = (isSave)=>{
  if(menu_edit.value && isSave){
    //Сохраним 
    const forsave = {};
    
    if(props.isModer){
      //Модератор сохраняет настройки меню для всех
      for(let item of menuItems.value){
        forsave[item.name] = item.status
      }
      localStorage.setItem('left_menu', JSON.stringify(forsave));
      props.parent.appOptionSet('left_menu', JSON.stringify(forsave))
    }else{
      //ОБычный юзер сохраняет свои личные настройки
      for(let item of menuItems.value){
        forsave[item.name] = item.user_status
      }
      localStorage.setItem('user_menu', JSON.stringify(forsave));
      props.parent.userOption('set','user_menu', JSON.stringify(forsave))
    }
  }
  menu_edit.value=!menu_edit.value
}

const widgetContainer = ref(null);

onMounted(() => {
  // console.log(':: onMounted isModer=', props.isModer, props.isMobile)
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn-ru.bitrix24.ru/b13945802/crm/site_button/loader_2_kn4idd.js?' + ((Date.now() / 60000) | 0);

    // Вставляем скрипт в контейнер
    widgetContainer.value.appendChild(script);

     if (!props.message || !props.message[props.lang]) {
        console.error('Invalid message or lang prop');
        return;
    }
    //инициализация
    for(let item of menuItems.value){
      left_menu_statuses.value[item.name] = item.status;
      user_menu_statuses.value[item.name] = item.user_status;
    }
    //что бы быстро показать берем из локальной памяти браузера
    let statuses = localStorage.getItem('left_menu'); //Общие настройки
    if(statuses){
      try{
        statuses = JSON.parse(statuses);
        left_menu_statuses.value = statuses;
      }catch(e){
        console.log(':1: e=',e)
      }
    }
    let user_statuses = localStorage.getItem('user_menu'); //личные настройки обычного юзера
    if(user_statuses){
      try{
        user_statuses = JSON.parse(user_statuses);
        user_menu_statuses.value = user_statuses;
      }catch(e){
        console.log(':1: e=',e)
      }
    }

    //уточним статусы из настроек приложения
    //Общие настройки
    props.parent.appOptionGet('left_menu').then((data)=>{
      try{
        statuses = data.DETAIL_TEXT? JSON.parse(data.DETAIL_TEXT) : '';
        if(statuses){
          left_menu_statuses.value = statuses;
        }
        // console.log(':: onMounted left_menu_statuses=', left_menu_statuses.value)
      }catch(e){
        console.log(':2: e=',e)
      }
    })

    //Личные настройки
    props.parent.userOption('get','user_menu').then((data)=>{
      try{
        user_statuses = data? JSON.parse(data) : '';
        if(user_statuses){
          user_menu_statuses.value = user_statuses;
        }
        // console.log(':: onMounted user_menu_statuses=', user_menu_statuses.value)
      }catch(e){
        console.log(':2: e=',e)
      }
    })


  

    
});



</script>
