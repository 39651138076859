<template>
  <div>
    <div v-if="mode_view">
      <q-btn 
        flat 
        round 
        color="positive" 
        icon="visibility"
        @click="viewElement"/>

    </div>
    <div v-else>
      <!-- Загрузка PDF -->
      <div class="col-8 text-left" v-if="entityId && !pdfLink">
        <div class="q-pa-sm h5 text-positive">
          <q-icon name="info" />{{ message[lang].PDFhelp }}
        </div>
        <streaming-file
          @streamingSuccess="handleScormSuccess"
          @streamingFailed="handleScormFailed"
          :lang="lang"
          :parent="parent"
          :accept="'.pdf'"
          :url="`sendfile?id=${entityId}`"
          :dense="true"
          :unelevated="true"
        />
        <div v-if="pdfAlert" class="text-warning q-my-sm">{{ pdfError }}</div>
      </div>
      <!-- Запрет копирования не работает-->
      <!-- <div class="row q-gutter-x-xs">
        <q-toggle v-model="disableCopy" :label="message[lang].disableCopy" @update:model-value="emitElement"/>
        <q-toggle v-model="disableContextMenu" :label="message[lang].disableContextMenu" @update:model-value="emitElement"/>
      </div> -->
      <!-- Предпросмотр PDF -->
      <div v-if="pdfLink" class="row q-mt-md justify-center text-accent h5">
        {{pdfName}}
        <iframe 
          ref="pdfIframe"
          :src="pdfLink" 
          width="100%"
          height='400' 
          frameborder="0" 
          @load="applyProtection"
          @contextmenu="handleContextMenu"></iframe>
      </div>

      <!-- Удаление PDF -->
      <div class="q-mt-md" v-if="pdfLink">
        <q-btn
          flat
          rounded
          no-caps
          icon="clear"
          color="positive"
          :label="message[lang].remove + ' PDF'"
          @click="handlePdfRemove"
          :loading="removeLoading"
        />
      </div>
    </div>
  </div>

  <q-dialog
    v-model="dialogOpen"
    maximized
   >
    <q-card class="full-height column no-wrap">
      <q-bar class="bg-primary text-negative">
        <div class="text-h6">PDF</div>
        <q-space />
        <q-btn round dense unelevated color="secondary" icon="done" @click="handleDone">
          <q-tooltip>{{ message[lang].item_checked }}</q-tooltip>
        </q-btn>
        <q-btn round dense flat icon="close" @click="handleDone">
          <q-tooltip>{{message[lang].close}}</q-tooltip>
        </q-btn>
      </q-bar>

      <!-- Основной контент (скроллируемая область) -->
      <q-scroll-area class="col"> <!-- Весь контент внутри скроллится -->
        <div class="column full-height" style="min-height: 100%;">
          <!-- IFrame (растягивается на доступную высоту) -->
          <iframe
            :src="pdfLink"
            class="full-width"
            frameborder="0"
            allowfullscreen
            :style="selfItem.allowComments?'height: calc(100vh - 300px); min-height: 400px;':'height: calc(100vh); min-height: 400px;'"
            ref="pdfIframe"
          />

          <!-- Блок комментариев (остаётся внизу) -->
          <div 
            v-if="selfItem.allowComments" 
            class="bg-dark q-mt-md"
            style="height: auto; min-height: 200px;"
          >
            <Comments 
                  v-bind:lang="lang" 
                  v-bind:entity-id="selfItem.id" 
                  v-bind:user_current="parent.user_current" 
                  v-bind:parent="parent" 
                  v-bind:mode="'list'" 
                  v-bind:heght="900" />
          </div>
        </div>
      </q-scroll-area>
    </q-card>
  </q-dialog>

</template>

<script setup>
import { ref, onMounted, computed, defineProps, defineEmits } from 'vue'
import { useQuasar } from 'quasar'
import streamingFile from '/src/streamingFile';
import Comments from 'src/Comments.vue';

const $q = useQuasar()

const props = defineProps({
  selfItem: Object,
  element:{
    type: Object
  },
  entityId: {
    type: String,
    default: ''
  },
  mode_view: Boolean,
  lang: {
    type: String,
    required: true
  },
  parent: {
    type: Object,
    required: true
  },
  message: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['pdf-loaded', 'pdf-removed', 'error', 'item-checked'])

const pdfAlert = ref(false)
const pdfError = ref('')
const removeLoading = ref(false)
const pdfLink = ref(props?.element?.href)
const pdfName = ref(props?.element?.name)
const disableCopy = ref(props?.element?.disableCopy||false)
const disableContextMenu = ref(props?.element?.disableContextMenu||false)
let dialogOpen = ref(false)

const disableInteraction = computed(() => {
  return disableCopy.value || disableContextMenu.value
})

const pdfIframe = ref(null);

const applyProtection = () => {
  if (!pdfIframe.value) return;
  
  const iframeDoc = pdfIframe.value.contentDocument;
  if (props.disableCopy) {
    iframeDoc.body.style.userSelect = 'none';
    iframeDoc.addEventListener('selectstart', e => e.preventDefault());
  }
  if (props.disableContextMenu) {
    iframeDoc.addEventListener('contextmenu', e => e.preventDefault());
  }
};


const handleContextMenu = (e) => {
  if (disableContextMenu.value) {
    e.preventDefault();
  }
};

const preventDefault = (e) => e.preventDefault();


const viewElement = () => {
  dialogOpen.value = true;
}

const alertSCORM = (message) => {
  pdfAlert.value = true
  pdfError.value = message
  emit('error', message)
}

const handleScormSuccess = (response) => {
  try {
    if (response && props.parent.isJSON(response)) {
      const res = JSON.parse(response)
      if (res.error) {
        alertSCORM(res.error)
      } else {
        console.log('Файл успешно загружен res=', res)
        pdfLink.value = res?.href
        emitElement();
      }
    } else {
      alertSCORM(props.message[props.lang].pdf_error)
    }
  } catch (e) {
    alertSCORM(props.message[props.lang].failed_to_load)
  }
}

const emitElement = (res) => {
  applyProtection();
  emit('pdf-loaded', 
          {href:res?.href || pdfLink.value, 
          name:res?.name || pdfName.value, 
          disableCopy:disableCopy.value, 
          disableContextMenu:disableContextMenu.value
        })
}

const handleScormFailed = (error) => {
  alertSCORM(error.message || props.message[props.lang].pdf_error)
}

const doPdfRemove = () => {
  return new Promise(resolve=>{
    let name = pdfName.value;
    let url = `${props.parent.app_server_url}api/clearimage?client_key=${props.parent.member_id}&portal=${props.parent.portal}&name=${name}&access_token=${props.parent.access_token}&app_id=${props.parent.appID}`;
    props.parent.POST(url, {name: name}, function (response) {
        resolve(true);
    });
  })
}

const handlePdfRemove = async () => {
  $q.dialog({
    title: props.message[props.lang].attention,
    message: props.message[props.lang].remove +' ' + pdfName.value + ' ?',
    position: 'top',
    persistent: true,
    ok: {
      label: props.message[props.lang].remove,
      color: 'warning',
      rounded: true
    },
    cancel: {
      label: props.message[props.lang].prompt_cancel,
      color: 'positive',
      flat: true
    }
  }).onOk(async () => {
    try {
      removeLoading.value = true
      await doPdfRemove()
      pdfLink.value = ''
      pdfName.value = ''
      emit('pdf-removed', {href:'', name:''})
      $q.notify({
        type: 'positive',
        message:props.message[props.lang].file_deleted,
      });
    } catch (error) {
      alertSCORM(error.message || props.message[props.lang].rev_error_title)
    } finally {
      removeLoading.value = false
    }
  })
}


const handleDone = () => {
  emit('item-checked', props.selfItem);
  dialogOpen.value=false;
}

onMounted(async () => {
  
});
  
</script>
<style scoped>
/* Дополнительные стили для полного устранения отступов */
.q-card {
  display: flex;
  flex-direction: column;
}
.q-card__section {
  flex-grow: 1;
  overflow: hidden;
}
/* Стили для запрета взаимодействия */
.no-interaction {
  pointer-events: none;
  user-select: none;
}
</style>