<template>
  <div class="component_padding">
    <div class="text-accent h3">
      <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="close"/>  
      {{ message[lang].the_task_360 }}
    </div>

    <div class="row justify-between q-my-sm">
      <div class="col-8 q-gutter-y-sm">
        <!--  Название -->
        <div>
          <q-input
            :label="message[lang].degree_task_name"
            v-model="degree360.name"
            borderless
            style="padding-left: 10px; border-radius: 25px !important"
            class="body-0 bg-negative"
          />
        </div>
         <!-- Описание -->
        <div>
           <q-input
                v-model="degree360.description"
                type="textarea"
                autogrow
                borderless
                style="padding-left:10px;  border-radius: 25px !important; background-color: white"
                class="body-0"
            />
        </div>
        <!-- Добавить оцениваемых -->    
        <div class="q-my-sm">
          <div class="text-accent h4 q-my-sm">
            <div>
              {{ message[lang].add_students }}
              <div class="text-accent h6">
                <q-icon name="info" />
                {{ message[lang].degree_add_students_info }}
              </div>
            </div>
            <div class="q-my-sm q-gutter-x-sm">
              <q-btn
                icon="add"
                size="sm"
                :loading="isStudentsLoading"
                outline
                rounded
                color="warning"
                :label="message[lang].add_students"
                @click="usersAdd"
              />
              <q-btn v-if="parent.isDepartments"
                icon="add"
                size="sm"
                :loading="isDepsLoading"
                outline
                rounded
                color="secondary"
                :label="message[lang].add_department"
                @click="departmentAdd"
              />
              <!-- <q-btn
                icon="add"
                size="sm"
                :loading="isGroupLoading"
                outline
                rounded
                color="primary"
                :label="message[lang].add_group"
                @click="groupAdd"
              /> -->
            </div>
          </div>

        </div>
        <!-- Список студентов -->
        <div v-if="degree360.students && degree360.students.length > 0">
          <q-list bordered class="rounded-borders">
            <q-item  v-for="(student, stud_index) in degree360.students" :key="student.id">

              <q-item-section>{{student.name}}</q-item-section>
              <q-item-section side>
                  <q-btn
                    flat
                    round
                    icon="clear"
                    color="positive"
                    @click="studentRemove(student, stud_index)"
                  />
                </q-item-section>

            </q-item>

          </q-list>
        </div>

         <!-- Сохранение -->
    <div class="q-pa-md q-gutter-x-sm">
        <q-btn
          :disabled="!validForm"
          icon="save"
          color="secondary"
          no-caps
          unelevated
          rounded
          :label="message[lang].save_comp"
          @click="saveNclose"
        />
        <q-btn
          icon="clear"
          color="warning"
          no-caps
          unelevated
          rounded
          :label="message[lang].close_comp"
          @click="close"
        />
      </div>

      </div>
    </div>


  </div>
</template>

<script>
// import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";

export default {
  name: "Test",
  props: {
    degree360Item: Object,
    degree360Index: Number,
    parent: Object,
    lang: String,
    back: String,
  },
  data() {
    return {
      degree360:{},
      degree360_OLD:{},
      departments:[],
      message: this.parent.dictionary //messageObj,
    };
  },
  mounted() {
    console.log(':: mounted degree360Item=',this.degree360Item);
    if (this.degree360Item) { 
      this.degree360 = this.parent.isJSON(this.degree360Item.DETAIL_TEXT)? JSON.parse(this.degree360Item.DETAIL_TEXT) : { name: "", description:"", students: [] };
      this.degree360.id = this.degree360Item.ID;
      this.degree360.name = this.parent.restoreQuotes(this.degree360.name);
      this.degree360.description = this.parent.restoreQuotes(this.degree360.description);
      this.degree360.students = this.degree360.students || [];
      this.degree360_OLD = { students: JSON.parse(JSON.stringify(this.degree360.students)) };
      // console.log(':: mounted degree360=',this.degree360);
    }else {
      this.degree360 = { name: "", description:"", students: [] };
      this.degree360_OLD = { students: [] };
    }

     this.parent.getDepartments().then((departments) => {
      this.departments = departments;
     });

  },
  computed: {
    validForm() {
      let ret = this.degree360 && this.degree360.name && this.degree360.name.trim();
      return !!ret;
    },
  },
  methods: {
    saveNclose() {
      console.log(":: saveNclose degree360=", {...this.degree360});
      this.$emit("task_saved", {
        degree360Task: this.degree360,
        index: this.degree360Index,
      });
    },
    close() {
      this.$emit("task_close");
    },
    studentRemove(student, index) {
      let $this = this;
      $this.parent.do_confirm = () => {
        $this.degree360.students.splice(index, 1);
        $this.parent.confirm = false;
      };
      $this.parent.title_confirm = this.message[this.lang].attention;
      $this.parent.message_confirm =
        this.message[this.lang].del_student + ' "' + student.name + '"?';
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    groupAdd(){
      let $this = this;
      this.isGroupLoading = true;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list=[];
      this.parent.getGroupList().then(groups=>{
        $this.isGroupLoading=false;
        $this.parent.modal_list=groups;
        $this.parent.modal_flt='';
        $this.parent.model=null;
        $this.parent.modal_list_single=true;
        $this.parent.modal_list_label = $this.message[$this.lang].selectgroups_label;
        $this.parent.modal_title = $this.message[$this.lang].selectgroup_title;
        $this.parent.modal_message = $this.message[$this.lang].selectgroup_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;
        $this.parent.modal_filterFn = null;
        $this.parent.okModal =  ()=> {
          let nn=0;
          $this.parent.modal_loading = true;
          let grp_id = parseInt($this.parent.modal_list_selected);
          console.log('::grp_id=',grp_id);
          BX24.callMethod('sonet_group.user.get', {
            'ID': parseInt(grp_id)
          }, (result) => {
            let groupUsers = result.data();
            console.log('::sonet_group.user.get=', groupUsers);
            let usersIds = [];
            for (let i in groupUsers) {
              let item = groupUsers[i];
              usersIds.push(parseInt(item.USER_ID));
            }
            if (usersIds.length > 0) {
              $this.parent.getUsers(true, '', usersIds).then(users => {
                let items = [];
                $this.degree360.students = $this.degree360.students?$this.degree360.students:[];
                for (let i in users) {
                  let user = users[i];
                  let user_id = parseInt(user.ID);
                  let itemUser = {id: user.ID, name: user.NAME + ' ' + user.LAST_NAME, dep: [grp_id], deps:user.UF_DEPARTMENT, color:'primary'};
                  if (itemUser && !$this.degree360.students.find((element, index, array) => {
                    if (element.id == itemUser.id) return true; else return false
                  }, $this)) {
                    $this.degree360.students.push(itemUser);
                    // console.log('::sonet_group.user.get push itemUser=',itemUser)
                  }
                }
                // $this.parent.getSubAccounts($this.degree360.students, null, $this.departments).then(res=>{
                //   $this.degree360.students = $this.degree360.students.concat(res);
                  console.log(':: selected_users=',$this.degree360.students);
                  $this.parent.modal_loading = false;
                  $this.parent.hideModal();
                // });

              }, err => {
                console.log(err);
              });
            }
            // more
            if(result.more() && nn++<100)
              result.next();
            else{
              console.log('::sonet_group.user.get finish selected_users=',$this.degree360.students)
            }
          });
          // $this.parent.hideModal();
        };
        this.parent.showModal();

      },err=>{$this.isGroupLoading=false; console.log('::err=',err)});
    },
    departmentAdd(){
      let nn=0;
      let $this = this;
      this.isDepsLoading = true;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list=[];
      this.parent.getDepartments().then(deps=>{
        let parent=0, sub_parent=0, sub_parent2=0;
        for(let i in deps) {
          let item = deps[i];
          if(this.parent.isModer || this.ImUser.value == item.uf_head
                  || parent == item.parent || sub_parent == item.parent || sub_parent2 == item.parent) {
            $this.parent.modal_list.push(item);
            
            if(parent == item.parent){
              sub_parent = item.value;
            }
            if(sub_parent == item.parent){
              sub_parent2 = item.value;
            }
          }
        }
        this.isDepsLoading=false;
        $this.parent.model=null;
        $this.parent.modal_flt='';
        $this.parent.modal_list_single=true;
        $this.parent.modal_list_label = $this.message[$this.lang].selectdeps_label;
        $this.parent.modal_title = $this.message[$this.lang].selectdep_title;
        $this.parent.modal_message = $this.message[$this.lang].selectdep_message;
        $this.parent.modal_ok = $this.message[$this.lang].selectusers_add;
        $this.parent.modal_cancel = $this.message[$this.lang].selectusers_finish;
        $this.parent.modal_filterFn = null;
        $this.parent.okModal =  ()=> {
          $this.degree360.students = $this.degree360.students?$this.degree360.students:[];
          $this.parent.modal_loading=true;
          let allDeps=[parseInt($this.parent.modal_list_selected)];
          $this.parent.getAllSubDeps($this.parent.modal_list ,parseInt($this.parent.modal_list_selected), allDeps)
          setTimeout(() => {
            $this.parent.getUsers(true, allDeps).then(users => {
              let items = [];
              for (let i in users) {
                let user = users[i];
                let user_id = parseInt(user.ID);
                let itemUser = {id:user.ID, name:user.NAME + ' ' + user.LAST_NAME, dep:user.UF_DEPARTMENT, color:'secondary'};
                if (itemUser && !$this.degree360.students.find((element, index, array) => {
                  if (element.id == itemUser.id) return true; else return false
                }, $this)) {
                  $this.degree360.students.push(itemUser);
                }
              }
              // $this.parent.getSubAccounts($this.degree360.students, null, $this.departments).then(res=>{
              //   $this.degree360.students = $this.degree360.students.concat(res);
                $this.parent.modal_loading = false;
                $this.parent.hideModal();
              // });
            }, err => {
              console.error(err);
              $this.parent.modal_loading=false;
              $this.parent.hideModal();
            });
          }, 2000);
        };

        this.parent.showModal();

      },err=>{this.isDepsLoading=false; console.log('::err=',err)});
    },
    usersAdd() {
      this.parent.usersAdd(this.degree360.students).then((res) => {
        console.log("::usersAdd res=", res);
      });
    },






  },
};
</script>

<style lang="scss" scoped></style>