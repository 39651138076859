<template>
  <div class="q-pa-md">
    <div class="column" :style="parent.isMobile ? 'height: 350px' : 'height: 700px'">
      <div class="col self-start"></div>
      <div class="col self-center">
        <!-- в CRM не должно быть окна авторизации -->
        <q-card
          v-if="message[lang] && !parent.SA"
          class="my-card text-white q-pa-lg"
          :style="`width: ${wd}; background: radial-gradient(circle, #bfc1c5 0%, #717276 80%)`"
          >
          <img
            src="https://docs.brusnika.solutions/uploads/images/system/2024-08/artboard-5-copy.png"
          />
          <q-card-section>
            <div class="h3 text-warning">{{ message[lang].no_BX24 }}</div>
          </q-card-section>
        </q-card>
        
        <!-- Смена пароля в SA -->
        <q-card
          v-else-if="message[lang] && reset_password_token && parent.SA"
          class="my-card text-white q-pa-lg"
          :style="`width: ${wd}; background: radial-gradient(circle, #bfc1c5 0%, #717276 80%)`"
          >
          <img
            src="https://docs.brusnika.solutions/uploads/images/system/2024-08/artboard-5-copy.png"
          />
          <q-card-section>
            <div class="h3 text-negative">{{ message[lang].repair_password }}</div>
            <q-input
              :type="isPwd ? 'password' : 'text'"
              v-model="password"
              :label="message[lang].auth_password"
              color="white"
              label-color="white"
              input-class="bg-transparent text-white"
              dark
              autocomplete="off"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>

            <q-input
              :type="isPwd ? 'password' : 'text'"
              v-model="password2"
              :label="message[lang].auth_password2"
              color="white"
              label-color="white"
              input-class="bg-transparent text-white"
              dark
              autocomplete="off"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>

            <div class="row items-center q-my-md q-gutter-x-md">
              <div>
                <q-btn
                  :loading="mail_loading"
                  @click="setNewPassword(reset_password_token)"
                  rounded
                  unelevated
                  no-caps
                  color="white"
                  text-color="grey-9"
                  :disable="!isPasswordMatch"
                  :label="message[lang].change_password"
                />
              </div>

              <div v-show="isPasswordMatch === false" class="h5 text-red-2">
                {{ message[lang].auth_password_not_match }}
              </div>
              
            </div>

          </q-card-section>
        </q-card>
        
        <!-- Авторизация -->
        <q-card
          v-else-if="message[lang]"
          class="my-card text-white q-pa-lg"
          :style="`width: ${wd}; background: radial-gradient(circle, #bfc1c5 0%, #717276 80%)`"
         >
          <img
            src="https://docs.brusnika.solutions/uploads/images/system/2024-08/artboard-5-copy.png"
          />

          <q-card-section>
            <div class="h3 text-white">{{ message[lang].sign_up_for_an_account }}</div>
          </q-card-section>

          <q-item>
            <q-item-section avatar v-if="appLogo">
              <q-avatar rounded size="100px">
                <img :src="appLogo" />
              </q-avatar>
            </q-item-section>

            <q-item-section v-if="appTitle">
              <div class="h4 text-white" v-html="appTitle"></div>
            </q-item-section>
          </q-item>

          <q-card-section class="q-pt-none q-gutter-y-md">
            <q-input
              type="email"
              v-model="email"
              :label="message[lang].auth_email"
              @update:model-value="getLogin"
              debounce="1000"
              color="white"
              label-color="white"
              input-class="bg-transparent text-white"
              dark
              @keyup.enter="signUp"
              autocomplete="off"
            />

            <q-input
              :type="isPwd ? 'password' : 'text'"
              v-model="password"
              :label="message[lang].auth_password"
              debounce="250"
              @update:model-value="getLogin"
              color="white"
              label-color="white"
              input-class="bg-transparent text-white"
              dark
              @keyup.enter="signUp"
              autocomplete="off"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>

            <q-input
              v-if="isReg || is_change_password"
              :type="isPwd ? 'password' : 'text'"
              v-model="password2"
              :label="message[lang].auth_password2"
              color="white"
              label-color="white"
              input-class="bg-transparent text-white"
              dark
              autocomplete="off"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>

            <q-banner
              v-if="isReg"
              rounded
              style="
                color: #ffffff;
                background: radial-gradient(circle, #ec8c79 0%, #d16d5d 100%);
              "
            >
              <span v-if="inviteParam">{{ message[lang].is_reg_invite }}</span>
              <span v-else>{{ message[lang].is_reg_info }}</span>
            </q-banner>

            <div>
              <q-btn
                :loading="loading"
                @click="signUp"
                rounded
                unelevated
                no-caps
                color="white"
                text-color="grey-9"
                :disable="!isPasswordMatch || !isValidEmail(email)"
                :label="
                  is_change_password
                    ? message[lang].change_password
                    : !loginExist && loginExist !== null
                    ? message[lang].sign_up
                    : message[lang].sign_in
                "
              />

              <q-btn
                v-if="change_password_available"
                flat
                rounded
                no-caps
                color="blue-1"
                :label="message[lang].change_password"
                @click="is_change_password = !is_change_password"
              />
            </div>
          </q-card-section>

          <q-separator inset dark />

          <q-card-section style="height: 100px">
            <div class="row justify-between">
              <div class="col-6">
                <div v-if="is_change_password" class="h5 text-red-3">
                  {{ message[lang].change_password_hint }}
                </div>
                <div v-if="!loginExist && loginExist !== null" class="h5 text-green-3">
                  {{ email_hint }}
                </div>
                <div v-else-if="loginExist" class="h5 text-blue-2">{{ email_hint }}</div>
                <div v-if="password_invalid" class="h5 text-red-2">
                  <div>{{ message[lang].password_invalid }}</div>
                  <q-btn :loading="mail_loading" flat rounded no-caps class="q-pa-none"  color="negative" :label="message[lang].reset_password" @click="resetPassword" >
                  <q-tooltip class="bg-positive h-tt">{{message[lang].reset_password_info}}</q-tooltip>
                  </q-btn>

                </div>
                <div v-if="isPasswordMatch && isReg" class="h5 text-green-3">
                  {{ message[lang].auth_password_match }}
                </div>
                <div v-else-if="isReg && isPasswordMatch === false" class="h5 text-red-2">
                  {{ message[lang].auth_password_not_match }}
                </div>
                <!-- isReg={{isReg}} isPasswordMatch={{isPasswordMatch}} -->
              </div>
              <div class="col-5">
                <q-select
                  v-model="lang"
                  :options="parent.lang_options"
                  map-options
                  option-label="text"
                  emit-value
                  color="white"
                  label-color="white"
                  dark
                  dense
                  @update:model-value="changeLang"
                  popup-content-style="background: radial-gradient(circle, #35a2ff 0%, #014a88 100%)"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col self-end"></div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useQuasar } from 'quasar';

export default {
  name: "Auth",
  props: {
    parent: Object,
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const isMobile = ref(false);
    const wd = ref("550px");
    let appTitle = ref("");
    let appLogo = ref(null);
    const email = ref("");
    const password = ref("");
    const password2 = ref(null);
    const auth = ref("");
    const lang = ref("en");
    // const message = messageObj;
    let message = reactive({}); //props.parent.dictionary
    let loginExist = ref(null);
    let email_hint = ref("");
    let password_invalid = ref(false);
    let loading = ref(false);
    // const route = useRoute();
    const inviteParam = ref(null);
    const is_change_password = ref(false);
    const change_password_available = ref(false);
    const mail_loading = ref(false);

    let userExist = reactive({ id: 0 });

    const currentUrl = ref(window.location.href);
    const domain = props.parent.getRootDomain(currentUrl.value);
    const foundIndex = props.parent.lang_options.findIndex((element, index, array) => {
      return element.value == domain;
    });
    // console.log('::domain= ',domain, foundIndex);
    if (foundIndex !== -1) {
      lang.value = domain;
    }

    const url = new URL(currentUrl.value);
    const reset_password_token = url.searchParams.get("reset-password");
    console.log(':: reset_password_token= ',reset_password_token);

    onMounted(() => {
      //inviteParam.value = route.query.invite;
      isMobile.value = props.parent.isMobile;
      wd.value = isMobile.value ? "100%" : "550px";
      // const currentUrl = window.location.href;
      const url = new URL(currentUrl.value);
      const member_id = url.searchParams.get("invite");
      inviteParam.value = member_id;
      
      // console.log('::inviteParam= ',inviteParam.value, member_id)
      getAppLogo(member_id);

      // const domain = props.parent.getRootDomain(currentUrl);
      // const foundIndex = props.parent.lang_options.findIndex((element, index, array) => {
      //   return element.value == domain;
      // });
      // console.log('::domain= ',domain, foundIndex);
      // if(foundIndex !== -1){
      //   lang.value = domain;
      // }
      // changeLang(lang.value);
    });

    const isReg = computed(() => {
      return !loginExist.value && loginExist.value !== null;
    });

    const isPasswordMatch = computed(() => {
      let ret = null;
      // console.log('::isPasswordMatch ',loginExist.value,password.value, password2.value);
      if (isReg.value || is_change_password.value || reset_password_token) {
        ret = password.value && password.value === password2.value; 
      } 
      // else if (is_change_password.value) {
      //   ret = password.value && password.value === password2.value;
      //   // console.log('::isPasswordMatch это смена пароля ret=',ret)
      // } 
      else {
        ret = password.value || null;
        // console.log('::isPasswordMatch это вход ret=',ret)
      }
      return ret;
    });

    //loginExist && !loginExist.hasOwnProperty('is_valid')
    const btn_disable = computed(() => {
      return false; // userExist;// && !userExist.hasOwnProperty('is_valid') && !is_change_password.value
    });

    // Функция для получения корневого домена без субдоменов
    // function getRootDomain(url) {
    //   const hostname = new URL(url).hostname;
    //   const parts = hostname.split('.').reverse();

    //   if (parts.length > 1) {
    //       return parts[0]; //`${parts[1]}.${parts[0]}`;
    //   }
    //   return hostname;
    // }

    const changeLang = (lang) => {
      let $this = props.parent;
      loading.value = true;
      localStorage.setItem('user_lang', lang);
      props.parent.getDictionary(lang).then((res) => {
        message[lang] = res;
        //
        $this.dictionary[lang] = res;
        $this.messageObj = $this.dictionary;
        $this.message = $this.dictionary;

        // eslint-disable-next-line vue/no-mutating-props
        //props.parent.dictionary = res;

        loading.value = false;
        // console.log('::getDictionary message=',message);
      });
    };
    const user_lang = localStorage.getItem('user_lang');
    if(user_lang){
      lang.value = user_lang;
    }

    changeLang(lang.value);

    const signUp = () => {
      if (!isValidEmail(email.value)) {
        // console.log('::signUp ', message[lang.value].email_invalid);
        return;
      }
      // console.log('::signUp ',email.value, password.value, userExist);

      if (userExist?.id > 0) {
        if (userExist.hasOwnProperty("is_valid")) {
          //запрос пароля выполнен
          if (userExist.is_valid || is_change_password.value) {
            if (
              is_change_password.value &&
              password2.value &&
              password.value === password2.value
            ) {
              // это смена пароля
              updUserPassword().then(
                (res) => {
                  // console.log('::signUp res=',res);
                  emit("auth_passed", userExist);
                },
                (err) => {
                  console.error(":: updUserPassword err=", err);
                }
              );
            } else {
              // это вход
              props.parent.getAccessToken().then(
                (res) => {
                  emit("auth_passed", {
                    user_id: userExist.id,
                    email: userExist.login,
                    member_id: userExist.member_id,
                    portal: userExist.portal,
                    is_admin: userExist.is_admin,
                    is_moder: userExist.is_moder,
                  });
                },
                (err) => {
                  if (userExist.client_id && userExist.client_secret) {
                    let body = {
                      grant_type: "password",
                      client_id: userExist.client_id,
                      client_secret: userExist.client_secret,
                      scope: "app",
                      username: userExist.login,
                      password: password.value,
                    };
                    // console.log(':1: getTokenForUser body=',body );
                    props.parent.getTokenForUser(body).then(
                      (res) => {
                        emit("auth_passed", {
                          user_id: userExist.id,
                          email: userExist.login,
                          member_id: userExist.member_id,
                          portal: userExist.portal,
                          is_admin: userExist.is_admin,
                          is_moder: userExist.is_moder,
                        });
                      },
                      (err) => {
                        console.error(":1:getTokenForUser error");
                      }
                    );
                  } else {
                    console.error(":2:getTokenForUser error");
                  }
                }
              );
            }
          } else {
            password_invalid.value = true;
          }
        } else {
          // console.log('::signUp password waiting...');
          getLogin();
        }
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.do_confirm = () => {
          // eslint-disable-next-line vue/no-mutating-props
          props.parent.confirm = false;
          addAuthUser(inviteParam.value).then(
            (res) => {
              // console.log('::signUp res=',res);
              if (isReg.value && !inviteParam.value) {
                res.is_admin = true; //Это регистрация нового портала
              }
              emit("auth_passed", res);
            },
            (err) => {
              console.error(":: addAuthUser err=", err);
              // eslint-disable-next-line vue/no-mutating-props
              props.parent.notify_title = "";
              // eslint-disable-next-line vue/no-mutating-props
              props.parent.notify_text = err.error;
              // eslint-disable-next-line vue/no-mutating-props
              props.parent.notify_warning = true;
              // eslint-disable-next-line vue/no-mutating-props
              props.parent.notify = true;
            }
          );
        };
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.title_confirm = message[lang.value].attention;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.message_confirm = inviteParam.value
          ? message[lang.value].sign_up_invite
          : message[lang.value].sign_up_confirm;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_ok = inviteParam.value
          ? message[lang.value].yes
          : message[lang.value].sign_up_btn_ok;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_cancel = inviteParam.value
          ? message[lang.value].no
          : message[lang.value].reset;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = true;
      }
    };

    function generateRandomKey(length = 32) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }

      return result;
    }

    const updUserPassword = () => {
      let $this = this;
      return new Promise((resolve, reject) => {
        props.parent.POST(
          props.parent.apiurl + "api/userpasswupdate",
          { id: userExist.id, password: password.value, member_id: userExist.member_id },
          function (res) {
            // console.log('::updUserPassword res=',res);

            resolve();
          }
        );
      });
    };

    const addAuthUser = (memberid) => {
      let $this = this;
      const member_id = memberid || generateRandomKey();
      // console.log('::addAuthUser');
      // const c53passw = props.parent.c53(password.value)
      return new Promise((resolve, reject) => {
        props.parent.POST(
          props.parent.apiurl + "api/useradd",
          { login: email.value, password: password.value, member_id: member_id },
          function (res) {
            // console.log(res);

            if (res && res[":client_id"]) {
              let user_id = res[":user_id"];
              let body = {
                grant_type: "password",
                client_id: res[":client_id"],
                client_secret: res[":client_secret"],
                scope: res[":scope"],
                username: email.value,
                password: password.value,
              };
              // console.log(':1: getTokenForUser body=',body );
              props.parent.getTokenForUser(body).then(
                (res) => {
                  resolve({
                    user_id: user_id,
                    login: email.value,
                    member_id: member_id,
                    token: res,
                  });
                },
                (err) => {
                  reject(err);
                }
              );
            } else {
              reject(res);
            }
          }
        );
      });
    };

    const getAppLogo = (member_id) => {
      if (member_id) {
        props.parent.POST(
          props.parent.apiurl + "api/appoptiongetNoAuth",
          { member_id: member_id, key: "app_title" },
          function (res) {
            appTitle.value = res;
          }
        );

        props.parent.POST(
          props.parent.apiurl + "api/appoptiongetNoAuth",
          { member_id: member_id, key: "applogo" },
          function (res) {
            appLogo.value = res;
          }
        );
      }
    };

    const getLogin = () => {
      // if(isReg.value){
      // return;
      // }
      loginExist.value = null;
      // console.log('::getLogin');
      if (isValidEmail(email.value)) {
        // console.log('::getLogin', email.value, password.value);
        loading.value = true;
        props.parent.POST(
          props.parent.apiurl + "api/users/get",
          { login: email.value, password: password.value },
          function (res) {
            // console.log('::getLogin res=',res);
            loading.value = false;
            if (res && res.length > 0 && res[0].id) {
              loginExist.value = true;
              userExist = res[0];
              change_password_available.value = userExist && userExist.is_valid;
              email_hint.value = message[lang.value].email_exist;
            } else {
              loginExist.value = false;
              email_hint.value = message[lang.value].email_ok;
            }
            // console.log('::getLogin userExist=',userExist);
          }
        );
      } else {
        loginExist.value = true;
        email_hint.value = message[lang.value].email_invalid;
        // console.log('::getLogin invalid email email_hint=',lang,email_hint.value);
      }
    };

    function isValidEmail(email) {
      // Регулярное выражение для проверки валидности email
      // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const regex = /^[^\s@,\'\"]+@[^\s@,\'\"]+\.[^\s@,\'\"]+$/;
      return regex.test(email);
    }

    const setNewPassword = async (token) =>{
      mail_loading.value = true;
    
      try {
        const params = JSON.stringify({token:token, password:password.value, lang:lang.value});
        // console.log("::setNewPassword params=",params);
        const response = await fetch(
          props.parent.apiurl + "api/reset-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: params,
          }
        );
        

        const res = await response.json(); // Парсим ответ в JSON
        // console.log("::setNewPassword res=", res);

        mail_loading.value = false;

        // Уведомление
        $q.notify({
          type: res?.error ? "warning" : "secondary",
          position: "center",
          multiLine: true,
          message: res?.result || res?.error || message[lang.value].change_password_fail,
        });

        // Перезагрузка через 5 секунд
        setTimeout(() => {
          const currentUrl = window.location.href+'index.php';
          const domain = new URL(currentUrl).origin;
          window.location.href = domain;
        }, 5000);
      } catch (error) {
        console.error("::resetPassword error=", error);
        mail_loading.value = false;

        // Уведомление об ошибке
        $q.notify({
          type: "warning",
          position: "center",
          multiLine: true,
          message: message[lang.value].change_password_fail,
        });
      }
    };
      

    const resetPassword = () => {
      // console.log('::resetPassword');
      const reversed = email.value.split('').reverse().join('');
      mail_loading.value = true;
      sha256(reversed).then(hash=>{
        props.parent.POST(
          props.parent.apiurl + "api/resetpassword",
          { email: email.value, hash:hash, lang:lang.value },
          function (res) {
            console.log('::resetPassword res=',res);
            mail_loading.value = false;
            $q.notify({
              type: res?.error?'warning':'secondary',
              position: 'center',
              multiLine: true,
              message:res?.result || res?.error || message[lang.value].send_email_error,
            });
            
          }
        );
      })
    };

    async function sha256(message) {
      const msgBuffer = new TextEncoder().encode(message);
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
  }

    return {
      isPwd: ref(true),
      appTitle,
      appLogo,
      isMobile,
      wd,
      lang,
      inviteParam,
      loading,
      isPasswordMatch,
      auth,
      email,
      password,
      password2,
      message,
      signUp,
      addAuthUser,
      getLogin,
      loginExist,
      email_hint,
      password_invalid,
      btn_disable,
      is_change_password,
      change_password_available,
      isReg,
      isValidEmail,
      changeLang,
      resetPassword,
      mail_loading,
      reset_password_token,
      setNewPassword
    };
  },
};
</script>
<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-bottom: 1px solid #ccc !important;
  -webkit-text-fill-color: #707173 !important;
  -webkit-box-shadow: 0 0 0px 100px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
</style>
